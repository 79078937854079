import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  MInputGeneric,
  MInputNumber,
  MInputSelect,
} from "@inversiones-ma/finfast-react-controls";
import * as GlobalModels from "@redux/models/GlobalModels";
import * as helpers2 from "@helpers/helpers2";
import * as helpers from "@helpers/helpers";

interface PropsAssuredAddress {
  form: any;
}

const AssuredAddress = ({ form }: PropsAssuredAddress) => {
    const { assuredTrc } = useAppSelector((store) => store.insuranceTRC);
    const { regions } = useAppSelector((store) => store.global);
    const { setValue, watch } = form;
    const { regionAsegurado } = watch();
   
    const [arrComunaAsegurado, setArrComunaAsegurado] = useState<GlobalModels.Commune[]>(
      helpers2.getCommunes("", regions)
    );
  
    useEffect(() => {
      let r = regions?.find((r) => r.id.toString() == regionAsegurado);
      let comunas = helpers.getComunas(r?.name, regions);
      setArrComunaAsegurado(comunas);
      // eslint-disable-next-line
    }, [regionAsegurado]);
  
  
    useEffect(() => {
      if (assuredTrc.comunaAsegurado !== "") {
        setValue("comunaAsegurado", assuredTrc.comunaAsegurado);
      }
    }, [arrComunaAsegurado]);
  
    return (
      <>
        <h3 className="subtitle-mini">Dirección del asegurado / contratante</h3>
        <div className="row pb-3">
          <div className="col-lg-6">
            <div className="floating-label">
              <MInputSelect
                form={form}
                name="regionAsegurado"
                className="form-select info-form input-tag"
                items={regions as GlobalModels.Region[]}
                valueItem="id"
                label="Región"
                descriptionItem="name"
                orderBy={{
                  by: "name",
                  desc: false,
                  isString: true,
                }}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="floating-label">
              <MInputSelect
                form={form}
                name="comunaAsegurado"
                className="form-select info-form input-tag"
                items={arrComunaAsegurado}
                valueItem="id"
                descriptionItem="name"
                orderBy={{
                  by: "name",
                  desc: false,
                  isString: true,
                }}
                label="Comuna"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <fieldset>
              <div className="floating-label">
                <MInputGeneric
                  form={form}
                  name="calleAsegurado"
                  label="Calle"
                  className="floating-input"
                  type="text"
                  placeholder=" "
                  rules={{
                    required: "Este campo es requerido",
                  }}
                />
              </div>
            </fieldset>
          </div>
          <div className="col-lg-6">
            <fieldset>
              <div className="floating-label">
                <MInputNumber
                  form={form}
                  name="numeroAsegurado"
                  label="Número"
                  className="floating-input"
                  maxLength={6}
                  placeholder=" "
                  rules={{
                    required: "Este campo es requerido",
                  }}
                />
              </div>
            </fieldset>
          </div>
        </div>
      </>
    );
};

export default AssuredAddress;
