import React from "react";
import { Link } from "react-router-dom";

interface PropsLogout {
  isMobile?: boolean
}
const Logout = (props: PropsLogout = {isMobile: false}) => {
  if(props.isMobile) {
    return (
      <Link className="nav-link" to="/logout">
        <i className="fa fa-power-off me-2"></i>Cerrar sesión
      </Link>
    );
  }

  return (
    <Link className="nav-link" to="/logout">
        Cerrar sesión <i className="fa fa-power-off ms-2"></i>
    </Link>
  );
};

export default Logout;
