import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { loadFromSessionStorage, saveToSessionStorage } from "./localStorage";
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import GlobalReducer from "@redux/reducers/GlobalReducer";
import AccountReducer from "@redux/reducers/AccountReducer";
import ProductReducer from "../reducers/ProductReducer";
import SessionReducer from "@redux/reducers/SessionReducer";
import InsuranceRCReducer from "@redux/reducers/InsuranceRCReducer";
import InsurancePAReducer from "@redux/reducers/InsurancePAReducer";
import CoveragesReducer from "@redux/reducers/CoveragesReducer";
import StepReducer from "@redux/reducers/StepReducer";
import InsuranceTRCReducer from '@redux/reducers/InsuranceTRCReducer';
import { typesInsuranceSlice } from "@redux/reducers/TypeInsuranceReducer"
import { typesInsurancesAPI } from '@redux/api/typesInsurancesAPI'
import ApiMembresiaAccount from "@api/membresia/account";
import * as AccountActions from "@redux/actions/AccountActions";

const rootReducer = combineReducers({
  global: GlobalReducer,
  account: AccountReducer,
  product: ProductReducer,
  session: SessionReducer,
  insuranceRC: InsuranceRCReducer,
  insurancePA: InsurancePAReducer,
  insuranceTRC: InsuranceTRCReducer,
  steps: StepReducer,
  coverages: CoveragesReducer,
  typesInsurances: typesInsuranceSlice.reducer,
  [typesInsurancesAPI.reducerPath]: typesInsurancesAPI.reducer
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadFromSessionStorage(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(typesInsurancesAPI.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

// listen for store changes and use saveToLocalStorage to
// save them to localStorage en cada cambio
// store.subscribe(() => saveToSessionStorage(store.getState()))
//save on close o refresh
window.onbeforeunload = () => {
  saveToSessionStorage(store.getState())
};


setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
