import { RootState } from "./../store/store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TypeInsurance } from "@redux/models/TypeInsuranceModels";
import GenericBaseModel from "@api/GenericBaseModel";
import { apiUrl } from "../../config/environment";

export const typesInsurancesAPI = createApi({
  reducerPath: "typesInsurancesAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl()}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).account.token;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
    credentials: "include", //Permite al server configurar las cookies
  }),
  endpoints: (builder) => ({
    getAllAvailable: builder.query<GenericBaseModel<TypeInsurance>, void>({
      query: () => `/Seguro/InsuranceType/GetAllAvailable`,
    }),
  }),
});

export const { useGetAllAvailableQuery } = typesInsurancesAPI;
