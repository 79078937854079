import { loadAllCoverages } from "./../actions/InsuranceActions";
import { CoveragesState } from "@redux/models/InsuranceModels";
import { createSlice } from "@reduxjs/toolkit";

const inicialState: CoveragesState = {
  coverages: [],
};

const slice = createSlice({
  name: "coverages",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAllCoverages, (state, action) => {
        state.coverages = action.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
