import React from "react";
import "./Stepper.scss";
import { useAppSelector } from "@redux/hooks";

export const Steeper = () => {
  const { active, steps } = useAppSelector((store) => store.global.stepper);

  return (
    <>
      <div className="section-insurance stepperlist">
        <p className="youarein">Estás en:</p>
        <nav className="stepper">
          <ul className="stepper__list">
            {steps.map((step) => {
              return (
                <li
                  key={step.id}
                  className={`stepper__list__item ${
                    active === step.id
                      ? "stepper__list__item--current"
                      : "stepper__list__item--pending"
                  }`}
                >
                  <span className="stepper__correlative">{step.id}</span>
                  <span className="stepper__list__title">{step.name}</span>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className="info-mobile-steppers">
          <p className="step__location">
            Paso
            <span className="px-1">{active}</span>
            de
            <span className="px-1">{steps.length}</span>
          </p>
          <h5 className="description__step_mobile">
            {steps.find((step) => step.id === active)?.name}
          </h5>
        </div>
      </div>
    </>
  );
};
