import { TypeInsurance } from "@redux/models/TypeInsuranceModels";

type TDetailCardInsuranceProps = {
  insurance: TypeInsurance;
  blockIfConstruction: boolean;
};

export const DetailCardInsurance = ({
  insurance,
  blockIfConstruction,
}: TDetailCardInsuranceProps) => {
  return (
    <div className="col-12 h-100 d-inline-block mx-height-200">
      <h3
        className={`fs-24 ${
          !insurance.estado === true || blockIfConstruction
            ? "text-muted"
            : "color-secondary"
        } fw-bold text-center d-flex align-items-center justify-content-center`}
      >
        {insurance.title}
      </h3>
      <p className="text-center color-text fs-14 text-center">
        {insurance.id === 3 && insurance.idOpcionPropuesta === 38 ? (
          insurance.descripcion
        ) : (
          <>
            {insurance.idOpcionPropuesta === 35 && (
              <>
                El seguro de TRC + RC cubre todos los daños que se produzcan de
                forma accidental o imprevisible, asociados a la empresa, a
                terceros y obra civil durante su ejecución, incluidos los que
                tengan su origen en fenómenos naturales.
              </>
            )}
            {insurance.idOpcionPropuesta === 40 && (
              <>
                El seguro RC Construcción permite reparar el daño causado a un
                tercero originado por la ejecución de una obra.
              </>
            )}
          </>
        )}
        {insurance.id !== 3 && insurance.descripcion}
      </p>
    </div>
  );
};
