import React from "react";
import {
  MInputGeneric,
  MInputRut,
} from "@inversiones-ma/finfast-react-controls";

interface PropsCompanyForm {
  form: any;
}

const CompanyForm = ({ form }: PropsCompanyForm) => {
  return (
    <>
      <div className="col-lg-6">
        <fieldset>
          <div className="floating-label">
            <MInputRut
              form={form}
              name="rutAsegurado"
              label="RUT"
              className="floating-input disabled"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>
      <div className="col-lg-12">
        <fieldset>
          <div className="floating-label">
            <MInputGeneric
              form={form}
              name="razonSocialAsegurado"
              label="Razón Social"
              className="floating-input disabled"
              type="text"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default CompanyForm;
