import { InsuranceHeader } from "@controls/InsuranceHeader/InsuranceHeader";
import { Steeper } from "@controls/Stepper/Stepper";
import { StepOne } from "./Partials/StepOne";
import { StepTwo } from "./Partials/StepTwo";
import { StepThree } from "./Partials/StepThree";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { changeStep, loadStepper } from "@redux/actions/GlobalActions";
import {
  cleanInsurancePlansPA,
  loadAllCoverages,
  loadInsurancePlansPA,
} from "@redux/actions/InsuranceActions";
import { useEffect, useState } from "react";
import "./AccidentPersonals.scss";
import ApiInsurance from "@api/insurance/insurance";
import MLoading from "@controls/MLoading";
import { Coverage, InsurancePlan } from "@redux/models/InsuranceModels";
import { Step, Stepper } from "@redux/models/GlobalModels";

export const PFInsurance = () => {
  const dispatch = useAppDispatch();
  const { plans } = useAppSelector((store) => store.insurancePA);
  const { regions } = useAppSelector((store) => store.global);
  const { active, steps } = useAppSelector((store) => store.global.stepper);
  const [AddAdicionalBenef, setAddAdicionalBenef] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [employees, setEmployees] = useState<number>(1);
  const [idPlan, setIdPlan] = useState<number>(31);
  const [medicalExpenses, setMedicalExpenses] = useState<boolean>(false);

  const stepsPF: Step[] = [
    { id: 1, name: "Cotizar" },
    { id: 2, name: "Información del asegurado" },
    { id: 3, name: "Ingresa información adicional" },
  ];
  const stepper: Stepper = { active: 1, steps: stepsPF };

  useEffect(() => {
    dispatch(loadStepper(stepper));
    if (active !== 1) dispatch(changeStep(1));
    // if (active === 1) dispatch(cleanInsurancePlansPA());

    if (active === 1) {
      if (plans.length === 0) {
        ApiInsurance.callPlanesSeguroAll("2")
          .then((res) => {
            const sortedPlans = res.dataList.sort(
              (a: InsurancePlan, b: InsurancePlan) => {
                if (a.montoAseguradoGeneral > b.montoAseguradoGeneral) return 1;
                if (a.montoAseguradoGeneral < b.montoAseguradoGeneral)
                  return -1;
                return 0;
              }
            );
            dispatch(loadInsurancePlansPA(sortedPlans));
          })
          .then(() => {
            ApiInsurance.callSeguroRCInsuranceCoverageGetInfoCoverageAll(
              new Date().getFullYear().toString(),
              "1",
              "2"
            ).then((res) => {
              dispatch(loadAllCoverages(res.dataList));
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Si se desmonta el componente volver el stepper al paso 1
    return () => {
      dispatch(changeStep(1));
    };

    // eslint-disable-next-line
  }, []);

  const handleChangeStep = (step: number) => {
    // Condicion para evitar conflictos con pasos que no existen
    if (step > 0 && step <= steps.length) dispatch(changeStep(step));
  };

  return (
    <>
      {loading ? (
        <div className="anim-loading">
          <div className="lottie-animacion">
            <MLoading />
          </div>
        </div>
      ) : (
        <main className="bg-home pb-5">
          <div className="container accidentes-personales">
            {/* Sección estás contratando (Header) */}
            <div className="row">
              <div className="col-12 mt-4 mb-3">
                <InsuranceHeader
                  descripcion={"Protección financiera para empleados"}
                  id={7}
                />
              </div>
            </div>
            {/* Contenido principal */}
            <div className="row">
              {/* Paso a paso */}
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <Steeper />
              </div>
              {/* Cotizador */}
              <div className="col-12 col-lg-8">
                {active === 1 && (
                  <StepOne
                    setEmployees={setEmployees}
                    setIdPlan={setIdPlan}
                    setMedicalExpenses={setMedicalExpenses}
                  />
                )}
                {active === 2 && (
                  <StepTwo
                    handleChangeStep={handleChangeStep}
                    regions={regions}
                    setAddAdicionalBenef={setAddAdicionalBenef}
                    addAdicionalBenef={AddAdicionalBenef}
                    employees={employees}
                    idPlan={idPlan}
                    medicalExpenses={medicalExpenses}
                  />
                )}
                {active === 3 && (
                  <StepThree handleChangeStep={handleChangeStep} />
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};
