import { rutValidate } from "rutfunctions";
import { getFileExtension, toDate, convertToPeso } from "./helpers";
import dayjs from "dayjs";

export const minLenghtWordsFilter = (value) => ({
  value: value,
  message: value < 1 && "Debes ingresar una palabra",
});

export const minLengthGeneral = (value) => ({
  value: value,
  message:
    value < 2
      ? "Debes ingresar al menos un caracter"
      : "Debes ingresar al menos " + value + " caracteres",
});

export const minLengthTelefono = {
  value: 9,
  message: "Debes ingresar un teléfono válido",
};
//MaxLenght
export const maxLengthGeneral = (value) => ({
  value: value,
  message: "Debes ingresar a lo más " + value + " caracteres",
});

export const maxLengthGlosa = {
  value: 500,
  message: "No puedes ingresar más de 500 caracteres",
};
export const maxLengthNumeroDireccion = {
  value: 6,
  message: "Debes ingresar un número de dirección válido",
};
//max
export const maxMontoCertificado = {
  value: 999999999,
  message: "El monto del certificado es demasiado alto",
};
//Regex
export const patternAlphaSpace = {
  value: /^[A-Za-záÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternAlphaNumSpace = {
  value: /^[A-Za-z0-9áÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternText = {
  value: /^[A-Za-z0-9-#°.,:;áÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternNum = {
  value: /^[0-9]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternAddress = {
  value: /^[A-Za-z0-9-#°.,áÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternRut = {
  value: /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/i,
  message: "Formato de Rut inválido",
};
export const patternNumeroSeparadorMilesComas = {
  value: /^\d{3}(.\d{3})*(,\d+)?$/,
  message: "Formato número inválido",
};
export const patternEmail = {
  value:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Formato de correo inválido",
};

export const patternNroCFT = {
  value: /^[0-9]+((w|W)(e|E)(b|B))?$/,
  message: "Número de certificado inválido",
};
//Values
export const validateTrim = (value, get) =>
  value.length === 0 ||
  /^\S/.test(value) ||
  "No puedes comenzar con espacios vacíos";
export const validateRut = (value) => rutValidate(value) || "El rut no existe";
export const validateSelect = (value) =>
  (value !== -1 && value !== "-1") || "Debes seleccionar una alternativa";
export const validateHiddenArray = (value) =>
  (value !== "" && value !== "[]") ||
  "Debes seleccionar al menos una alternativa";
export const validateDateForward = (value, currentDay) =>
  new Date(`${value.year}-${value.month}-${value.day}`) > currentDay ||
  "Rango de fecha no permitido";
export const validateDateBack = (value, currentDay) =>
  new Date(`${value.year}-${value.month}-${value.day}`) < currentDay ||
  "Rango de fecha no permitido";

export const validateFileType = (
  value,
  fileType,
  message = "Tipo de archivo no permitido"
) => {
  const fileList = [...value];

  const Types = fileType.split(",");
  let validate = true;
  fileList.forEach((file) => {
    validate &= Types.includes(file.type);
  });
  return validate || message;
};

export const validateFileExtension = (
  value,
  extension,
  message = "Tipo de archivo no permitido"
) => {
  const fileList = [...value];
  const extensions = extension.split(",");
  let validate = true;
  fileList.forEach((file) => {
    validate &= extensions.includes(getFileExtension(file));
  });
  return validate || message;
};

export const validateFileSize = (value, fileSize) => {
  const fileList = [...value];
  let validate = true;
  fileList.forEach((file) => {
    validate &= fileSize >= file.size;
  });
  return validate || "Peso de archivo supera el máximo permitido";
};

export const validateDateEquealOrForward = (value, currentDay) => {
  const inDate = new Date(value);
  const nowDate = currentDay;
  return (
    inDate >= nowDate ||
    toDate(inDate) === toDate(nowDate) ||
    "Rango de fecha no permitido"
  );
};
export const validateDateEqualOrBack = (value, currentDay) => {
  const inDate = new Date(value);
  const nowDate = currentDay;
  return (
    inDate <= nowDate ||
    toDate(inDate) === toDate(nowDate) ||
    "Rango de fecha no permitido"
  );
};

export const validateDateOverEighteen = (value, currentDay) => {
  const inDate = new Date(value);
  const nowDate = new Date(currentDay);
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth();
  const day = nowDate.getDate();
  const maxDate = new Date(year - 18, month, day);
  return (
    inDate <= maxDate ||
    toDate(inDate) === toDate(maxDate) ||
    "Debe ser mayor de edad"
  );
};

// Validaciones conjuntas
export const vRut = (
  requireText = "Este campo es requerido",
  required = true
) => ({
  required: required ? requireText : false,
  pattern: patternRut,
  validate: validateRut,
});

export const vNombres = (
  requiredText = null,
  maxLength = 70,
  minLength = 3
) => ({
  required: requiredText ?? false,
  pattern: patternAlphaSpace,
  maxLength: maxLengthGeneral(maxLength),
  minLength: minLengthGeneral(minLength),
  validate: validateTrim,
});

export const vEmail = (requiredText = null, maxLength = 100) => ({
  required: requiredText ?? false,
  pattern: patternEmail,
  maxLength: maxLengthGeneral(maxLength),
});

export const vNewPasswordRepeat = (compareString, requiredText = null) => ({
  required: requiredText ?? false,
  validate: (value) =>
    value === compareString ||
    value?.length === 0 ||
    "Contraseñas deben coincidir",
});

export const vDireccion = (
  requiredText = null,
  maxLength = 80,
  minLength = 4
) => ({
  required: requiredText ?? false,
  pattern: patternAddress,
  maxLength: maxLengthGeneral(maxLength),
  minLength: minLengthGeneral(minLength),
  validate: validateTrim,
});

export const vTelefono = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  pattern: patternNum,
  minLength: minLengthTelefono,
});

export const vNumeroDireccion = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  pattern: patternNum,
  maxLength: maxLengthNumeroDireccion,
});

export const vGlosa = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  maxLength: maxLengthGlosa,
});

export const vDateEqualOrForward = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  validate: validateDateEquealOrForward,
});

export const vDateEqualOrBack = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  validate: validateDateEqualOrBack,
});

export const vDateFechaNacimiento = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  validate: validateDateOverEighteen,
});

export const validateFechaTerminoCotizacion = (
  value,
  fechaInicio,
  finalidad = "",
  arrLinea = []
) => {
  if (!fechaInicio) {
    return "Debes ingresar fecha de inicio";
  } else if (!value) {
    return "Debes ingresar fecha de término";
  }

  const fechaInicioFormated = dayjs(fechaInicio);
  const fechaTerminoFormated = dayjs(value);

  if (fechaTerminoFormated < fechaInicioFormated) {
    return "Fecha de término inferior a la de inicio";
  } else if (!!!finalidad || finalidad === "") {
    return "Selecciona finalidad";
  } else if (!!arrLinea) {
    const linea = arrLinea
      .filter((l) => l.name != null)
      .find((x) => x.name === finalidad);

    if (!!!linea) return "Cliente no posee líneas";

    return true;
  } else return "Cliente no posee líneas";
};

export const validateFechaTerminoEmision = (
  value,
  fechaInicio,
  finalidad = "",
  arrLinea = []
) => {
  if (!fechaInicio) {
    return "Debes ingresar fecha de inicio";
  } else if (!value) {
    return "Debes ingresar fecha de término";
  }
  const fechaInicioFormated = dayjs(fechaInicio);
  const fechaTerminoFormated = dayjs(value);

  if (fechaTerminoFormated < fechaInicioFormated) {
    return "Fecha de término inferior a la de inicio";
  } else if (!!!finalidad || finalidad === "") {
    return "Selecciona finalidad";
  } else if (!!arrLinea) {
    const linea = arrLinea
      .filter((l) => l.idFinalidad != null)
      .find((x) => x.descripcionFinalidad === finalidad);

    if (!!!linea) return "Cliente no posee líneas";

    return true;
  } else return "Cliente no posee líneas";
};

export const validateCurrencyType = (required = true) => ({
  required: required ? "Debes seleccionar el tipo de moneda" : false,
});
export const validatePurpose = (required = true) => ({
  required: required ? "Debes seleccionar la finalidad del certificado" : false,
});

export const validateMontoGarantia = (
  dataMandante,
  montoGarantia,
  finalidad,
  tipoMoneda,
  arrLinea,
  valorUF,
  valorUSD
) => {
  if (montoGarantia) {
    if (!!!finalidad) return "Selecciona finalidad";
    else if (!!!tipoMoneda) return "Selecciona moneda";
    else if (!!!arrLinea) return "No existe línea";

    if (tipoMoneda === "UF" /*uf*/) montoGarantia = montoGarantia * valorUF;
    if (tipoMoneda === "USD" /*uf*/) montoGarantia = montoGarantia * valorUSD;

    const linea = arrLinea.find((x) => x.descripcionFinalidad === finalidad);

    const { montoLineaNoUtilizado, montoMaximoAprobado } = linea;

    if (!montoLineaNoUtilizado) return "Contacte a su ejecutiva";
    else if (montoGarantia > montoMaximoAprobado)
      return (
        "Excede el máximo de tu ticket, Disponible:" +
        convertToPeso(montoMaximoAprobado)
      );
    else if (montoGarantia > montoLineaNoUtilizado)
      return (
        "Excede el máximo de tu línea, Disponible:" +
        convertToPeso(montoLineaNoUtilizado)
      );

    if(dataMandante?.montoDisponible) {
      if(dataMandante?.montoDisponible < montoGarantia) {
        return (
          "Monto ingresado supera el máximo disponible para esté mandante."
        );
      }
    }
    return true;
  } else {
    return true;
  }
};

export const vMontoCertificadoEmision = (
  dataMandante,
  finalidad,
  tipoMoneda,
  arrLinea,
  valorUF,
  valorUSD,
  required = true
) => ({
  required: required ? "Debes ingresar el monto de la garantía" : false,
  max: maxMontoCertificado,
  validate: {
    monto: (v) =>
      validateMontoGarantia(
        dataMandante,
        v,
        finalidad,
        tipoMoneda,
        arrLinea,
        valorUF,
        valorUSD
      ),
  },
});

export const vBeginDateEmision = (finalidad, currentDay, required = true) => ({
  required: required ? "Debes ingresar fecha de inicio" : false,
  //pattern: patternNumeroSeparadorMilesComas,
  validate: {
    fechaLocal: (v)=>validateDateEquealOrForward(v, currentDay),
    finalidad: () => !!finalidad || "Selecciona finalidad",
  },
});

export const vEndDateCotizacion = (
  fechaInicio,
  finalidad,
  arrLinea,
  currentDay,
  required = true
) => ({
  required: required ? "Debes ingresar fecha de término" : false,
  validate: {
    fechaLocal: (v)=>validateDateEquealOrForward(v, currentDay),
    finalidad: () => !!finalidad || "Selecciona finalidad",
    neg: (v) =>
      validateFechaTerminoCotizacion(v, fechaInicio, finalidad, arrLinea),
  },
});

export const vEndDateEmision = (
  fechaInicio,
  finalidad,
  arrLinea,
  currentDay,
  required = true
) => ({
  required: required ? "Debes ingresar fecha de término" : false,
  validate: {
    fechaLocal: (v)=>validateDateEquealOrForward(v, currentDay),
    finalidad: () => !!finalidad || "Selecciona finalidad",
    neg: (v) =>
      validateFechaTerminoEmision(v, fechaInicio, finalidad, arrLinea),
  },
});

export const validateMontoReemision = (
  dataMandante,
  montoGarantia,
  finalidad,
  tipoMoneda,
  arrLinea,
  valorUF,
  valorUSD,
  montoOriginal,
  tipoMonedaOriginal
) => {
  if (montoGarantia) {
    if (!!!finalidad) return "Selecciona finalidad";
    else if (!!!tipoMoneda) return "Selecciona moneda";
    else if (!!!arrLinea) return "No existe línea";

    if (tipoMoneda === "UF" /*uf*/) montoGarantia = montoGarantia * valorUF;
    if (tipoMoneda === "USD" /*uf*/) montoGarantia = montoGarantia * valorUSD;
    
    if (tipoMonedaOriginal === "UF" /*uf*/) montoOriginal = montoOriginal * valorUF;
    if (tipoMonedaOriginal === "USD" /*uf*/) montoOriginal = montoOriginal * valorUSD;

    const linea = arrLinea.find((x) => x.descripcionFinalidad === finalidad);

    const { montoLineaNoUtilizado, montoMaximoAprobado } = linea;
    
    // calcular diferencia
    const montoAdicional = montoGarantia - montoOriginal;

    if (!montoLineaNoUtilizado) return "Contacte a su ejecutiva";
    else if (montoAdicional > montoMaximoAprobado)
      return (
        "Excede el máximo de tu ticket, Disponible:" +
        convertToPeso(montoMaximoAprobado)
      );
    else if (montoAdicional > montoLineaNoUtilizado)
      return (
        "Excede el máximo de tu línea, Disponible:" +
        convertToPeso(montoLineaNoUtilizado)
      );

    if(dataMandante?.montoDisponible) {
      if(dataMandante?.montoDisponible < montoAdicional) {
        return (
          "Monto ingresado supera el máximo disponible para esté mandante."
        );
      }
    }
    return true;
  } else {
    return true;
  }
};

//Exclusivo Reemisión
export const vMontoCertificadoReissue = (
  dataMandante,
  finalidad,
  tipoMoneda,
  arrLinea,
  valorUF,
  valorUSD,
  montoOriginal,
  tipoMonedaOriginal,
  required = true
) => ({
  required: required ? "Debes ingresar el monto de la garantía" : false,
  max: maxMontoCertificado,
  validate: {
    monto: (v) =>
      validateMontoReemision(
        dataMandante,
        v,
        finalidad,
        tipoMoneda,
        arrLinea,
        valorUF,
        valorUSD,
        montoOriginal,
        tipoMonedaOriginal
      ),
  },
});
// export const vMontoCertificadoReissue = (
//   token,
//   NroCertificado,
//   Finalidad,
//   TipoMoneda,
//   required = true
// ) => ({
//   required: required ? "Este campo es requerido" : false,
//   max: maxMontoCertificado,
//   validate: {
//     monto: async (value) =>
//       await asyncValidateReemisionAmount(
//         value,
//         token,
//         NroCertificado,
//         Finalidad,
//         TipoMoneda
//       ),
//   },
// });


export const validateFilterName = (
  filters,
  required = true,
  max
) => ({
  required: required ? "Debes ingresar un nombre para el filtro" : false,
  maxLength: maxLengthGeneral(max),
  validate: {
    name: (value) =>
    validateName(
        filters,
        value,
      ),
    validateTrim
  },
});

export const validateName = (filters, value) => {
  let index = filters?.findIndex((x) => x.descripcion === value);
  if (index > -1) {
    return "Este nombre ya está en uso";
  } else {
    return true;
  }
};


export const validateMontoCotizacion = (
  montoGarantia,
  finalidad,
  tipoMoneda,
  arrLinea,
  valorUF,
  valorUSD
) => {
  if (montoGarantia) {
    if (!!!finalidad) return "Selecciona finalidad";
    else if (!!!tipoMoneda) return "Selecciona moneda";
    else if (!!!arrLinea) return "No existe línea";

    if (tipoMoneda === "UF" /*uf*/) montoGarantia = montoGarantia * valorUF;
    if (tipoMoneda === "USD" /*uf*/) montoGarantia = montoGarantia * valorUSD;

    const linea = arrLinea.find((x) => x.descripcionFinalidad === finalidad);

    const { montoLineaNoUtilizado, montoMaximoAprobado } = linea;
  
    if (!montoLineaNoUtilizado) return "Contacte a su ejecutiva";
    else if (montoGarantia > montoMaximoAprobado)
      return (
        "Excede el máximo de tu ticket, Disponible:" +
        convertToPeso(montoMaximoAprobado)
      );
    else if (montoGarantia > montoLineaNoUtilizado)
      return (
        "Excede el máximo de tu línea, Disponible:" +
        convertToPeso(montoLineaNoUtilizado)
      );
    else return true;
  } else {
    return true;
  }
};

export const vMontoCertificadoCotizacion = (
  finalidad,
  tipoMoneda,
  arrLinea,
  valorUF,
  valorUSD,
  required = true
) => ({
  required: required ? "Debes ingresar el monto de la garantía" : false,
  max: maxMontoCertificado,
  validate: {
    monto: (v) =>
      validateMontoCotizacion(
        v,
        finalidad,
        tipoMoneda,
        arrLinea,
        valorUF,
        valorUSD
      ),
  },
});
