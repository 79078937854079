import dayjs from "dayjs";

export function sonRegionesIguales(region1Nombre, region2Nombre) {
  region1Nombre = region1Nombre.replace(/('|´)/g, "");
  region2Nombre = region2Nombre.replace(/('|´)/g, "");
  return region1Nombre === region2Nombre;
}

export function convertDateToDateWithoutTime(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getCurrentDate() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}

export function getNextYearDate(today = new Date()) {
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear() + 1;
  if (dd === "29" || mm === "02") {
    dd = "28";
  }
  return mm + "/" + dd + "/" + yyyy;
}

export function getDateNextDays(days) {
  let today = new Date();
  today.setDate(today.getDate() + days);
  return today;
}

//Retorna una variable desde la url
export const getHelperVariableUrl = (nombreVariable = "user") => {
  let argUrl = window.location.search;
  let params = new URLSearchParams(argUrl);
  return params.get(nombreVariable);
};

//Retorna un objeto con sus atributos con trim
export const getTrimObject = (obj) =>
  Object.keys(obj).map((k) => (obj[k] = obj[k].trim()));

export const roundNumber = (num, scale = 0) => {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, scale)) /
    Math.pow(10, scale)
  );
};

//Ajusta el scroll a la referencia enviada de forma vertical
export const scrollTopRef = (reference, px = 90) => {
  const { current: contenedor } = reference;
  window.scrollTo({
    behavior: contenedor ? "smooth" : "auto",
    top: contenedor ? contenedor.offsetTop - px : 0,
  });
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};


//#endregion

//#region "Archivos"

export const getFileExtension = (file) => {
  return /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : undefined;
};

//#endregion

//#region "Fechas"

export function toDate(date) {
  let today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return (today = mm + "-" + dd + "-" + yyyy);
}

export const toMInputDate = (date) => {
  if (!date || typeof date === "undefined") return "";

  if (typeof date === "string") {
    let today = new Date(date);
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const respuesta = { year, month, day };
    return respuesta;
  } else {
    let today = dayjs(date);

    const day = today.date();
    const month = today.month() + 1;
    const year = today.year();
    const respuesta = { year, month, day };
    return respuesta;
  }
};

export const getDateNowZero = () => {
  const dateToday = dayjs();
  const d = new Date(dateToday.year(), dateToday.month(), dateToday.date());
  return d.toJSON();
};
//Usada para cargar datos al agregar nueva operación

export const getBeginEndDate = (selectedData) => {
  const fechaActual = getDateNowZero();

  if (!!!selectedData.fechaInicio && !!!selectedData.fechaTermino) {
    return {
      fechaInicio: fechaActual,
      fechaTermino: fechaActual,
    };
  } else {
    const fechaActualDayJs = dayjs(fechaActual);

    const fechaInicioDayJs = dayjs(selectedData.fechaInicio);
    const fechaTerminoDayJs = dayjs(selectedData.fechaTermino);

    //Se compara la fecha de inicio con la actual para cambiar la fecha de ser necesario
    const isValid = 0 < fechaInicioDayJs.diff(fechaActualDayJs, "d");
    if (isValid) {
      return {
        fechaInicio: new Date(selectedData.fechaInicio).toJSON(),
        fechaTermino: new Date(selectedData.fechaTermino).toJSON(),
      };
    } else {
      // De no ser valida, se actualizan los valores manteniendo la diferencia
      const diferenciaFechas = fechaTerminoDayJs.diff(fechaInicioDayJs, "d");

      const fechaInicioActualizada = fechaActualDayJs;
      const fechaTerminoActualizada = fechaActualDayJs.add(
        diferenciaFechas,
        "d"
      );

      return {
        fechaInicio: new Date(fechaInicioActualizada).toJSON(),
        fechaTermino: new Date(fechaTerminoActualizada).toJSON(),
      };
    }
  }
};

//#endregion

//#region "Numbers"

export const toMilesNumeros = (numero) => {
  return numero
    .toString()
    .replace(/(\.\d+)|\B(?=(\d{3})+(?!\d))/g, function (m, g1) {
      return g1 || ".";
    });
};

export const toDecimal = (numero) => {
  let resultado = numero.toString().replace(/[^0-9.]/g, function (m, g1) {
    return g1 || "";
  });
  let resultArray = resultado.split(".");

  if (resultArray.length > 2) resultado = resultado.replace(/\.+$/, "");
  if (resultArray.length > 1)
    resultado = `${resultArray[0]}.${resultArray[1].substring(0, 2)}`;
  return resultado;
};

//#endregion

//#region  "Strings"

export const simpleStringify = (object) => {
	const getCircularReplacer = () => {
		const seen = new WeakSet();
		return (key, value) => {
			if (typeof value === "object" && value !== null) {
				if (seen.has(value)) {
					return;
				}
				seen.add(value);
			}
			return value;
		};
	};
	return JSON.stringify(object, getCircularReplacer()); // returns cleaned up JSON
};

export const convertToComparableString = (value) => {
	return (value?.toString() ?? "")
		.normalize("NFD")
		.replace(
			/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
			"$1"
		)
		.normalize()
		.toLowerCase();
};

//#endregion

