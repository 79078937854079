import React, { ReactChild, ReactChildren } from "react";

interface Props {
  children: ReactChild | ReactChildren;
}

const MasterPublic = ({ children }: Props) => {
  return <div className="main-container">{children}</div>;
};

export default MasterPublic;