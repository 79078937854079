// Filtra los seguros externos SOAP & COVID
export class FilterExternalInsurances {

    static filter(id: number) {
        switch (id) {
            case 4:
                return true;
            case 19:
                return true;
            case 15:
                return true;
            default:
                return false;
        }
    };

}