import { LocationPropsTRC } from "components/pages/Private/TRCInsurance/TRCInsurance";

import { useAppSelector } from "@redux/hooks";
import { TypeInsurance } from "@redux/models/TypeInsuranceModels";
import { useState } from "react";
import { DetailCardInsurance } from "./DetailCardInsurance";
import { ButtonsCardInsurance } from "./ButtonsCardInsurance";
import { RenderImgInsurance } from "./RenderImgInsurance";
import { ModalFileHelpInsurance } from "./ModalFileHelpInsurance";

export interface CardInsuranceProps {
  insurance: TypeInsurance;
  tipoSeguroTRC?: LocationPropsTRC;
}

export const CardInsurance = ({
  insurance,
  tipoSeguroTRC,
}: CardInsuranceProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [idOpcionPropuesta, setIdOpcionPropuesta] = useState<number>(0);

  const { idClassification } = useAppSelector((store) => store.account);

  const blockIfConstruction =
    idClassification === 2 && insurance.idOpcionPropuesta === 1;

  return (
    <>
      <div className="mh-100 d-inline-block m-2 p-2">
        <div className="row">
          <div className="col-12 bg-white card-product-seguros p-4 mb-4 max-height">
            <div className="row h-100 align-items-between">
              <div className="col-12 d-flex justify-content-center">
                <RenderImgInsurance
                  insurance={insurance}
                  blockIfConstruction={blockIfConstruction}
                />
              </div>
              <div className="col-12 ">
                <div className="row align-items-end justify-content-center">
                  <DetailCardInsurance
                    insurance={insurance}
                    blockIfConstruction={blockIfConstruction}
                  />
                  <ButtonsCardInsurance
                    blockIfConstruction={blockIfConstruction}
                    setModalOpen={setModalOpen}
                    setIdOpcionPropuesta={setIdOpcionPropuesta}
                    tipoSeguroTRC={tipoSeguroTRC}
                    insurance={insurance}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFileHelpInsurance
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        idOpcionPropuesta={idOpcionPropuesta}
      />
    </>
  );
};
