import { createAction } from '@reduxjs/toolkit';

import {
  Activity,
  Cart,
  ValueCurrency,
} from '../models/SessionModels';

export const cleanStateCart = createAction("SESSION_CLEAN_STATE_CART");
export const cleanState = createAction('GLOBAL_CLEAN_STATE');

export const loadActivities = createAction(
  "SESSION_LOAD_ACTIVITIES",
  (activities: Activity[]) => {
    return {
      payload: activities,
    };
  }
);


export const loadActivitiesRC = createAction(
  "SESSION_LOAD_ACTIVITIES_RC",
  (activities: Activity[]) => {
    return {
      payload: activities,
    };
  }
);

export const loadUf = createAction(
  "SESSION_LOAD_UF",
  (value: number, date: Date) => {
    return {
      payload: {
        value: value,
        date: date,
      } as ValueCurrency,
    };
  }
);

export const loadUsd = createAction(
  "SESSION_LOAD_USD",
  (value: number, date: Date) => {
    return {
      payload: {
        value: value,
        date: date,
      } as ValueCurrency,
    };
  }
);

export const loadCart = createAction(
  "SESSION_LOAD_ITEMSCART",
  (itemsCart: Cart) => {
    return {   
      payload: itemsCart,
    };
  }
);

export const addItemToCart = createAction(
  "SESSION_ADD_ITEM_TO_CART",
  (item: any) => {
    return {
      payload: item,
    };
  }
);

export const loadSurvey = createAction("SESSION_SURVEY", (item: boolean) => {
  return {
    payload: item,
  };
});
