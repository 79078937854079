import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "@redux/hooks";

interface PairPurposeClassName {
  HandlerNavbarCollapse: any;
}

const MenuPrincipal = (props: PairPurposeClassName) => {
  const location = useLocation();
  const { enabledEmit } = useAppSelector((store) => store.account);
  const { cart } = useAppSelector((store) => store.session);
  const [ totalCart, setTotalCart] = useState<number>(0);
  useEffect(() => {

   setTotalCart(cart.item.listInsuranceRC.length + cart.item.listInsurancePF.length + cart.item.listInsuranceTRC.length ?? 0);

  }, [cart]);

  return (
    <>
      <hr className="my-0" />
      <li className="nav-item menu-home-pr">
        <Link
          className={`nav-link ${
            "/home" === location.pathname ? "active" : ""
          }`}
          to="/home"
        >
          Inicio
        </Link>
      </li>
      <li className="nav-item menu-home-pr">
        <Link
          className={`nav-link ${
            "/descarga-poliza" === location.pathname ? "active" : ""
          }`}
          to="/descarga-poliza"
        >
          Descargar Póliza
        </Link>
      </li>
      <li className="nav-item menu-home-pr">
        <Link
          className={`nav-link ${
            "/homecart" === location.pathname ? "active" : ""
          }`}
          to="/homecart"
        >
          Carrito
          <i className="fas fa-shopping-cart"></i>
          {cart?.item && totalCart !== 0 &&(
            <span className="container-cart-counter">
              <span className="cart-counter">{totalCart}</span>
            </span>
          )}
        </Link>
      </li> 
    </>
  );
};

export default MenuPrincipal;
