import { DataWork } from "@redux/models/InsuranceModels";
import {
  Insurance,
  InsuranceQuote,
  InsuranceRCAddToCart,
  InsurancePFAddToCart,
  InsuranceTRCAddToCart,
} from "@redux/models/InsuranceModels";
import { MAxios, InjectTokenHeader } from "../conf";

export const callSeguroTRCInsuranceCoverageGetInfoCoverageAll = async (
  anio: string,
  idTipoMoneda: string,
  idTipoSeguro: string,
  idOpcionPropuesta: string,
  montoUfAseguradoObra: string,
  primaAfecta: string,
  primaExenta: string,
  periodoMantencion: string,
  numeroTrabajadores: string
) => {
  const endpoint =
    "seguro/TodoRiesgoConstruccion/InsuranceCoverage/GetInfoCoverageAll";

  const formData = new FormData();
  formData.append("anio", anio);
  formData.append("idTipoMoneda", idTipoMoneda);
  formData.append("idTipoSeguro", idTipoSeguro);
  formData.append("idOpcionPropuesta", idOpcionPropuesta);
  formData.append("montoUfAseguradoObra", montoUfAseguradoObra);
  formData.append("primaAfecta", String(primaAfecta));
  formData.append("primaExenta", String(primaExenta));
  formData.append("periodoMantencion", periodoMantencion);
  formData.append("numeroTrabajadores", numeroTrabajadores);

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const callSeguroRCInsuranceCoverageGetInfoCoverageAll = async (
  anio: string,
  idTipoMoneda: string,
  idTipoSeguro: string
) => {
  const endpoint = "seguro/InsuranceCoverage/GetInfoCoverageAll";
  const formData = new FormData();
  formData.append("anio", anio);
  formData.append("idTipoMoneda", idTipoMoneda);
  formData.append("idTipoSeguro", idTipoSeguro);

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const callSeguroRCInsuranceCoverageGetInfoCoverage = async (
  anio: string,
  idTipoMoneda: string,
  idTipoSeguro: string,
  idOpcionPropuesta: string,
  montoPrima: string | null = null
) => {
  const endpoint = "seguro/InsuranceCoverage/GetInfoCoverage";

  let amountPrima: number | null = 0;

  console.log(montoPrima)
  if (montoPrima !== null) {
    amountPrima = parseFloat(montoPrima);
  } else {
    amountPrima = null;
  }

  const formJson = {
    anio: Number(anio),
    idTipoMoneda: Number(idTipoMoneda),
    idTipoSeguro: Number(idTipoSeguro),
    idOpcionPropuesta: Number(idOpcionPropuesta),
    montoPrima: amountPrima,
  };

  const res = await MAxios().post(endpoint, formJson);

  return res.data;
};

export const callPlanesSeguroAll = async (idTipoSeguro: string) => {
  const endpoint = "seguro/insurance/GetInfoInsurance";

  const formData = new FormData();
  formData.append("idTipoSeguro", idTipoSeguro);

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const calltipoObraTRCAll = async () => {
  const endpoint = "seguro/TypeConstruction/GetAll";

  const res = await MAxios().get(endpoint);

  return res.data;
};

export const calltipoRiesgoObraTRCAll = async () => {
  const endpoint = "seguro/RiskTypeConstruction/GetAll";

  const res = await MAxios().get(endpoint);

  return res.data;
};

export const callperiodosMantencionTRCAll = async () => {
  const endpoint = "seguro/MaintenancePeriodConstruction/GetAll";

  const res = await MAxios().get(endpoint);

  return res.data;
};

export const SaveInfoInsurance = async (insurance: Insurance) => {
  const endpoint = "seguro/Insurance/SaveInfoInsurance";

  const res = await MAxios().post(endpoint, insurance);

  return res.data;
};
export const SendQuote = async (insurance: InsuranceQuote) => {
  const endpoint = "seguro/InsuranceCoverage/SendQuote";

  const res = await MAxios().post(endpoint, insurance);

  return res.data;
};

export const SaveInsuranceRC = async (insurance: InsuranceRCAddToCart) => {
  const endpoint = "seguro/Insurance/SaveInsuranceRC";

  const res = await MAxios().post(endpoint, insurance);

  return res.data;
};

export const SaveInsurancePF = async (insurance: InsurancePFAddToCart) => {
  const endpoint = "seguro/Insurance/SaveInsurancePF";

  const res = await MAxios().post(endpoint, insurance);

  return res.data;
};

export const SaveInsuranceTRC = async (insurance: InsuranceTRCAddToCart) => {
  const endpoint = "seguro/Insurance/SaveInsuranceTRC";

  const res = await MAxios().post(endpoint, insurance);

  return res.data;
};

export const GetInsuranceInCart = async () => {
  const endpoint = "seguro/Policy/GetInsuranceInCart";

  const res = await MAxios().get(endpoint);

  return res.data;
};

export const DeleteInsuranceFromCart = async (id: number) => {
  const endpoint = "seguro/Policy/DeleteInsuranceFromCart";

  const formData = new FormData();
  formData.append("idPoliza", id.toString());

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const GetQuotation = async (
  additionalWorkInfo: DataWork,
  idOpcionPropuesta: number
) => {
  const endpoint = "seguro/todoRiesgoConstruccion/policyQuote/GetQuotation";

  let objQuote = {
    idOpcionPropuesta: idOpcionPropuesta,
    montoUfAseguradoObra: additionalWorkInfo.montoUfAseguradoObra,
    montoUfResponsabilidadCivil: additionalWorkInfo.montoUfAseguradoObra,
    idPeriodoMantencionObra: additionalWorkInfo.idPeriodoMantencionObra,
    idTipoRiesgoObra: additionalWorkInfo.idTipoRiesgoObra,
    vigenciaInicioObra: new Date(additionalWorkInfo.vigenciaInicioObra),
    vigenciaTerminoObra: new Date(additionalWorkInfo.vigenciaTerminoObra),
  };

  const res = await MAxios().post(endpoint, objQuote);

  return res.data;
};

const ApiInsurance = {
  callSeguroTRCInsuranceCoverageGetInfoCoverageAll,
  callPlanesSeguroAll,
  callSeguroRCInsuranceCoverageGetInfoCoverageAll,
  callSeguroRCInsuranceCoverageGetInfoCoverage,
  SaveInsuranceRC,
  SaveInsurancePF,
  SendQuote,
  GetInsuranceInCart,
  DeleteInsuranceFromCart,
  calltipoObraTRCAll,
  calltipoRiesgoObraTRCAll,
  callperiodosMantencionTRCAll,
  SaveInsuranceTRC,
  GetQuotation,
};

export default ApiInsurance;
