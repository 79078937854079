import React from "react";
import { MButton, MTitle } from "@inversiones-ma/finfast-react-controls";
import download from "@img/cloud-arrow-down-solid.svg";
import { dataPolicy } from "../DownloadPolicy";
import dayjs from "dayjs";

interface props {
  dataPolicy: dataPolicy;
  resetValidation: any;
  inRequestDownload: any;
  DownloadPolicy: any;
}

function ValidationResult(props: props) {
  let dateNow: Date = new Date();

  return (
    <div className="bg-1">
      <div className=" text-center p-4">
        <span className="tx-1">Seguro</span>
        <br />
        <span className="subtitle-mini mb-0 text-center type-pol">
          {props.dataPolicy.poliza.propuesta}
        </span>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-4">
              <span className="tx-1">Datos del asegurado</span>
              <p>{props.dataPolicy.asegurados[0].nombre}</p>
              <p>Rut: {props.dataPolicy.asegurados[0].rut}</p>
            </div>
            <div className="col-12 col-sm-4 vl">
              <span className="tx-1">Datos contratante</span>
              <p>{props.dataPolicy.asegurados[0].nombre}</p>
              <p>Rut: {props.dataPolicy.asegurados[0].rut}</p>
            </div>
            <div className="col-12 col-sm-4 vl">
              <span className="tx-1">Estado de póliza </span>
              <p>
                {props.dataPolicy.poliza.estadoPoliza && (
                  <span className="ellipse-success"></span>
                )}
                {!props.dataPolicy.poliza.estadoPoliza && (
                  <span className="ellipse-error"></span>
                )}
                {props.dataPolicy.poliza.estadoPoliza
                  ? "Vigente"
                  : "No vigente"}
              </p>
              <p>
                Hasta {dayjs(props.dataPolicy.vigencia).format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
        </div>
        <span className="subtitle-mini mb-0 text-center text-monto">
          Monto asegurado
        </span>
        <MTitle position="center">
          <span className="text-prima">
            {props.dataPolicy.poliza.moneda} {props.dataPolicy.poliza.prima}
          </span>
        </MTitle>
        <span>
          Esta póliza posee firma electrónica avanzada
          <br />y está certificada.
        </span>
        <div className="text-center pt-4">
          <div>
            <div>
              <MButton
                className=""
                onClick={() => {
                  props.DownloadPolicy();
                }}
                loading={props.inRequestDownload}
              >
                Descargar póliza {"  "}
                <i
                  className="fas fa-cloud-download-alt boton-certificado"
                  aria-hidden="true"
                ></i>{" "}
              </MButton>
            </div>
            <div className="pt-3">
              <MButton
                className=""
                onClick={() => {
                  props.resetValidation();
                }}
                outline
              >
                Nueva consulta
              </MButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidationResult;
