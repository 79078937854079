import {
  useEffect,
  useState,
} from 'react';

import { upperCase } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import BaseModel from '@api/BaseModel';
import GenericBaseModel from '@api/GenericBaseModel';
import ApiInsurance from '@api/insurance/insurance';
import { toDate2 } from '@helpers/helpers';
import {
  MButton,
  MGrid,
  MGridColumn,
  MInputCheckBox,
  MInputDate,
} from '@inversiones-ma/finfast-react-controls';
import { loadCart } from '@redux/actions/SessionActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import {
  Insurance,
  InsurancePFAddToCart,
  InsurancePFModel,
  InsuranceQuote,
} from '@redux/models/InsuranceModels';
import { Activity } from '@redux/models/SessionModels';

import {
  handleResponsePfAddToCart,
  handleSendPFAddToCart,
} from '../../Cart';
import { Terms } from './Modals/Terms';

type StepThreeProps = {
  handleChangeStep: Function;
};

export const StepThree = ({ handleChangeStep }: StepThreeProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { cart, activities } = useAppSelector((store) => store.session);
  const { assured, plans, prima } = useAppSelector(
    (store) => store.insurancePA
  );
  
  const [checkActivity, setCheckActivity] = useState<Activity[]>(activities);
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<boolean>(false);
  const [fechaTermino, setFechaTermino] = useState<string>("");
  const [activitiesSelectedCount, setActivitiesSelectedCount] =
    useState<number>(0);

  const [showModalTerms, setShowModalTerms] = useState<boolean>(false);
  const [isCheckAllActivity, setIsCheckAllActivity] = useState(false);
  const [dateInicio, setDateinicio] = useState<Date>(new Date());

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { getValues, watch, handleSubmit, formState } = form;
  const { fechaInicio } = watch();

  const sumarDias = (fecha: Date, dias: number) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  };

  const handleShowTerms = () => {
    setShowModalTerms(true);
  };

  const [dateMax, setDateMax] = useState<Date>(sumarDias(new Date(), 30));

  const onChangeFechaInicio = () => {
    const { fechaInicio } = getValues();
    let fecha: Date = new Date(fechaInicio);
    fecha.setFullYear(fecha.getFullYear() + 1);
    let fechaString = toDate2(fecha.toDateString());
    setFechaTermino(fechaString);
    setStartDate(true);
  };

  const handleOnchangeActividad = (event: any, value: number) => {
    if (event !== "") {
      setCheckActivity(
        checkActivity.map((ac) =>
          ac.code === value ? { ...ac, checked: !ac.checked } : { ...ac }
        )
      );
    }
  };

  const handleOnchangeActividadAll = (event: any) => {
    if (event !== "") {
      setIsCheckAllActivity(!isCheckAllActivity);
      setCheckActivity(
        checkActivity.map((ac) => {
          return { ...ac, checked: !isCheckAllActivity };
        })
      );
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setActivitiesSelectedCount(checkActivity.filter((a) => a.checked).length);
  }, [checkActivity, setCheckActivity]);

  const onSubmit = (data: any) => {
    setIsAddingToCart(true);

    let insurancePFAddToCart: InsurancePFAddToCart | undefined;
    insurancePFAddToCart = handleSendPFAddToCart(
      assured,
      checkActivity,
      plans,
      prima,
      fechaInicio
    );

    const planSelected = plans.find(
      (a) => a.idPropuesta === assured.idPlanPropuesta
    );

    if (insurancePFAddToCart) {
      let quotePF: InsuranceQuote = {
        correo: assured.mailAsegurado,
        idOpcion: planSelected?.idPropuesta!,
        IdTipoSeguro: 2,
        nombre: assured.razonSocialAsegurado,
        rut: assured.rutAsegurado,
        numEmpleados: assured.cantidadTrabajadores,
        seguroOpc: assured.rgm,
      };

      ApiInsurance.SendQuote(quotePF)
        .then((resp: GenericBaseModel<BaseModel>) => {
          if (resp.success) {
            ApiInsurance.SaveInsurancePF(insurancePFAddToCart!)
              .then((resp: GenericBaseModel<InsurancePFModel>) => {
                if (resp !== null && resp.success) {
                  let newInsurance: Insurance | null;

                  newInsurance = handleResponsePfAddToCart(
                    resp.data,
                    insurancePFAddToCart,
                    cart?.item
                  );

                  if (newInsurance !== null) {
                    dispatch(loadCart({ item: newInsurance }));
                  }

                  history.push("/homecart/step1");
                }
              })
              .catch((err) => {
                console.error("Ha ocurrido un error:" + err.message);
              });
          }
        })
        .catch((err) => {
          console.error("Ha ocurrido un error:" + err.message);
        })
        .finally(() => {
          setIsAddingToCart(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="section-insurance">
        <h2 className="titles-center fs-24">ingresa información adicional</h2>
        <p className="text-center pb-4">
          Indica la fecha de inicio y las actividades a asegurar
        </p>
        <h3 className="subtitle-mini">Ingresa fecha de inicio de vigencia</h3>
        <div className="col-12">
          <div
            className="alert alert-info d-flex align-items-start"
            role="alert"
          >
            <div className="icon">
              <i className="fas fa-info-circle" aria-hidden="true"></i>
            </div>
            <div>
              La fecha inicio de la vigencia debe estar dentro de los{" "}
              <strong>próximos 30 días</strong>.
            </div>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 col-md-6">
            <div className="floating-label">
              <MInputDate
                form={form}
                name="fechaInicio"
                label="Fecha Inicio"
                min={dateInicio}
                max={dateMax}
                onChange={onChangeFechaInicio}
                calendarPopperPosition="auto"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
            <p className="fs-14 color-secondary m-0">Fecha de término</p>
            <p className="fs-16 color-primary m-0 fw-bold">{fechaTermino}</p>
          </div>
        </div>
        <div className="dashed-separator my-3"></div>
        <div className="row">
          <div className="col-12">
            <h3 className="subtitle-mini my-3">
              Selecciona la actividad a asegurar
            </h3>
            <p className="fs-16 color-text text-start">
              Busca y selecciona la actividad que deseas asegurar.
            </p>
            <div className="row justify-content-end">
              <div className="col-12 seleccionart-todos-check">
                <MInputCheckBox
                  name="activitiesSelectedAll"
                  onClick={(event) => handleOnchangeActividadAll(event)}
                  form={form}
                  checked={isCheckAllActivity}
                  className="float-end"
                  label={
                    <p className="fs-14 color-secondary mb-3">
                      Seleccionar todos{" "}
                    </p>
                  }
                ></MInputCheckBox>
              </div>
            </div>
            <div className="tbodyDiv">
              {checkActivity && checkActivity.length > 0 && (
                <MGrid showMoreSize={10} dataSource={checkActivity}>
                  <MGridColumn
                    dataField="description"
                    label="Nombre Actividad"
                    cellRender={(_k: any, value: any, row: any) => {
                      return <> {upperCase(value)} </>;
                    }}
                  />
                  <MGridColumn
                    dataField="code"
                    label="Seleccionar"
                    className="col text-center"
                    headerClassName="center-header"
                    cellRender={(_k: any, value: number, row: Activity) => {
                      return (
                        <>
                          {" "}
                          {
                            <MInputCheckBox
                              name={value.toString()}
                              checked={row.checked}
                              form={form}
                              value={value}
                              className="text-center"
                              centered
                              onClick={(event) =>
                                handleOnchangeActividad(event, value)
                              }
                            />
                          }{" "}
                        </>
                      );
                    }}
                  />
                </MGrid>
              )}
            </div>
            <div className="col-12 my-2">
              <p className="fs-16 color-text text-center ">
                Total de actividades seleccionadas:{" "}
                <span className="color-primary fs-16 fw-bold">
                  {activitiesSelectedCount}
                </span>
              </p>
            </div>
            <div className="row py-4 justify-content-center">
              <div className="col-12 col-md-6">
                <div className="form-check d-flex justify-content-center">
                  <MInputCheckBox
                    form={form}
                    name={"terminosCondiciones"}
                    rules={{
                      required: "Debes aceptar los términos y condiciones",
                    }}
                  />
                  <a
                    onClick={handleShowTerms}
                    className="text-link mx-2 fs-14 color-secondary mb-3"
                  >
                    Acepto los Términos y Condiciones, verifico la autenticidad,
                    exactitud, vigencia y veracidad de los datos ingresados.
                  </a>
                </div>
              </div>
              <div className="dashed-separator my-3"></div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row pb-2">
          <div className="col-lg-4 col-md-4 order-3 order-md-1">
            <MButton
              type="button"
              className="btn btn-primary sistema outline w-100"
              onClick={() => handleChangeStep(2)}
            >
              <i className="fa fa-arrow-left"></i> Volver
            </MButton>
          </div>
          <div className="col-lg-4 col-md-4 order-2"></div>
          <div className="col-lg-4 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
            <MButton
              type="submit"
              className="btn btn-primary sistema w-100"
              loading={isAddingToCart}
              disabled={activitiesSelectedCount === 0 || !formState.isValid}
            >
              Agregar al carro
              <i className="fas fa-shopping-cart" aria-hidden="true"></i>
            </MButton>
          </div>
        </div>
        <Terms
          setShowModal={setShowModalTerms}
          showClose={true}
          showModal={showModalTerms}
        />
      </div>
    </form>
  );
};
