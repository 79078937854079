import React from "react";
import SeguroSOAP from "@img/seguro-soap.svg";
import SeguroCOVID from "@img/seguro-covid.svg";
import { TypeInsurance } from "@redux/models/TypeInsuranceModels";

export interface CardOtherInsuranceProps {
  insurance: TypeInsurance;
}

export const CardExternalInsurance = ({
  insurance,
}: CardOtherInsuranceProps) => {
  const renderImg = () => {
    if ((insurance.id === 19) || (insurance.id === 15))
      return (
        <img
          src={SeguroSOAP}
          alt={insurance.alt}
          className={`text-center ${!insurance.estado && "img-grayscale"}`}
        />
      );
    if (insurance.id === 4)
      return (
        <img
          src={SeguroCOVID}
          alt={insurance.alt}
          className={`text-center ${!insurance.estado && "img-grayscale"}`}
        />
      );
  };

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <div className="col-12 bg-white card-product-seguros-otros p-4">
            <div className="row  align-items-between ">
              <div className="col-12 col-lg-3 justify-content-center">
                {renderImg()}
              </div>
              <div className="col-12 col-lg-9 ">
                <div className="row align-items-end">
                  <div className="col-12 detalle-card-otros">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="fs-24 color-secondary fw-bold mb-0 text-center text-md-start d-flex justify-content-center justify-content-md-start align-items-center">
                          {insurance.title}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="color-text fs-14 text-center text-md-start">
                          {insurance.descripcion}
                        </p>
                      </div>
                    </div>
                    <div className="row"></div>
                  </div>
                  <div className="col-12 col-md-7">
                    <a
                      type="button"
                      className="btn btn-primary sistema w-100 p-2"
                      href={insurance.vinculoPlataformaExterna}
                      target={"_blank"}
                    >
                      Cotizar ahora
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
