import React, { useEffect, useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import * as insuranceActions from "@redux/actions/InsuranceActions";
import { LocationPropsTRC } from "../TRCInsurance";
import {
  MAlert,
  MButton,
  MInputCheckBox,
  MInputDate,
  MInputNumber,
  MInputSelect,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import imgTrc from "@img/icon-seguros-trc.svg";
import RCTermsAndConditions from "../../RCInsurance/Partial/RCTermsAndConditions";
import { changeStep } from "@redux/actions/GlobalActions";
import {
  Insurance,
  InsuranceTRCAddToCart,
  InsuranceTRCModel,
  Prima,
  InsurancePlan,
  DataWork,
} from "@redux/models/InsuranceModels";
import { handleResponseTRCAddToCart, handleSendTRCAddToCart } from "../../Cart";
import ApiInsurance from "@api/insurance/insurance";
import GenericBaseModel from "@api/GenericBaseModel";
import { loadCart } from "@redux/actions/SessionActions";
import { useHistory } from "react-router-dom";
import * as validationsRFH from "../../../../../scripts/js/validationsRFH";
import { Collapse } from "reactstrap";
import { convertToPeso } from "@helpers/helpers";
import TRCViewCoverage from "../Partial/TRCViewCoverage";
import { validateStartDateAndEndDate } from "@helpers/helpers2";

interface PropsTRCAdditionalInfo {
  tipoSeguro: LocationPropsTRC;
}

const TRCAdditionalInfo = (props: PropsTRCAdditionalInfo) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { cart } = useAppSelector((store) => store.session);
  const {
    assuredTrc,
    dataWork,
    plans,
    tiposObra,
    tiposRiesgoObra,
    periodosMantencion,
  } = useAppSelector((store) => store.insuranceTRC);

  const [modalTermsAndCond, setModalTermsAndCond] = useState<boolean>(false);
  const [aceptTermsAndCond, setAceptTermsAndCond] = useState<boolean>(false);
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);
  const [loadingQuote, setloadingQuote] = useState<boolean>(false);
  const [validQuote, setValidQuote] = useState<boolean>(false);
  const [quoteResult, setQuoteResult] = useState<Prima | undefined>(undefined);
  const minDateIni = new Date();
  const [minDateFin, setMinDateFin] = useState<Date>(new Date());
  const [maxDateFin, setMaxDateFin] = useState<Date>(new Date());
  const [insurancePlan, setInsurancePlan] = useState<InsurancePlan | any>(
    plans.find((a) => a.idPropuesta === props.tipoSeguro.id)
  );
  const [alertQuotation, setAlertQuotation] = useState<boolean>(false);

  const form = useForm<DataWork>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: dataWork,
  });

  const checkAceptTermAndCond = (change: boolean) => {
    setAceptTermsAndCond(change);
  };

  const { watch, getValues, setValue, handleSubmit, trigger, formState } = form;
  const {
    idTipoRiesgoObra,
    idTipoObra,
    numeroPisos,
    numeroSubterraneos,
    numeroTrabajadores,
    vigenciaInicioObra,
    vigenciaTerminoObra,
    idPeriodoMantencionObra,
    montoUfAseguradoObra,
  } = watch();

  const showDetailConstruction = useMemo(
    () => idTipoObra?.toString() === "1",
    [idTipoObra]
  );

  const handleAddCart = async () => {
    setIsAddingToCart(true);

    let insuranceTRCAddToCart: InsuranceTRCAddToCart | undefined =
      handleSendTRCAddToCart(
        assuredTrc,
        dataWork,
        //additionalWorkInfo,
        props.tipoSeguro.id //idOpcionPropuesta
      );

    if (insuranceTRCAddToCart) {
      await ApiInsurance.SaveInsuranceTRC(insuranceTRCAddToCart)
        .then((resp: GenericBaseModel<InsuranceTRCModel>) => {
          if (resp !== null && resp.success) {
            let newInsurance: Insurance | null;

            newInsurance = handleResponseTRCAddToCart(resp.data, cart?.item);

            if (newInsurance !== null) {
              dispatch(loadCart({ item: newInsurance }));
            }

            history.push("/homecart/step1");
          }
        })
        .catch((err) => {
          console.error("Ha ocurrido un error:" + err.message);
        })
        .finally(() => {
          setIsAddingToCart(false);
        });
    }
  };

  const handleReturn = () => {
    dispatch(changeStep(2));
  };

  const addTwoYears = () => {
    const dateEnd = new Date(vigenciaTerminoObra);
    const dateStart = new Date(vigenciaInicioObra);
    setMaxDateFin(() => {
      dateStart.setFullYear(dateStart.getFullYear() + 2);
      if (dateEnd > dateStart) {
        setValue("vigenciaTerminoObra", dateStart);
      }

      return dateStart;
    });
  };

  //Set defaultValues when TipoObra = 1
  useEffect(() => {
    if (!!!showDetailConstruction) {
      setValue("numeroPisos", 1);
    }
  }, [showDetailConstruction]);

  //Set min value FechaFin when Upd FechaInicio
  useEffect(() => {
    if (!vigenciaInicioObra) return;

    const newFechaInicio = new Date(vigenciaInicioObra);
    const newFechaTermino = vigenciaTerminoObra
      ? new Date(vigenciaTerminoObra)
      : new Date();

    if (newFechaInicio > newFechaTermino)
      setValue("vigenciaTerminoObra", newFechaInicio);
    setMinDateFin(vigenciaInicioObra);

    addTwoYears();
  }, [vigenciaInicioObra]);

  useEffect(() => {
    dispatch(insuranceActions.loadWorkInformationTRC(getValues()));
    // eslint-disable-next-line
  }, [
    idTipoRiesgoObra,
    idTipoObra,
    numeroPisos,
    numeroSubterraneos,
    numeroTrabajadores,
    vigenciaInicioObra,
    vigenciaTerminoObra,
    idPeriodoMantencionObra,
    montoUfAseguradoObra,
  ]);

  useEffect(() => {
    if (Object.entries(formState.errors).length !== 0) {
      setAlertQuotation(true);
    } else {
      setAlertQuotation(false);
    }
  }, [formState]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);

  const handleCotizar = async (data: DataWork) => {
    setloadingQuote(true);
    setQuoteResult(undefined);
    setValidQuote(false);
    //dispatch(loadWorkInformationTRC(data));

    // Cotiza
    await ApiInsurance.GetQuotation(dataWork, props.tipoSeguro.id)
      .then((resp: GenericBaseModel<Prima>) => {
        if (resp !== null && resp.success) {
          setQuoteResult(resp.data);
          setValidQuote(true);
        } else {
          //error
        }
      })
      .catch((err) => {
        console.error("Ha ocurrido un error:" + err.message);
      })
      .finally(() => setloadingQuote(false));
  };

  const handleOnChange = () => {
    setQuoteResult(undefined);
  };

  return (
    <>
      <div className="col-lg-8">
        <form onSubmit={handleSubmit(handleCotizar)}>
          <div className="section-insurance">
            <h2 className="titles-center fs-24">
              Información de adicional de la Obra
            </h2>
            <p className="text-center pb-4">
              Completa el formulario con los datos de la obra a asegurar
            </p>

            <h3 className="subtitle-mini">Tipo de obra</h3>
            <div className="row pb-3">
              <div className="col-lg-6">
                <div className="floating-label">
                  <MInputSelect
                    form={form}
                    name="idTipoRiesgoObra"
                    className="form-select info-form input-tag"
                    items={tiposRiesgoObra}
                    valueItem="id"
                    label="Tipo de Riesgo"
                    descriptionItem="descripcion"
                    orderBy={{
                      by: "descripcion",
                      desc: false,
                      isString: true,
                    }}
                    rules={{
                      required: "Este campo es requerido",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <MInputSelect
                  name="idTipoObra"
                  form={form}
                  items={tiposObra}
                  label="Tipo de Obra"
                  descriptionItem="descripcion"
                  valueItem="id"
                  rules={{
                    required: "Este campo es requerido",
                  }}
                  orderBy={{
                    by: "descripcion",
                    desc: false,
                    isString: true,
                  }}
                />
              </div>
              <Collapse isOpen={!!showDetailConstruction}>
                <div className="row">
                  <div className="col-lg-6">
                    <fieldset>
                      <div className="floating-label">
                        <MInputNumber
                          name="numeroPisos"
                          label={"Número de pisos"}
                          form={form}
                          className="floating-input"
                          rules={{
                            required: {
                              value: !!showDetailConstruction,
                              message: "Este campo es requerido",
                            },
                            validate: {
                              ...validationsRFH.validateMinMax({
                                minimo: 1,
                                maximo: 200,
                              }),
                            },
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-lg-6">
                    <fieldset>
                      <div className="floating-label">
                        <MInputNumber
                          name="numeroSubterraneos"
                          label={"Número de subterráneaos"}
                          form={form}
                          className="floating-input"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </Collapse>
              <div className="col-lg-6">
                <fieldset>
                  <div className="floating-label">
                    <MInputNumber
                      name="numeroTrabajadores"
                      maxLength={4}
                      label={"Número de trabajadores"}
                      form={form}
                      className="floating-input"
                      rules={{
                        required: "Este campo es requerido",
                      }}
                    />
                  </div>
                </fieldset>
              </div>
            </div>

            <h3 className="subtitle-mini">Período de la obra</h3>
            <div className="row pb-3">
              <div className="col-lg-6">
                <div className="floating-label">
                  <MInputDate
                    name="vigenciaInicioObra"
                    form={form}
                    min={minDateIni}
                    label={"Fecha de inicio"}
                    className="floating-input default"
                    rules={{
                      required: "Este campo es requerido",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <MInputDate
                  name="vigenciaTerminoObra"
                  form={form}
                  min={minDateFin}
                  max={maxDateFin}
                  label={"Fecha de finalización"}
                  className="floating-input default"
                  rules={{
                    required: "Este campo es requerido",
                    validate: {
                      value: (v: Date) =>
                        validateStartDateAndEndDate(
                          new Date(getValues("vigenciaInicioObra")),
                          new Date(getValues("vigenciaTerminoObra"))
                        ),
                    },
                  }}
                />
              </div>
              <div className="col-lg-6">
                <MInputSelect
                  form={form}
                  className="form-select"
                  name="idPeriodoMantencionObra"
                  items={periodosMantencion}
                  valueItem="numeroMeses"
                  descriptionItem="numeroMeses"
                  selectedDefaultValue={true}
                  label="Período de mantención"
                  rules={{
                    required: "Este campo es requerido",
                  }}
                />
              </div>
            </div>

            <h2 className="titles-center fs-20">
              Ingresa el monto asegurado que corresponda{" "}
            </h2>
            <div className="row pb-2 bg-grisf7 p-3">
              <div className="col-12 p-0">
                <p className="color-text ">
                  Modifica el monto asegurado de responsabilidad civil de
                  construcción para lograr la cobertura que necesitas.
                </p>
              </div>
              <div className="card-new-trc m-0 p-0">
                <div className="rounded-card-bg  border-primary lborder-radius-5 p-2 card-trc-new-seguro">
                  <div className="row">
                    <div className="col-12 col-lg-2 col-xl-2 m-0 d-block">
                      <img
                        src={imgTrc}
                        alt="trc-icon"
                        className="trc-icon d-none d-lg-block"
                      />
                    </div>
                    <div className="col-12 col-lg-10">
                      <p className="fs-18 color-secondary ff-lato m-0 p-0">
                        Seguros
                      </p>
                      {props.tipoSeguro?.id === 35 && (
                        <h5 className="fs-24 color-primary ff-kanit m-0 p-0">
                          <strong>
                            Todo Riesgo Construcción + RC de construcción{" "}
                          </strong>
                        </h5>
                      )}
                      {props.tipoSeguro?.id === 38 && (
                        <h5 className="fs-24 color-primary ff-kanit m-0 p-0">
                          <strong>Todo Riesgo Construcción</strong>
                        </h5>
                      )}
                      {props.tipoSeguro?.id === 40 && (
                        <h5 className="fs-24 color-primary ff-kanit m-0 p-0">
                          <strong>RC de Construcción</strong>
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rounded-card-bg color-text-low p-2 pt-4 card-trc-new-seguro-detalle">
                  <div className="row">
                    <div className="col-12">
                      <p className="fs-12 color-text m-1">
                        Valor minímo de UF{" "}
                        {props.tipoSeguro.id === 40 ? 500 : 2500}
                      </p>
                    </div>
                    <div className="col-12 col-lg-8 m-0">
                      <MInputNumber
                        name="montoUfAseguradoObra"
                        maxLength={5}
                        className="floating-input default"
                        label={"Monto asegurado de la obra (UF)"}
                        form={form}
                        placeholder=" "
                        onChange={handleOnChange}
                        rules={validationsRFH.validateMinMaxBetween({
                          required: "Ingrese un valor",
                          unit: "UF",
                          min: props.tipoSeguro.id === 40 ? 500 : 2500,
                          max: props.tipoSeguro.id === 40 ? 10000 : 70000,
                        })}
                      />
                    </div>
                    <div
                      className="col-12 col-lg-4 m-0"
                      style={{ marginTop: "15px" }}
                    >
                      <MButton
                        type="submit"
                        className="w-100"
                        outline
                        loading={loadingQuote}
                      >
                        Cotizar
                      </MButton>
                    </div>

                    <Collapse
                      isOpen={!!quoteResult}
                      className="col-12 my-2"
                      style={{ fontSize: "13px" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h5 className="color-text fs-18 m-0 p-0">
                            {" "}
                            <strong>
                              Prima UF {quoteResult?.primaNeta} anual + IVA
                            </strong>{" "}
                            <small>
                              {convertToPeso(quoteResult?.primaBrutaCLP)}
                            </small>
                          </h5>
                        </div>
                      </div>
                      <TRCViewCoverage
                        planSelected={insurancePlan}
                        prima={quoteResult}
                        expandedLink={true}
                      />
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
            {alertQuotation && (
              <MAlert color="danger" className="mt-3">
                <p style={{ marginBottom: 0 + "px" } as React.CSSProperties}>
                  <span className="ingresa-alert02">
                    Debes rellenar todos los campos de{" "}
                    <strong>Información de la obra,</strong> para realizar la{" "}
                    <strong>cotización de tu seguro</strong>
                  </span>
                  <br />
                </p>
              </MAlert>
            )}
            <div className="d-flex py-4 justify-content-center text-center">
              <div className="form-check">
                <MInputCheckBox
                  className="form-check-inputs"
                  onClick={() => checkAceptTermAndCond(!aceptTermsAndCond)}
                  onClickLabel={() => setModalTermsAndCond(true)}
                  form={form}
                  name="checkTermAndCond"
                  checked={aceptTermsAndCond}
                  labelHtmlFor={false}
                  label={
                    <a className="text-link fs-14 color-secondary mb-3">
                      Acepto los Términos y Condiciones, verifico la
                      autenticidad, exactitud, vigencia y veracidad de los datos
                      ingresados.
                    </a>
                  }
                />
              </div>
            </div>
            <hr />

            <div className="row pb-2">
              <div className="col-lg-3 col-md-4 order-3 order-md-1">
                <MButton
                  type="button"
                  className="btn btn-primary sistema outline w-100"
                  onClick={() => handleReturn()}
                >
                  <i className="fa fa-arrow-left"></i>
                  Volver
                </MButton>
              </div>
              <div className="col-lg-6 col-md-4 order-2"></div>
              <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
                <MButton
                  type="button"
                  className="btn btn-primary sistema w-100"
                  loading={isAddingToCart}
                  disabled={!aceptTermsAndCond || !validQuote}
                  onClick={() => handleAddCart()}
                >
                  Agregar al carro
                  <i className="fas fa-shopping-cart" aria-hidden="true"></i>
                </MButton>
              </div>
            </div>
          </div>
        </form>
        <RCTermsAndConditions
          setShowModal={setModalTermsAndCond}
          showModal={modalTermsAndCond}
          checkAceptTermAndCondModal={checkAceptTermAndCond}
        />
      </div>
    </>
  );
};

export default TRCAdditionalInfo;
