import React, { ReactChild, ReactChildren } from "react";
import MasterPrivateNavbar from "./MasterPrivate/MasterPrivateNavbar";
import Footer from "./partial/Footer";
import "./MasterPrivate.scss";
import { useGliaInitialization } from "../../hooks/useGliaInitialization";
interface Props {
  children: ReactChild | ReactChildren;
}

const MasterPrivate = ({ children }: Props) => {

  useGliaInitialization();
  return (
    <>
      <main className="bg-home">
        <MasterPrivateNavbar />
        <div className="container pb-4">
          <div className="content-container">{children}</div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default MasterPrivate;
