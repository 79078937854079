import LogoRentaNacional from "../../../img/logo-renta-nacional.png";

type InsuranceHeaderProps = {
    id?: number;
    descripcion: string;
}

export const InsuranceHeader = ({ id, descripcion }: InsuranceHeaderProps) => {
    return (
        <div className="section-insurance mt-2">
            <div className="row align-items-center">
                <div className="col-12 col-lg-9">
                    <p className="youarein mb-2">Estás contratando:</p>
                    <h3 className="subtitle-mini mb-3 mb-lg-0">
                        {
                            id === 40 && (
                                <>
                                     RC de Construcción
                                </>
                            )
                        }
                        {
                            id === 35 && (
                                <>
                                    Todo Riesgo Construcción + RC de Construcción
                                </>
                            )
                        }
                        {
                            id === 38 && (
                                <>
                                    Todo Riesgo Construcción
                                </>
                            )
                        }
                        {
                            id === 7 && (
                                <>
                                    Protección Financiera
                                </>
                            )
                        }
                    </h3>
                </div>
                <div className="col-12 col-lg-3 text-start text-lg-end">
                    <img src={LogoRentaNacional} alt="Logo de renta nacional" className="img-fluid" />
                </div>
            </div>
        </div>
    )
}