export const createCobertura = (
    idOpcionPropuesta: number,
    idPlanSeguro: number,
    afecta: number,
    tipoMoneda: string,
    descripcionCobertura: string,
    codigo: string,
    deducible: string,
    monto: number,
    tasa: number,
    PorcentajeBase: number
) => ({
    idOpcionPropuesta,
    idPlanSeguro,
    afecta,
    tipoMoneda,
    descripcionCobertura,
    codigo,
    deducible,
    monto,
    tasa,
    PorcentajeBase,
    montoAsegurado: 0,
    montoAseguradoLabel: 0,
    primaBaseCalculo: 0,
    primaFormateada: 0,
    primaNeta: 0,
});

type TypePrimasMinimas = {
    porPersona: number,
    porPoliza: number
}

export const primasMinimas: TypePrimasMinimas = {
    porPersona: 0.5,
    porPoliza: 1.5,
};

export const coberturas = [
    createCobertura(
        31,
        2,
        0,
        "UF",
        "Protección Financiera Plan I: Muerte Accidental",
        "POL320130695",
        "Sin deducible",
        250,
        0.35,
        0.60
    ),
    createCobertura(
        31,
        3,
        1,
        "UF",
        "Protección Financiera Plan II: Incapacidad Permanente",
        "POL320130695",
        "Sin deducible",
        250,
        0.25,
        0.20
    ),
    createCobertura(
        31,
        4,
        1,
        "UF",
        "Protección Financiera Plan III: Desmembramiento",
        "POL320130695",
        "Sin deducible",
        250,
        0.25,
        0.20
    ),
    createCobertura(
        31,
        5,
        1,
        "UF",
        "RGM: Reembolso de Gastos Médicos por Accidente",
        "Condiciones Particulares",
        "Condiciones Particulares",
        250,
        8,
        0
    ),
    createCobertura(
        32,
        2,
        0,
        "UF",
        "Protección Financiera Plan I: Muerte Accidental",
        "POL320130695",
        "Sin deducible",
        500,
        0.2,
        0.60
    ),
    createCobertura(
        32,
        3,
        1,
        "UF",
        "Protección Financiera Plan II: Incapacidad Permanente",
        "POL320130695",
        "Sin deducible",
        500,
        0.15,
        0.20
    ),
    createCobertura(
        32,
        4,
        1,
        "UF",
        "Protección Financiera Plan III: Desmembramiento",
        "POL320130695",
        "Sin deducible",
        500,
        0.15,
        0.20
    ),
    createCobertura(
        32,
        5,
        1,
        "UF",
        "RGM: Reembolso de Gastos Médicos por Accidente",
        "Condiciones Particulares",
        "Condiciones Particulares",
        500,
        7,
        0
    ),
    createCobertura(
        33,
        2,
        0,
        "UF",
        "Protección Financiera Plan I: Muerte Accidental",
        "POL320130695",
        "Sin deducible",
        1000,
        0.15,
        0.60
    ),
    createCobertura(
        33,
        3,
        1,
        "UF",
        "Protección Financiera Plan II: Incapacidad Permanente",
        "POL320130695",
        "Sin deducible",
        1000,
        0.1,
        0.20
    ),
    createCobertura(
        33,
        4,
        1,
        "UF",
        "Protección Financiera Plan II: Incapacidad Permanente",
        "POL320130695",
        "Sin deducible",
        1000,
        0.1,
        0.20
    ),
    createCobertura(
        33,
        5,
        1,
        "UF",
        "RGM: Reembolso de Gastos Médicos por Accidente",
        "Condiciones Particulares",
        "Condiciones Particulares",
        1000,
        5,
        0
    ),
];
