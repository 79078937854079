import './RCInsurance.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import ApiInsurance from '@api/insurance/insurance';
import MLoading from '@controls/MLoading';
import LogoRentaNacional from '@img/logo-renta-nacional.png';
import * as RCActions from '@redux/actions/InsuranceActions';
import {
  useAppDispatch,
  useAppSelector,
} from '@redux/hooks';
import { InsurancePlan } from '@redux/models/InsuranceModels';

import RCAdicionalInfo from './Partial/RCAdicionalInfo';
import RCDataAssured from './Partial/RCDataAssured';
import RCSelectPlan from './Partial/RCSelectPlan';

const RCInsurance = () => {
  const dispatch = useAppDispatch();
  const { plans } = useAppSelector((store) => store.insuranceRC);
  const [step, setStep] = useState<number>(1);
  const [idPropuesta, setIdPropuesta] = useState<number>(11);
  const [loading, setLoading] = useState<boolean>(true);
  const [plansToSelect, setPlansToSelect] = useState<InsurancePlan[]>(plans);
  const [addAdicionalBenef, setAddAdicionalBenef] = useState<boolean>(false);
  const [typeBenefAdicional, setTypeBenefAdicional] =
    useState<string>("typeBenef");

  useEffect(() => {
    if (step == 1) {
      // dispatch(RCActions.cleanAssured());
      if (plansToSelect.length > 0) {
        setLoading(false);
      } else {
        ApiInsurance.callPlanesSeguroAll("1").then((resp) => {
          dispatch(RCActions.loadInsurancePlansRC(resp.dataList));
          setPlansToSelect(resp.dataList);
          setLoading(false);
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <div className="anim-loading">
          <div className="lottie-animacion">
            <MLoading />
          </div>
        </div>
      ) : (
        <div className="container RCInsurance">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="section-insurance mt-4">
                <div className="row">
                  <div className="col-lg-9 col-12 ">
                    <p className="youarein">Estás contratando:</p>
                    <h3 className="subtitle-mini mb-2 mb-lg-0">
                      Responsabilidad Civil
                    </h3>
                  </div>
                  <div className="col-lg-3 col-12 text-start text-lg-end">
                    <img
                      src={LogoRentaNacional}
                      alt="Logo Renta Nacional"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-3 mb-lg-0">
              <div className="section-insurance">
                <p className="youarein">Estás en:</p>
                <nav className="stepper">
                  <ul className="stepper__list">
                    <li
                      className={
                        step === 1
                          ? "stepper__list__item stepper__list__item--current"
                          : "stepper__list__item stepper__list__item--pending"
                      }
                    >
                      <span className="stepper__correlative">1</span>
                      <span className="stepper__list__title">Cotizar</span>
                    </li>
                    <li
                      className={
                        step === 2
                          ? "stepper__list__item stepper__list__item--current"
                          : step === 3
                          ? "stepper__list__item  stepper__list__item--pending"
                          : "stepper__list__item stepper__list__item--pending"
                      }
                    >
                      <span className="stepper__correlative">2</span>
                      <span className="stepper__list__title">
                        Información del Asegurado
                      </span>
                    </li>
                    <li
                      className={
                        step !== 3
                          ? "stepper__list__item stepper__list__item--pending"
                          : "stepper__list__item stepper__list__item--current"
                      }
                    >
                      <span className="stepper__correlative">3</span>
                      <span className="stepper__list__title">
                        Ingresa información adicional
                      </span>
                    </li>
                  </ul>
                </nav>
                <div className="info-mobile-steppers">
                  <p className="step__location">
                    Paso <span className="here">1</span> de{" "}
                    <span className="end">3</span>
                  </p>
                  <h5 className="description__step_mobile">Cotizar</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {step === 1 && (
                <RCSelectPlan
                  plans={plansToSelect}
                  setStep={setStep}
                  setIdPropuesta={setIdPropuesta}
                  idPropuesta={idPropuesta}
                />
              )}
              {step === 2 && (
                <RCDataAssured
                  setStep={setStep}
                  addAdicionalBenef={addAdicionalBenef}
                  typeBenefAdicional={typeBenefAdicional}
                  setAddAdicionalBenef={setAddAdicionalBenef}
                  setTypeBenefAdicional={setTypeBenefAdicional}
                />
              )}
              {step === 3 && (
                <RCAdicionalInfo
                  setStep={setStep}
                  idPropuesta={idPropuesta}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RCInsurance;
