import { Product } from './../models/ProductsModels';
import { createAction } from "@reduxjs/toolkit";
import { ProductState } from "../models/ProductsModels";

export const cleanState = createAction("SESSION_CLEAN_STATE");

export const loadProducts = createAction(
  "SESSION_LOAD_PRODUCTS",
  (listProducts: Product[] | undefined) => {
    return {
      payload: listProducts,
    };
  }
);
