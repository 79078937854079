import { InsurancePlan, Coverage } from "@redux/models/InsuranceModels";
import { roundNumber } from "../../../../../scripts/helpers/helpers";
import { coberturas, primasMinimas } from "./Coberturas";
import {
  resumenCotizacionMultiProteccionFinanciera,
  TypePrima,
} from "./QuotePersonalAccidents";

export type TypeQuote = {
  cobertura: TypeCotizacion[];
  prima: TypePrima;
};

export type TypeCotizacion = {
  PorcentajeBase: number;
  afecta: boolean;
  codigo: string;
  deducible: string;
  descripcionCobertura: string;
  idOpcionPropuesta: number;
  idPlanSeguro: number;
  monto: number;
  montoAsegurado: number;
  montoAseguradoLabel: string;
  primaBaseCalculo: number;
  primaFormateada: string;
  primaNeta: number;
  tasa: number;
  tipoMoneda: string;
};

const createCobertura = (
  idOpcionPropuesta: number,
  idPlanSeguro: number,
  afecta: boolean,
  tipoMoneda: string,
  descripcionCobertura: string,
  codigo: string,
  deducible: string,
  monto: number,
  tasa: number,
  PorcentajeBase: number
) => ({
  idOpcionPropuesta,
  idPlanSeguro,
  afecta,
  tipoMoneda,
  descripcionCobertura,
  codigo,
  deducible,
  monto,
  tasa,
  PorcentajeBase,
  montoAsegurado: 0,
  montoAseguradoLabel: 0,
  primaBaseCalculo: 0,
  primaFormateada: 0,
  primaNeta: 0,
});

//Calcula la comision
const calcularCotizacion = (cotizacion: any, numeroEmpleados: number) => {
  let montoAsegurado = cotizacion.monto * numeroEmpleados;
  if (cotizacion.idPlanSeguro === 5) {
    montoAsegurado *= 0.1;
  }
  let prima = ((cotizacion.monto * cotizacion.tasa) / 1000) * numeroEmpleados;
  prima = roundNumber(prima, 2);
  cotizacion.montoAsegurado = montoAsegurado;
  cotizacion.primaBaseCalculo = prima;
  cotizacion.primaNeta = cotizacion.primaBaseCalculo;
  cotizacion.primaFormateada = parseFloat(prima.toString())
    .toFixed(2)
    .replace(".", ",");
  cotizacion.montoAseguradoLabel = `${cotizacion.tipoMoneda} ${montoAsegurado}`;
  return cotizacion;
};

const calcularMinimo = (cotizacion: any, minimoPorPoliza: any) => {
  const prima = cotizacion.PorcentajeBase * minimoPorPoliza;
  cotizacion.primaNeta = prima;
  cotizacion.primaFormateada = parseFloat(roundNumber(prima, 2).toString())
    .toFixed(2)
    .replace(".", ",");
  return cotizacion;
};

export const cotizar = (
  idOpcionPropuesta: number,
  numeroEmpleados: number,
  rgm: boolean,
  coverages: Coverage[]
) => {

  const coberturas = coverages?.map((x) =>
    createCobertura(
      x.idOpcionPropuesta,
      x.idPlanSeguro,
      x.afecto,
      "UF",
      x.descripcionCobertura,
      x.codigo,
      x.deducible,
      x.montoAseguradoUf,
      x.tasa,
      x.porcentajeBase
    )
  );

  const { porPoliza } = primasMinimas;

  let coberturasSelecionadas = coberturas?.filter(
    (x) => x.idOpcionPropuesta == idOpcionPropuesta
  );

  if (!rgm)
    coberturasSelecionadas = coberturasSelecionadas?.filter(
      (x) => x.idPlanSeguro !== 5
    );

  let cotizaciones: TypeCotizacion[] = coberturasSelecionadas?.map((x) => {
    return calcularCotizacion(x, Number(numeroEmpleados));
  });

  let sumaPrimaPrevMin: number = 0;

  cotizaciones?.forEach((x) => {
    sumaPrimaPrevMin += x.primaBaseCalculo;
  });
  const esMenor: boolean = sumaPrimaPrevMin < porPoliza;
  if (esMenor) {
    cotizaciones = cotizaciones?.map((x) => {
      return calcularMinimo(x, porPoliza);
    });
  }

  let sumaAfecto: number = 0;
  let sumaExento: number = 0;

  cotizaciones?.forEach((x) => {
    if (x.afecta) {
      sumaAfecto += esMenor ? x.primaNeta : roundNumber(x.primaNeta, 2);
    } else {
      sumaExento += esMenor ? x.primaNeta : roundNumber(x.primaNeta, 2);
    }
  });

  const CotizacionProteccionFinanciera = {
    cobertura: cotizaciones,
    prima: resumenCotizacionMultiProteccionFinanciera(sumaAfecto, sumaExento),
  };


  return CotizacionProteccionFinanciera;
};
