import { InjectTokenHeader, MAxios } from "../conf";

const GetActividadesProveedores = async () => {
  const endpoint = "ActividadProveedor/GetActividadesProveedores";

  const res = await MAxios().get(endpoint);

  return res.data;
};

const GetActividades = async () => {
  const endpoint = "seguro/activity/GetActivities";

  const res = await MAxios().post(endpoint);

  return res.data;
};

const ApiActividadProveedor = {
  GetActividadesProveedores,
  GetActividades,
};

export default ApiActividadProveedor;
