import "../RCInsurance.scss";

import React, { useEffect, useState } from "react";

import ApiInsurance from "@api/insurance/insurance";
import { convertToUf } from "@helpers/helpers";
import {
  MButton,
  MPopup,
} from "@inversiones-ma/finfast-react-controls";
import { useAppSelector } from "@redux/hooks";
import { Coverage } from "@redux/models/InsuranceModels";
import { DetailCoverage } from "./DetailCoverage";
import { RCDesktopDetailCoverage } from "./RCDesktopDetailCoverage";
import { RCButtonDetailCoverage } from "./RCButtonDetailCoverage";

interface Props {
  idPropuesta: number;
  setShowModal: any;
  showModal: boolean;
  setIdPropuestaSelect: any;
  fromCart: boolean;
  primaNeta?: number | undefined;
  primaBruta?: number | undefined;
}

const RCDetailCoverage = (props: Props) => {
  const [detailCoverages, setDetailCoverages] = useState<Coverage[]>([]);
  const { plans } = useAppSelector((store) => store.insuranceRC);

  const planSelected = plans.find((a) => a.idPropuesta === props.idPropuesta);

  const valuePlanPrima = planSelected ? planSelected?.montoPrima : 0;
  const valueIva = planSelected ? planSelected.iva : 0;
  const valuePlan = valuePlanPrima + (valueIva ?? 0);

  const primaNeta = props.primaNeta ?? valuePlan;

  useEffect(() => {
    const fechaActual = new Date();
    ApiInsurance.callSeguroRCInsuranceCoverageGetInfoCoverage(
      fechaActual.getFullYear().toString(),
      "1",
      "1",
      props.idPropuesta.toString(),
      primaNeta.toFixed(2)
    ).then((resp) => {
      setDetailCoverages(resp.dataList);
    });
  }, [props.idPropuesta]);

  const selecionarPlan = () => {
    props.setIdPropuestaSelect(props.idPropuesta);
    props.setShowModal(false);
  };

  return (
    <MPopup
      setShowModal={props.setShowModal}
      showModal={props.showModal}
      showClose={true}
      size="xl"
    >
      <div className="row mx-auto justify-content-center RCInsurance">
        <div className="col-12">
          <h4 className="titles-center my-3">detalle de coberturas</h4>
          <h3 className="text-center pt-3 fs-5 fw-bold color-secondary">
            Responsabilidad Civil
          </h3>
          <p className="text-center fs-6 color-secondary">
            {/* Plan {props.idPropuesta} */}
            Plan {planSelected?.idPlan}
          </p>
          <p className="text-center fs-6 color-primary">
            <span className="fs-4 fw-bold">
              UF {convertToUf(props.primaBruta ?? valuePlan)} anual
            </span>{" "}
          </p>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="d-block d-sm-none">
                    {detailCoverages?.map((item: Coverage) => (
                      <DetailCoverage item={item} />
                    ))}
                  </div>
                  {/* inicio desktop fin mobil */}
                  <RCDesktopDetailCoverage detailCoverages={detailCoverages} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.fromCart ? (
        <RCButtonDetailCoverage
          selecionarPlan={selecionarPlan}
          setShowModal={props.setShowModal}
        />
      ) : (
        <div className="row justify-content-center">
          <div className="col-12 col-md-3">
            <MButton
              type="button"
              className="w-100"
              onClick={() => props.setShowModal(false)}
            >
              Cerrar
            </MButton>
          </div>
        </div>
      )}
    </MPopup>
  );
};

export default RCDetailCoverage;
