import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { cleanStateCart } from "@redux/actions/SessionActions";
import carritoNew from "@img/carritoNew.svg";
import { MAlert, MButton } from "@inversiones-ma/finfast-react-controls";
import { useHistory } from "react-router-dom";
import "../HomeCart.scss"
interface MatchParams {
  plural: boolean;
}
const CartStep2 = (x: MatchParams) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { assured } = useAppSelector((store) => store.insuranceRC);
  const { assured: assuredPA } = useAppSelector(store => store.insurancePA)

  function handlerToHome() {
    history.push("/home");
  }

  return (
    <>
      <div className="col-12 col-lg-10 offset-lg-1 bg-white p-4 homeCart">
        <h2 className="titles-center fs-24">¡gracias por tu compra!</h2>
        <div className="row">
          <div className="col-12 col-lg-4 ">
            <img src={carritoNew} alt="" className="m-auto d-block" />
          </div>
          <div className="col-12 col-lg-8 m-auto px-4">
            {!x.plural ? (
              <h3 className="color-secondary fs-18">
                Te enviaremos tu compra al correo <br />
                <span>
                  <b>
                    {assuredPA && assuredPA.mailBeneficiarioAdicional} {assured && assured.mailAsegurado}
                  </b>
                </span>{" "}
                apenas esté disponible.
              </h3>
            ) : (
              <h3 className="color-secondary fs-18">
                Tus pólizas serán enviadas al{" "}
                <b>correo registrado en tu formulario de compra </b>,
                <br />
                te avisaremos apenas estén disponibles.
              </h3>
            )}

            <p className="fs-14 color-text">
              <strong>Una vez disponibles</strong>, podrás descargar{" "}
              {!x.plural ? "tu póliza" : "tus pólizas"} desde el apartado del
              menú descargar póliza, <br /> ingresando{" "}
              <strong>tu Rut y el Nº de póliza.</strong>
            </p>
            <MAlert color="info">
              <p style={{ marginBottom: 0 + "px" } as React.CSSProperties}>
                <b className="ingresa-alert02">Recuerda &nbsp;</b>
                <br />
                <span style={{ fontSize: 14 + "px" } as React.CSSProperties}>
                  La generación de tu póliza podría demorarse hasta un
                  <b> máximo de 48 horas</b>
                </span>
              </p>
            </MAlert>
            <div className="col-12 col-md-6">
              <MButton className="w-100 p-2 " onClick={handlerToHome}>
                Volver al home
              </MButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartStep2;