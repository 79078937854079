import { Insurance } from "@redux/models/InsuranceModels";
import { MAxios, InjectTokenHeader } from "../conf";

export const PayTbk = async (insurance: Insurance) => {
  const endpoint = "seguro/Transbank/Pay";

  const res = await MAxios().post(endpoint, insurance);

  return res.data;
};

const ApiTbk = {
  PayTbk,
};

export default ApiTbk;
