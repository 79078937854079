import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./navbar-container.css";
import finfastLogo from "@img/finfast-logo.svg";
import FinfastProductSelector from "../../../pages/Private/FinfastProductSelector/FinfastProductSelector";
import MenuPrincipal from "./MenuPrincipal";

const MenuPartialSelector = () => {
  const [collapseMenu, setcollapseMenu] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line
    HandlerNavbarCollapseTrue();
  }, [collapseMenu]);

  function HandlerNavbarCollapse() {
    setcollapseMenu(false);
  }
  function HandlerNavbarCollapseTrue() {
    setcollapseMenu(true);
  }

  function goMultiProduct() {
    window.open("https://www.finfast.com/home", "_self");
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link
          className="navbar-brand"
          onClick={goMultiProduct}
          to="https://www.finfast.com/home"
        >
          <img
            src={finfastLogo}
            alt="Logo Finfast Servicios Financieros"
            className="logo-header"
          ></img>
        </Link>

        <FinfastProductSelector defaultName="SEGUROS" idProduct={2} />

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={
            !!collapseMenu
              ? "navbar-collapse collapse"
              : "navbar-collapse collapse show"
          }
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav navbar-right">
            <hr className="my-0"></hr>
            <MenuPrincipal
              HandlerNavbarCollapse={HandlerNavbarCollapse}
            ></MenuPrincipal>
            <hr className="my-0"></hr>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MenuPartialSelector;
