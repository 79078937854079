// Filtra los seguros RC, PF & TRC
export class FilterInsurances {

    static filter(id: number) {
        switch (id) {
            case 1:
                return true;
            case 2:
                return true;
            case 3:
                return true;
            default:
                return false;
        }
    }

}