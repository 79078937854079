import "../RCInsurance.scss";

import React, { SetStateAction, useEffect, useState } from "react";

import { upperCase } from "lodash";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import BaseModel from "@api/BaseModel";
import GenericBaseModel from "@api/GenericBaseModel";
import ApiInsurance from "@api/insurance/insurance";
import { toDate2 } from "@helpers/helpers";
import {
  MButton,
  MGrid,
  MGridColumn,
  MInputCheckBox,
  MInputDate,
} from "@inversiones-ma/finfast-react-controls";
import { loadCart } from "@redux/actions/SessionActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  Insurance,
  InsurancePlan,
  InsuranceQuote,
  InsuranceRCAddToCart,
  InsuranceRCModel,
} from "@redux/models/InsuranceModels";
import { Activity } from "@redux/models/SessionModels";

import { handleResponseRcAddToCart, handleSendRcAddToCart } from "../../Cart";
import RCTermsAndConditions from "./RCTermsAndConditions";

interface Props {
  idPropuesta: number;
  setStep: React.Dispatch<SetStateAction<number>>;
}
const RCAdicionalInfo = (props: Props) => {
  const { activities, activitiesRC } = useAppSelector((store) => store.session);
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);
  const [checkActivity, setCheckActivity] = useState<Activity[]>(activitiesRC);
  const [isCheckAllActivity, setIsCheckAllActivity] = useState(false);
  const [modalTermsAndCond, setModalTermsAndCond] = useState<boolean>(false);
  const [aceptTermsAndCond, setAceptTermsAndCond] = useState<boolean>(false);
  const { assured, plans } = useAppSelector((store) => store.insuranceRC);
  const { cart } = useAppSelector((store) => store.session);
  const dispatch = useAppDispatch();
  const [disableInputActivity, setDisableInputActivity] =
    useState<boolean>(false);

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const sumarDias = (fecha: Date, dias: number) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  };
  const sumarAnios = (fecha: Date, dias: number) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  };

  const backStep = () => {
    props.setStep(2);
  };
  const checkAceptTermAndCond = (change: boolean) => {
    setAceptTermsAndCond(change);
  };
  const history = useHistory();

  const dateInicio = new Date();
  const dateMax = sumarDias(new Date(), 30);
  const dateMaxTerm = sumarAnios(new Date(), 731);
  const [activitiesSelectedCount, setActivitiesSelectedCount] =
    useState<number>(0);
  const { watch, getValues, setValue } = form;
  const { fechaInicio, fechaTermino } = watch();
  const { handleSubmit } = form;

  const onSubmit = async () => {
    setIsAddingToCart(true);

    let insuranceRCAddToCart: InsuranceRCAddToCart | undefined;
    insuranceRCAddToCart = handleSendRcAddToCart(
      assured,
      checkActivity,
      plans,
      props.idPropuesta,
      fechaInicio,
      fechaTermino
    );

    const planSelected = plans.find(
      (a: InsurancePlan) => a.idPropuesta === props.idPropuesta
    );

    if (insuranceRCAddToCart) {
      let quoteRC: InsuranceQuote = {
        correo: assured.mailAsegurado,
        idOpcion: props.idPropuesta,
        IdTipoSeguro: 1,
        nombre: assured.razonSocialAsegurado,
        rut: assured.rutAsegurado,
        numEmpleados: 0,
        seguroOpc: false,
      };

      await ApiInsurance.SendQuote(quoteRC)
        .then((resp: GenericBaseModel<BaseModel>) => {
          if (resp.success) {
            ApiInsurance.SaveInsuranceRC(insuranceRCAddToCart!)
              .then((resp: GenericBaseModel<InsuranceRCModel>) => {
                if (resp !== null && resp.success) {
                  let newInsurance: Insurance | null;
                  newInsurance = handleResponseRcAddToCart(
                    resp.data,
                    cart?.item
                  );

                  if (newInsurance !== null) {
                    dispatch(loadCart({ item: newInsurance }));
                    console.log(cart.item);
                  }
                  setIsAddingToCart(false);
                  history.push("/homecart/step1");
                } else {
                  setIsAddingToCart(false);
                }
              })
              .catch((err) => {
                console.error("Ha ocurrido un error:" + err.message);
                setIsAddingToCart(false);
              });
          }
        })
        .catch((err) => {
          console.error("Ha ocurrido un error:" + err.message);
          setIsAddingToCart(false);
        });
    }
  };

  const handleOnchangeActividad = (event: any, value: number) => {
    if (event !== "") {
      const isChecked = checkActivity.some((ac) => ac.checked === true);

      const updatedActivity = checkActivity.map((ac) =>
        ac.code === value ? { ...ac, checked: !isChecked } : ac
      );
      setCheckActivity(updatedActivity);
    }
  };

  const handleOnchangeActividadAll = (event: any) => {
    if (event !== "") {
      setIsCheckAllActivity(!isCheckAllActivity);
      setCheckActivity(
        checkActivity.map((ac) => {
          return { ...ac, checked: !isCheckAllActivity };
        })
      );
    }
  };

  useEffect(() => {
    setActivitiesSelectedCount(checkActivity.filter((a) => a.checked).length);
  }, [checkActivity, setCheckActivity]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="section-insurance">
        <h2 className="titles-center fs-24">ingresa información adicional</h2>
        <p className="text-center pb-4">
          Indica la fecha de inicio y las actividades a asegurar
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="subtitle-mini">Ingresa fecha de inicio de vigencia</h3>
          <div className="col-12">
            <div
              className="alert alert-info d-flex align-items-start"
              role="alert"
            >
              <div className="icon">
                <i className="fas fa-info-circle" aria-hidden="true"></i>
              </div>
              <div>
                La fecha inicio de la vigencia debe estar dentro de los{" "}
                <strong>próximos 30 días</strong>.
              </div>
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-lg-6">
              <div className="floating-label">
                <MInputDate
                  name="fechaInicio"
                  form={form}
                  min={dateInicio}
                  max={dateMax}
                  label={"Fecha de inicio"}
                  className="floating-input default"
                  rules={{
                    required: "Este campo es requerido",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <MInputDate
                name="fechaTermino"
                form={form}
                min={dateInicio}
                max={dateMaxTerm}
                label={"Fecha de finalización"}
                className="floating-input default"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </div>
          <div className="dashed-separator my-3"></div>
          <div className="row">
            <div className="col-12">
              <h3 className="subtitle-mini my-3">
                Selecciona la actividad a asegurar
              </h3>
              <p className="fs-16 color-text text-start">
                Busca y selecciona la actividad que deseas asegurar.
              </p>

              <div className="tbodyDiv">
                {checkActivity && checkActivity.length > 0 && (
                  <MGrid showMoreSize={10} dataSource={checkActivity}>
                    <MGridColumn
                      dataField="description"
                      label="Nombre Actividad"
                      headerClassName="label-white"
                      cellRender={(_k: any, value: any, row: any) => {
                        return <> {upperCase(value)} </>;
                      }}
                    ></MGridColumn>
                    <MGridColumn
                      dataField="code"
                      label="Seleccionar"
                      className="col text-center"
                      headerClassName="center-header label-white"
                      cellRender={(_k: any, value: number, row: Activity) => {
                        return (
                          <>
                            {" "}
                            {
                              <MInputCheckBox
                                name={value.toString()}
                                checked={row.checked}
                                disabled={disableInputActivity}
                                form={form}
                                value={value}
                                className="text-center"
                                centered
                                onClick={(event) =>
                                  handleOnchangeActividad(event, value)
                                }
                              />
                            }{" "}
                          </>
                        );
                      }}
                    ></MGridColumn>
                  </MGrid>
                )}
              </div>
              {/* <div className="col-12 my-2">
                <p className="fs-16 color-text text-center ">
                  Total de actividades seleccionadas:{" "}
                  <span className="color-primary fs-16 fw-bold">
                    {activitiesSelectedCount}
                  </span>
                </p>
              </div> */}
            </div>
          </div>
          <div className="py-4 justify-content-center text-center">
            <div className="form-check">
              <MInputCheckBox
                className="form-check-inputs"
                onClick={() => checkAceptTermAndCond(!aceptTermsAndCond)}
                form={form}
                name="checkTermAndCond"
                checked={aceptTermsAndCond}
                onClickLabel={() => setModalTermsAndCond(true)}
                labelHtmlFor={false}
                label={
                  <a className="text-link fs-14 color-secondary mb-3">
                    Acepto los Términos y Condiciones, verifico la autenticidad,
                    exactitud, vigencia y veracidad de los datos ingresados.
                  </a>
                }
              />
            </div>
          </div>
          <hr />
          <div className="row pb-2">
            <div className="col-lg-4 col-md-4 order-3 order-md-1">
              <MButton
                type="button"
                className="btn btn-primary sistema outline w-100"
                onClick={() => backStep()}
              >
                <i className="fa fa-arrow-left"></i> Volver
              </MButton>
            </div>
            <div className="col-lg-4 col-md-4 order-2"></div>
            <div className="col-lg-4 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
              <MButton
                type="submit"
                className="btn btn-primary sistema w-100"
                loading={isAddingToCart}
                disabled={!aceptTermsAndCond || activitiesSelectedCount === 0}
              >
                Agregar al carro
                <i className="fas fa-shopping-cart" aria-hidden="true"></i>
              </MButton>
            </div>
          </div>
        </form>
      </div>

      <RCTermsAndConditions
        setShowModal={setModalTermsAndCond}
        showModal={modalTermsAndCond}
        checkAceptTermAndCondModal={checkAceptTermAndCond}
      />
    </>
  );
};

export default RCAdicionalInfo;
