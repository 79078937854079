import React from "react";
import Lottie from "react-lottie";
import jsonLoading from "../../img/animated/loading.json";
import "./MLoading.css";

function MLoading(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: jsonLoading,
        rendererSettings: {
            preserveAspectRation: "xMidYMid slice",
        },
    };
    return (
        <div
            className={
                (!props.modal && props.modal === false
                    ? ""
                    : "m-loading-container") +
                (!!props.transparent ? " transparent" : "")
            }
            style={props.style}
        >
            <div className="m-loading-content">
                <div className="m-loading-image-container">
                    <Lottie
                        options={defaultOptions}
                        height={props.height ?? 200}
                        width={props.width ?? 200}
                        isStopped={false}
                        isPaused={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default MLoading;
