import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  MButton,
  MInputNumber,
  MInputRadioButtons,
  MInputSelect,
} from "@inversiones-ma/finfast-react-controls";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { changeStep } from "@redux/actions/GlobalActions";
import { loadAssuredPA, loadSurePrime } from "@redux/actions/InsuranceActions";
import { AssuredPA, Prima } from "@redux/models/InsuranceModels";
import { InsuranceQuote } from "./InsuranceQuote";
import { cotizar, TypeQuote } from "../Functions/QuoterPersonalAccidents";
import SeguroMontoAsegurar from "../../../../../img/seguro-monto-asegurar.svg";
import SeguroCantidadTrabajdores from "../../../../../img/seguros-cantidad-trabajadores.svg";
import { useHistory } from "react-router-dom";
import { StepState } from "@redux/models/StepModels";
import { loadStepData } from "@redux/actions/StepActions";

type StepOneProps = {
  setEmployees: Function;
  setIdPlan: Function;
  setMedicalExpenses: Function;
};

export const StepOne = ({
  setEmployees,
  setIdPlan,
  setMedicalExpenses,
}: StepOneProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { coverages } = useAppSelector((store) => store.coverages);
  const { plans, assured } = useAppSelector((store) => store.insurancePA);
  const [quote, setQuote] = useState<TypeQuote>();

  const lowest = plans?.reduce((prev, cur) => (cur.idPropuesta < prev.idPropuesta ? cur : prev))
  let values = {...assured, idPlanPropuesta: lowest.idPropuesta }

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: values,
  });

  const { handleSubmit, watch } = form;
  const { idPlanPropuesta, cantidadTrabajadores, rgm } = watch();

  const itemPlans = plans.map((p) => {
    return {
      id: p.idPropuesta,
      descripcion: `${p.montoAseguradoGeneral} UF`,
    };
  });

  // Renderizar cuando cambian los datos de la cotización
  useEffect(() => {
    let opcionPropuesta = idPlanPropuesta;
    let gastosMedicos: boolean = !!!rgm
      ? false
      : rgm.toString().toLowerCase() === "true";

    const cotizacion = cotizar(
      opcionPropuesta,
      cantidadTrabajadores,
      gastosMedicos,
      coverages
    );

    let surePrime: Prima = {
      iva: cotizacion.prima.clean.iva,
      primaAfecta: cotizacion.prima.clean.primaAfecta,
      primaBruta: cotizacion.prima.clean.primaBruta,
      primaExenta: cotizacion.prima.clean.primaExenta,
      primaNeta: cotizacion.prima.clean.primaNeta,
    };
    dispatch(loadSurePrime(surePrime));
    setQuote(cotizacion);
  }, [coverages, idPlanPropuesta, cantidadTrabajadores, rgm]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data: any) => {
    setEmployees(data.cantidadTrabajadores);
    setIdPlan(Number(data.idPlanPropuesta));
    setMedicalExpenses(data.rgm === "true");

    let newData: AssuredPA = {
      ...assured,
      idPlanPropuesta: data.idPlanPropuesta,
      cantidadTrabajadores: data.cantidadTrabajadores,
      rgm: data.rgm,
    };

    dispatch(loadAssuredPA(newData));
    dispatch(changeStep(2));
  };

  const handleOnClickBack = () => {
    dispatch(changeStep(1));
    history.push("/home");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="section-insurance">
        <h2 className="titles-center fs-24">Cotiza tu seguro</h2>
        <h3 className="subtitle-mini">Ingresa los datos para cotizar</h3>
        {/* Parte #1 */}
        <div className="row pb-3">
          <div className="col-12 col-lg-6">
            <div
              className="cont-card-cotizador-sg bg-white card-product-seguros text-center my-3 d-flex flex-column justify-content-between"
              style={{ minHeight: "330px" }}
            >
              <div className="col-12 cont-cotizador-montos py-3 px-2">
                <img
                  src={SeguroMontoAsegurar}
                  alt="Seguro de accidentes personales"
                />
                <h3 className="fs-16 color-secondary fw-bold">
                  Selecciona el monto a asegurar por trabajador
                </h3>
              </div>
              <div className="col-12 bg-light p-3">
                <div className="floating-label">
                  <MInputSelect
                    form={form}
                    name="idPlanPropuesta"
                    selectedDefaultValue={false}
                    items={itemPlans}
                    label="Monto a asegurar"
                    key="id"
                    rules={{
                      required: "Este campo es requerido",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div
              className="cont-card-cotizador-sg bg-white card-product-seguros text-center my-3 d-flex flex-column justify-content-between"
              style={{ minHeight: "330px" }}
            >
              <div className="col-12 cont-cotizador-montos py-3 px-2">
                <img
                  src={SeguroCantidadTrabajdores}
                  alt="Seguro de accidentes personales"
                />
                <h3 className="fs-16 color-secondary fw-bold">
                  Indique la cantidad de trabajadores a asegurar
                </h3>
                <p>(máximo 100 trabajadores)</p>
              </div>
              <div className="col-12 bg-light p-3">
                <div className="floating-label">
                  <MInputNumber
                    form={form}
                    maxLength={3}
                    name="cantidadTrabajadores"
                    className="floating-input default"
                    label={"Cantidad de trabajadores"}
                    rules={{
                      required: "Este campo es requerido",
                      max: { value: 100, message: "Máximo 100 trabajadores" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Parte #2*/}
        <div className="row">
          <div className="dashed-separator my-3"></div>
          <div className="col-12">
            <h4 className="color-text fs-16 text-center fw-bold">
              ¿Deseas incluir Reembolso de Gastos Médicos por Accidente?
            </h4>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <MInputRadioButtons
                form={form}
                name={"rgm"}
                items={[
                  { label: "Sí", value: true },
                  { label: "No", value: false },
                ]}
                mode={"flex"}
                direction={"row"}
                justify={"center"}
                key={"value"}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </div>
          <div className="dashed-separator my-3"></div>
        </div>
        {/* Parte #3 */}
        <InsuranceQuote quote={quote} />
        {/* Parte #4 */}
        <div className="row justify-content-lg-between pb-2 mt-4">
          <div className="col-12 order-2 order-lg-1 col-lg-3 mb-3">
            <MButton
              className="btn btn-primary sistema outline w-100"
              type="button"
              onClick={handleOnClickBack}
            >
              <i className="mx-2 fa fa-arrow-left"></i>
              Volver
            </MButton>
          </div>
          <div className="col-12 order-1 order-lg-2 col-lg-3 mb-3">
            <MButton className="btn btn-primary sistema w-100" type="submit">
              Continuar
              <i className="mx-2 fa fa-arrow-right"></i>
            </MButton>
          </div>
        </div>
      </div>
    </form>
  );
};
