import {
  MAxios,
  InjectTokenHeader,
  InjectTokenHeaderDownload,
  MAxiosBlob,
} from "../conf";

export const DownloadPolicy = async (insurance: policy) => {
  const endpoint = "Seguro/Policy/DownloadPolicy";
  const formData = new FormData();
  formData.append("Rut", insurance.Rut);
  formData.append("item", insurance.item);

  const res = await MAxiosBlob().post(endpoint, formData);
  return res.data;
};

export const SearchPolicy = async (dataPolicy: policy) => {
  const endpoint = "Seguro/Policy/GetDownloadPolicyData";
  const formData = new FormData();
  formData.append("Rut", dataPolicy.Rut);
  formData.append("item", dataPolicy.item);

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

const ApiPolicy = {
  DownloadPolicy,
  SearchPolicy,
};

export default ApiPolicy;

interface policy {
  Rut: string;
  item: string;
}
