import { useEffect, useState } from "react";
import ApiInsurance from "@api/insurance/insurance";
import { useAppSelector } from "@redux/hooks";
import { Coverage } from "@redux/models/InsuranceModels";
import {
  MButton,
  MGrid,
  MGridColumn,
  MPopup,
} from "@inversiones-ma/finfast-react-controls";
import { convertToPeso, convertToUf } from "@helpers/helpers";

type CoverageDetailProps = {
  idPropuesta: number;
  setShowModal: any;
  showModal: boolean;
  setIdPropuestaSelect: any;
  fromCart: boolean;
};

export const CoverageDetail = ({
  showModal,
  setShowModal,
  idPropuesta,
  fromCart,
  setIdPropuestaSelect,
}: CoverageDetailProps) => {
  const [detailCoverages, setDetailCoverages] = useState<Coverage[]>([]);
  const { plans } = useAppSelector((store) => store.insurancePA);

  const planSelected = plans.find((a) => a.idPropuesta === idPropuesta);
  const valuePlanPrima = planSelected ? planSelected?.montoPrima : 0;
  const valueIva = planSelected ? planSelected.iva : 0;
  const valuePlan = valuePlanPrima + (valueIva ?? 0);

  useEffect(() => {
    ApiInsurance.callSeguroRCInsuranceCoverageGetInfoCoverage(
      new Date().getFullYear().toString(),
      "1",
      "2",
      idPropuesta.toString()
    ).then((res) => {
      setDetailCoverages(res.dataList);
    });
  }, [idPropuesta]);

  const selecionarPlan = () => {
    setIdPropuestaSelect(idPropuesta);
    setShowModal(false);
  };

  return (
    <MPopup
      showModal={showModal}
      showClose={true}
      setShowModal={setShowModal}
      size="xl"
    >
      <div className="row mx-auto justify-content-center RCInsurance">
        <div className="col-12">
          <h4 className="titles-center my-3">detalle de coberturas</h4>
          <h3 className="text-center pt-3 fs-5 fw-bold color-secondary">
            Accidentes Personales
          </h3>
          <p className="text-center fs-6 color-secondary">Plan {idPropuesta}</p>
          <p className="text-center fs-6 color-primary">
            <span className="fs-4 fw-bold">
              UF {convertToUf(valuePlan)} anual
            </span>{" "}
            + IVA
          </p>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="d-block d-sm-none">
                    {detailCoverages?.map((item: Coverage) => (
                      <>
                        <div className="detalle-coberturas my-3">
                          <div className="row bg-grey p-2">
                            <div className="col-6 ">
                              <h4 className=" fs-16 color-primary fw-bold p-0 m-0">
                                Cobertura
                              </h4>
                            </div>
                            <div className="col-6 ">
                              <p className="fs-14 color-text p-0 m-0">
                                {item.descripcionCobertura}
                              </p>
                            </div>
                          </div>
                          <div className="row p-2">
                            <div className="col-6 ">
                              <h4 className=" fs-16 color-secondary fw-bold p-0 m-0">
                                Asegurado
                              </h4>
                            </div>
                            <div className="col-6 ">
                              <p className="fs-14 color-text p-0 m-0">
                                {item.montoAsegurado}
                              </p>
                            </div>
                          </div>
                          <hr className="m-0" />
                          <div className="row  p-2">
                            <div className="col-6 ">
                              <h4 className=" fs-16 color-gris87 fw-bold p-0 m-0">
                                Prima Neta
                              </h4>
                            </div>
                            <div className="col-6">
                              <p className="fs-14 color-gris87 p-0 m-0">
                                {item.primaNeta}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  {/* inicio desktop fin mobil */}
                  <div className="d-none d-sm-block">
                    <MGrid
                      className="table table-coberturas-seguro"
                      dataSource={detailCoverages}
                      key="code"
                    >
                      <MGridColumn
                        dataField="descripcionCobertura"
                        label=" "
                        className="bg-grey fs-14 color-text"
                        cellRender={(_k: any, value: any, row: any) => {
                          return <> {value} </>;
                        }}
                      ></MGridColumn>
                      <MGridColumn
                        dataField="montoAsegurado"
                        label={
                          <h4 className=" fs-16 color-secondary fw-bold">
                            Asegurado
                          </h4>
                        }
                        className="fs-16 color-secondary fw-bold"
                        cellRender={(_k: any, value: any, row: any) => {
                          return <> {value} </>;
                        }}
                      ></MGridColumn>
                      <MGridColumn
                        dataField="primaNeta"
                        label={
                          <h4 className=" fs-16 color-gris57 fw-bold ">
                            Prima neta
                          </h4>
                        }
                        className="fs-16 color-gris57 fw-bold"
                        headerClassName="ancho-prima-neta"
                        cellRender={(_k: any, value: any, row: any) => {
                          return <> {value} </>;
                        }}
                      ></MGridColumn>
                    </MGrid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-3">
            <MButton
              type="button"
              className="w-100"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </MButton>
          </div>
        </div>
      </div>
      {!fromCart && (
        <div className="row justify-content-center py-3">
          <div className="col-12 col-lg-3 pt-3 order-2 order-md-1">
            <MButton
              type="button"
              className="btn btn-primary outline sistema w-100"
              data-bs-toggle="collapse"
              data-bs-target="#advance-results"
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </MButton>
          </div>
          <div className="col-12 col-lg-3 pt-3 order-1 order-md-2">
            <MButton
              type="button"
              className="btn btn-primary sistema w-100"
              data-bs-toggle="collapse"
              data-bs-target="#advance-results"
              onClick={() => selecionarPlan()}
            >
              Seleccionar plan
            </MButton>
          </div>
        </div>
      )}
    </MPopup>
  );
};
