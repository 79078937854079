import LogoRenta from "@img/logo-renta-nacional.png";

export const HeaderContent = () => {
  return (
    <>
      <div className="row p-2">
        <div className="col-12 pb-2  mt-5">
          <h2 className="titles-left fs-32">
            Escoge el seguro que estás buscando
          </h2>
        </div>
      </div>
      <div className="row p-2">
        <div className="container pb-3">
          <div className="row">
            <div className="col-12">
              <div className="section-insurance">
                <div className="row">
                  <div className="col-lg-9 col-12 my-auto">
                    <p className="youarein mb-0">
                      Todos los seguros son contratados con:
                    </p>
                  </div>
                  <div className="col-lg-3 col-12 text-start text-lg-end">
                    <img
                      src={LogoRenta}
                      alt="Logo Renta Nacional"
                      className="img-fluid mt-2 mt-lg-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
