import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { MStepper } from "@inversiones-ma/finfast-react-controls";
import { arrStep } from "../../../../scripts/js/menu";
import CartStep1 from "../HomeCart/partial/CartStep1";
import { useRouteMatch } from "react-router-dom";
import { StepPay, StepState } from "@redux/models/StepModels";
import { loadStepData } from "@redux/actions/StepActions";
import { cleanStateCart } from "@redux/actions/SessionActions";
import PaymentError from "./partial/PaymentError";
import './HomeCart.scss'
import PaymentSuccess from "./partial/PaymentSuccess";

interface MatchParams {
  children: string;
}
const HomeCart = (x: MatchParams) => {
  const match = useRouteMatch<MatchParams>();
  //const { children } = match.params;
  const dispatch = useAppDispatch();
  const { stepState } = useAppSelector((store) => store.steps);
  const { cart } = useAppSelector((store) => store.session);
  const [plural, setPlural] = useState<boolean>(false);

  function handlerDeleteCart() {
    dispatch(cleanStateCart());
  };

  useEffect(() => {
    if (match) {
      const { children } = match.params;

      if (!!children && children.toLowerCase() === "step3") {
        if (cart.item.listInsuranceRC.length > 1 || cart.item.listInsurancePF.length > 1 || cart.item.listInsuranceTRC.length > 1) {
          setPlural(true)
        }
        //vacia carro
        handlerDeleteCart();
        const steps: StepState = {
          step: 2,
          subStep: StepPay.successPay,
        };
        dispatch(loadStepData(steps));


      } else if (!!children && children.toLowerCase() === "segurospaymenterror") {
        const steps: StepState = {
          step: 2,
          subStep: StepPay.errorPay,
        };
        dispatch(loadStepData(steps));
      } else {
        const steps: StepState = {
          step: 1,
          subStep: undefined,
        };
        dispatch(loadStepData(steps));
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <main className="bg-home homeCart">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav
                style={
                  { "--bs-breadcrumb-divider": "'>'" } as React.CSSProperties
                }
                aria-label="breadcrumb"
                className="mt-3"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <span className="migas01">Seguros</span>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <span className="migas02"> Carrito </span>
                  </li>
                </ol>

              </nav>
            </div>
            <div className="col-12">
              <h2 className="titles-center">Tu carro de compras</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <MStepper
                steps={arrStep}
                currentStep={stepState.step}
              ></MStepper>
            </div>
          </div>
          {stepState.step === 1 && <CartStep1 />}
          {stepState.step === 2 && stepState?.subStep === StepPay.successPay && (<PaymentSuccess plural={plural} />)}
          {stepState?.step === 2 && stepState?.subStep === StepPay.errorPay && (<PaymentError />)}

        </div>
      </main>
    </>
  );
};

export default HomeCart;
