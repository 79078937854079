import SeguroEmpleado from "@img/seguro-empleados.svg";
import SeguroResponsabilidadCivilConstruccion from "@img/seguro-responsabilidad-civil-construcción.svg";
import SeguroResponsabilidadCivil from "@img/seguro-responsabilidad-civil.svg";
import SeguroTodoRiesgoConstruccionRC from "@img/seguro-todo-riesgo-construcción-mas-rc.svg";
import SeguroTodoRiesgoConstruccion from "@img/seguro-todo-riesgo-construcción.svg";

import { TypeInsurance } from "@redux/models/TypeInsuranceModels";
import { ImageInsurance } from "../utils/ImageInsurance";

type TRenderImgInsuranceProps = {
  insurance: TypeInsurance;
  blockIfConstruction: boolean;
};

const insuranceImages: ImageInsurance = {
  1: SeguroResponsabilidadCivil,
  31: SeguroEmpleado,
  7: SeguroEmpleado,
  35: SeguroResponsabilidadCivilConstruccion,
  38: SeguroTodoRiesgoConstruccion,
  40: SeguroTodoRiesgoConstruccionRC,
};

export const RenderImgInsurance = ({
  insurance,
  blockIfConstruction,
}: TRenderImgInsuranceProps) => {
  const selectedImage =
    insuranceImages[insurance.idOpcionPropuesta] || "";
  return (
    <img
      src={selectedImage}
      alt={insurance.alt}
      className={`text-center ${
        (!insurance.estado || blockIfConstruction) && "img-grayscale"
      }`}
    />
  );
};
