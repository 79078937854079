import React from "react";
import {
  MInputEmail,
  MInputGeneric,
  MInputNumber,
  MInputRut,
} from "@inversiones-ma/finfast-react-controls";

interface PropsPersonForm {
  form: any;
}

const PersonForm = ({ form }: PropsPersonForm) => {
  return (
    <>
      <div className="col-lg-6 col-12">
        <fieldset>
          <div className="floating-label">
            <MInputRut
              form={form}
              name="rutAsegurado"
              label="RUT"
              className="floating-input disabled"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>
      <div className="col-lg-6 col-12">
        <fieldset>
          <div className="floating-label">
            <MInputGeneric
              form={form}
              name="nombresAsegurado"
              label="Nombres"
              className="floating-input disabled"
              type="text"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>

      <div className="col-lg-6 col-12">
        <fieldset>
          <div className="floating-label">
            <MInputGeneric
              form={form}
              name="apellidoPaternoAsegurado"
              label="Apellido paterno"
              className="floating-input disabled"
              type="text"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>
      <div className="col-lg-6 col-12">
        <fieldset>
          <div className="floating-label">
            <MInputGeneric
              form={form}
              name="apellidoMaternoAsegurado"
              label="Apellido materno"
              className="floating-input disabled"
              type="text"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>
      <div className="col-lg-6 col-12">
        <fieldset>
          <div className="floating-label">
            <MInputEmail
              form={form}
              name="mailAsegurado"
              label="Correo"
              className="floating-input"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </fieldset>
      </div>
      <div className="col-lg-6 col-12">
        <fieldset>
          <div className="floating-label">
            <MInputNumber
              form={form}
              name="telefonoAsegurado"
              label="Teléfono"
              className="floating-input"
              placeholder=" "
              rules={{
                required: "Este campo es requerido",
              }}
              maxLength={12}
            />
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default PersonForm;
