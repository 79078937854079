import { InsuranceHeader } from "@controls/InsuranceHeader/InsuranceHeader";
import MLoading from "@controls/MLoading";
import { Steeper } from "@controls/Stepper/Stepper";
import { loadStepper } from "@redux/actions/GlobalActions";
import {
  cleanAdditionalWorkInfoTRC,
  cleanAssuredTRC,
} from "@redux/actions/InsuranceActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Step, Stepper } from "@redux/models/GlobalModels";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TRCAdditionalInfo from "./Partial/TRCAdditionalInfo";
import TRCAssuredData from "./Partial/TRCAssuredData";
import TRCWorkInformation from "./Partial/TRCWorkInformation";
import "./TRCInsurance.scss";

export interface LocationPropsTRC {
  id: number;
  descripcion: string;
}

const TRCInsurance = () => {
  const dispatch = useAppDispatch();
  const [tipoSeguro, setTiposeguro] = useState<LocationPropsTRC>(
    useLocation<LocationPropsTRC>().state
  );

  const { active } = useAppSelector((store) => store.global.stepper);
  const [loading, setLoading] = useState<boolean>(false);
  const stepsTRC: Step[] = [
    { id: 1, name: "Información del Asegurado" },
    { id: 2, name: "Identificación de la Obra" },
    { id: 3, name: "Información Adicional" },
  ];
  const stepper: Stepper = { active: 1, steps: stepsTRC };

  useEffect(() => {
    dispatch(loadStepper(stepper));
    if (active === 1) dispatch(cleanAssuredTRC());
    dispatch(cleanAdditionalWorkInfoTRC());

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <div className="anim-loading">
          <div className="lottie-animacion">
            <MLoading />
          </div>
        </div>
      ) : (
        <main className="bg-home trc-section">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-3">
                <InsuranceHeader
                  id={tipoSeguro?.id}
                  descripcion={tipoSeguro?.descripcion}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <Steeper />
              </div>
              {active === 1 && (
                <TRCAssuredData tipoSeguro={tipoSeguro}></TRCAssuredData>
              )}
              {active === 2 && (
                <TRCWorkInformation
                  tipoSeguro={tipoSeguro}
                ></TRCWorkInformation>
              )}
              {active === 3 && (
                <TRCAdditionalInfo tipoSeguro={tipoSeguro}></TRCAdditionalInfo>
              )}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default TRCInsurance;
