import * as createHistory from 'history';

import BaseModel from '@api/BaseModel';
import ApiConfiguration from '@api/configuration/Configuration';
import GenericBaseModel from '@api/GenericBaseModel';
import ApiGlobalActividad from '@api/global/ActividadProveedor';
import ApiCurrency from '@api/global/Currency';
import ApiPayment from '@api/global/Payment';
import ApiRegion from '@api/global/Region';
import ApiInsurance from '@api/insurance/insurance';
import ApiMembresiaAccount from '@api/membresia/account';
import ApiMembresiaAccountInsurance from '@api/membresia/accountInsurance';
import ApiNps from '@api/nps/nps';
import { finfast } from '@config/environment';
import { encryptData } from '@helpers/security';
import * as AccountActions from '@redux/actions/AccountActions';
import * as GlobalActions from '@redux/actions/GlobalActions';
import {
  loadInsurancePlansRC,
  loadInsurancePlansTRC,
  loadPeriodosMantencionTRC,
  loadTiposObrasTRC,
  loadTiposRiesgosObrasTRC,
} from '@redux/actions/InsuranceActions';
import * as SessionActions from '@redux/actions/SessionActions';
import { loadCart } from '@redux/actions/SessionActions';
import { TDispatch } from '@redux/hooks';
import {
  AccountState,
  ExecutiveState,
} from '@redux/models/AccountModels';
import {
  Commune,
  ListDataConfiguration,
  PaymentMethods,
  Region,
  SurveyNps,
} from '@redux/models/GlobalModels';
import {
  Insurance,
  InsurancePlan,
  PeriodoMantencionObra,
  TipoObra,
  TipoRiesgoObra,
} from '@redux/models/InsuranceModels';
import {
  ActivitiesList,
  Activity,
} from '@redux/models/SessionModels';
import { saveToSessionStorage } from '@redux/store/localStorage';
import store from '@redux/store/store';

const history = createHistory.createBrowserHistory();

export const DevLogin = (
  rut: string,
  password: string,
  dispatch: TDispatch
) => {
  return new Promise((resolve, reject) => {
    ApiMembresiaAccountInsurance.loginInsurance(
      rut,
      encryptData(password)
    ).then((data) => {
      if (data.success) {
        saveAuthData(data.token, data, dispatch);
        resolve(true);
      } else {
        reject(data);
      }
    });
  });
};

export const AutoLogin = (
  rToken: string,
  token: string | undefined,
  dispatch: TDispatch
) => {
  return new Promise((resolve, reject) => {
    dispatch(AccountActions.cleanState());
    if (!token) {
      if (rToken) {
        ApiMembresiaAccount.autoLogin(rToken).then((data) => {
          if (data.success) {
            if (data.status === "ValidUser") {
              //En caso de autologin
              saveAuthData(rToken, data, dispatch);
              //Retorno false ya que no tiene token aún
              resolve(false);
            } else {
              //Usuario no valido, retorna al login sin sesión
              window.location.href = finfast() + "/logout";
              resolve(false);
            }
          } else {
            //Login no exitoso, retorna al login sin sesión
            history.push("/home");
            resolve(false);
          }
        });
      } else {
        //No viene variable en la URL ni hay token
        resolve(true);
      }
    } else {
      //Existe sesión de token, por ende sólo se procede a entrar a la app
      history.push("/home");
      resolve(true);
    }
  });
};

export const saveAuthData = async (
  token: string,
  data: any,
  dispatch: TDispatch
) => {
  const executiveId = data.executives
  .filter((x: any) => x.idProducto === 2)[0]?.id ?? 0;
  const executive: ExecutiveState = {
    name: data.executiveName
    , phone: data.executivePhone
    , phoneAnnex: data.executivePhoneAnnex
    , email: data.executiveEmail
    , id: executiveId,
  };
  const accountData: AccountState = {
    token: data.token,
    run: data.run,
    name: data.name,
    names: data.names,
    lastName: data.lastName,
    motherLastName: data.motherLastName,
    email: data.email ?? "alertasit@finfast.com",
    idBillingType: data.idTipoFacturacion,
    idSalesLevel: data.idNivelVentas,
    executive: executive,
    idClassification: data.clasificacion,
    idActivity: data.idActividad,
    enabledEmit: data.enabledEmit,
    isAnonymous: false,
    isNaturalPerson: data.isNaturalPerson,
    showPreference: data.showPreference,
    emailPreference: data.emailPreference ?? data.email ?? "",
    validatePlazo: data.validatePlazo,
    blockedByDebt: false,
    loginDate: data.loginDate,
    loginDateClient: new Date(),
  };

  // guardar accountData
  localStorage.setItem("access_token", data.token);
  dispatch(AccountActions.loadLoginData(accountData));

  let dataConfiguration = [{ llave: "insuranceTRCRutMandanteMOP" }];

  Promise.all([
    ApiGlobalActividad.GetActividades(),
    ApiCurrency.GetUf(),
    ApiCurrency.GetUsd(),
    ApiPayment.GetPaymentMethods(),
    ApiRegion.GetRegionComuna(),
    ApiConfiguration.GetConfiguration(dataConfiguration),
    ApiNps.SurveyShowSurvey(),
    ApiInsurance.GetInsuranceInCart(),
    ApiInsurance.callPlanesSeguroAll("1"),
    ApiInsurance.calltipoObraTRCAll(),
    ApiInsurance.calltipoRiesgoObraTRCAll(),
    ApiInsurance.callperiodosMantencionTRCAll(),
    ApiInsurance.callPlanesSeguroAll("3"),
  ]).then((resp: any) => {
    const respActivities = resp[0] as GenericBaseModel<ActivitiesList>;
    const respUf = resp[1] as ValueUfExternalModel;
    const respUsd = resp[2] as ValueUsdExternalModel;
    const PaymentMethods = resp[3] as GenericBaseModel<PaymentMethods>;
    const respRegions = resp[4] as GenericBaseModel<RegionBaseExternalModel>;
    const respConfiguration =
      resp[5] as GenericBaseModel<ConfigurationExternalModel>;
    const respShowSurvey = resp[6] as GenericBaseModel<SurveyNps>;
    const respInsurance = resp[7] as GenericBaseModel<Insurance>;
    const respInsurancePlansRC = resp[8] as GenericBaseModel<InsurancePlan>;
    const respTipoObraTRC = resp[9] as GenericBaseModel<TipoObra>;
    const respTipoRiesgoObraTRC = resp[10] as GenericBaseModel<TipoRiesgoObra>;
    const respPeriodoMantencionTRC =
      resp[11] as GenericBaseModel<PeriodoMantencionObra>;
    const respInsurancePlansTRC = resp[12] as GenericBaseModel<InsurancePlan>;

    let Activities: ActivityBaseExternalModel[] = respActivities.data.activityOther;
    let ActivitiesRc: ActivityBaseExternalModel[] = respActivities.data.activityRc;

    const activities = Activities.map((a) => {
      return {
        code: a.codigo,
        description: a.descripcion,
        disabled: a.disabled,
        checked: false,
      } as Activity;
    });

    const activitiesRC = ActivitiesRc.map((a) => {
      return {
        code: a.codigo,
        description: a.descripcion,
        disabled: a.disabled,
        checked: false,
      } as Activity;
    });

    const regions = respRegions.dataList.map((r) => {
      return {
        id: r.idRegion,
        name: r.nombreRegion,
        code: r.codigo,
        totalCost: r.costoTotal,
        order: r.orden,
        communes: r.comuna.map((c) => {
          return {
            id: c.idComuna,
            name: c.nombreComuna,
            idRegion: c.idRegion,
            idCity: c.idCiudad,
          } as Commune;
        }),
      } as Region;
    });

    const PayMethods = PaymentMethods.dataList.map((y) => {
      return {
        id: y.id,
        descripcion: y.descripcion,
        estado: y.estado,
        mensajeContingencia: y.mensajeContingencia,
      } as PaymentMethods;
    });

    const Configuration = respConfiguration.dataList.map((x) => {
      return {
        llave: x.llave,
        valorAlfanumerico: x.valorAlfanumerico,
        valorBooleano: x.valorBooleano,
        valorNumerico: x.valorNumerico,
        valorFecha: x.valorFecha,
        bannerMulti2: x.bannerMulti2,
      } as ListDataConfiguration;
    });

    if (respShowSurvey.success) {
      // guardar si debe responder encuesta
      dispatch(SessionActions.loadSurvey(respShowSurvey.data.showSurvey));
      dispatch(GlobalActions.loadDataSurvey(respShowSurvey.data));
    } else {
      dispatch(GlobalActions.loadDataSurvey(undefined));
      dispatch(SessionActions.loadSurvey(false));
    }
    // guardar actividades
    dispatch(SessionActions.loadActivities(activities));

    dispatch(SessionActions.loadActivitiesRC(activitiesRC));
    // guardar regiones
    dispatch(GlobalActions.loadRegions(regions));
    // guardar uf
    dispatch(SessionActions.loadUf(respUf.valorUf, new Date(respUf.fecha)));
    // guardar usd
    dispatch(SessionActions.loadUsd(respUsd.valorUsd, new Date(respUsd.fecha)));
    // guardar Metodos de pagos
    dispatch(GlobalActions.loadPaymentMethods(PayMethods));

    // guardar configuracion
    dispatch(GlobalActions.loadDataDisabledTrc(Configuration));
    //guardar Planes RC Iniciales
    dispatch(loadInsurancePlansRC(respInsurancePlansRC.dataList));
    //guardar TiposObra
    dispatch(loadTiposObrasTRC(respTipoObraTRC.dataList));
    //guardar TiposRiesgoObra
    dispatch(loadTiposRiesgosObrasTRC(respTipoRiesgoObraTRC.dataList));
    //guardar PeriodosMantencionObra
    dispatch(loadPeriodosMantencionTRC(respPeriodoMantencionTRC.dataList));
    //guardar Planes TRC Iniciales
    dispatch(loadInsurancePlansTRC(respInsurancePlansTRC.dataList));

    const insurance: Insurance = {
      listInsuranceRC: respInsurance.data.listInsuranceRC ?? [],
      listInsurancePF: respInsurance.data.listInsurancePF ?? [],
      listInsuranceTRC: respInsurance.data.listInsuranceTRC ?? [],
    };

    dispatch(loadCart({ item: insurance }));

    //guardar estado global en sessionstorage
    saveToSessionStorage(store?.getState());
  });
};

interface ValueUfExternalModel extends BaseModel {
  valorUf: number;
  fecha: string;
}

interface ValueUsdExternalModel extends BaseModel {
  valorUsd: number;
  fecha: string;
}

interface ActivityBaseExternalModel {
  codigo: number;
  descripcion: string;
  disabled: boolean;
}

interface RegionBaseExternalModel {
  idRegion: number;
  nombreRegion: string;
  codigo: string;
  costoTotal: number | null;
  orden: number | null;
  comuna: {
    idComuna: number;
    nombreComuna: string;
    idRegion: number;
    idCiudad: number | null;
  }[];
}

interface ConfigurationExternalModel {
  llave: string;
  valorBooleano?: boolean;
  valorNumerico?: number;
  valorFecha?: Date;
  valorAlfanumerico?: string;
  fechaActualizacion?: Date;
  bannerMulti2?: string;
}
