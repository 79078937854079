import { Coverage } from "@redux/models/InsuranceModels";

type TDetailCoverageProps = {
  item: Coverage;
};

export const DetailCoverage = ({ item }: TDetailCoverageProps) => {
  return (
    <div className="detalle-coberturas my-3">
      <div className="row bg-grey p-2">
        <div className="col-6 ">
          <h4 className=" fs-16 color-primary fw-bold p-0 m-0">Cobertura</h4>
        </div>
        <div className="col-6 ">
          <p className="fs-14 color-text p-0 m-0">
            {item.descripcionCobertura}
          </p>
        </div>
      </div>
      <div className="row p-2">
        <div className="col-6 ">
          <h4 className=" fs-16 color-secondary fw-bold p-0 m-0">Asegurado</h4>
        </div>
        <div className="col-6 ">
          <p className="fs-14 color-text p-0 m-0">{item.montoAsegurado}</p>
        </div>
      </div>
      <hr className="m-0" />
      <div className="row  p-2">
        <div className="col-6 ">
          <h4 className=" fs-16 color-gris87 fw-bold p-0 m-0">Prima Neta</h4>
        </div>
        <div className="col-6">
          <p className="fs-14 color-gris87 p-0 m-0">{item.primaNeta} </p>
        </div>
      </div>
    </div>
  );
};
