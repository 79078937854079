import "./Home.scss";
import { Main } from "./components/";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RenderLoading } from "../../../features/RenderLoading";
import { useGetAllAvailableQuery } from "@redux/api/typesInsurancesAPI";
import { FilterExternalInsurances, FilterInsurances } from "./functions";
import {
  setInsurances,
  setExternalInsurances,
} from "@redux/actions/TypeInsuranceActions";
import { cleanAssured, cleanAssuredTRC, cleanInsurancePlansPA } from "@redux/actions/InsuranceActions";

export const Home = () => {
  const dispatch = useAppDispatch();
  const { insurances, externalInsurances } = useAppSelector(
    (state) => state.typesInsurances
  );
  // Llamada a la API
  const { data, isLoading, isSuccess } = useGetAllAvailableQuery();

  useEffect(() => {
    //active == 1
    dispatch(cleanAssured());
    dispatch(cleanInsurancePlansPA());
    dispatch(cleanAssuredTRC());

    if (!isLoading && isSuccess) {
      dispatch(
        setInsurances(
          data!.dataList.filter((i) => FilterInsurances.filter(i.id))
        )
      );
      dispatch(
        setExternalInsurances(
          data!.dataList
            .filter((i) => FilterExternalInsurances.filter(i.id))
            .sort((a, b) => b.id - a.id)
        )
      );
    }
  }, [isLoading, isSuccess, data, dispatch]);

  return (
    <>
      {isLoading && !isSuccess ? (
        <RenderLoading />
      ) : (
        <Main insurances={insurances} externalInsurances={externalInsurances} />
      )}
    </>
  );
};
