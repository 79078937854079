import { MButton } from "@inversiones-ma/finfast-react-controls";
import { Dispatch, SetStateAction } from "react";

type TRCButtonDetailCoverageProps = {
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  selecionarPlan: () => void;
};

export const RCButtonDetailCoverage = ({
  setShowModal,
  selecionarPlan,
}: TRCButtonDetailCoverageProps) => {
  return (
    <div className="row justify-content-center py-3">
      <div className="col-12 col-lg-3 pt-3 order-2 order-md-1">
        <MButton
          type="button"
          className="btn btn-primary outline sistema w-100"
          data-bs-toggle="collapse"
          data-bs-target="#advance-results"
          onClick={() => setShowModal(false)}
        >
          Cancelar
        </MButton>
      </div>
      <div className="col-12 col-lg-3 pt-3 order-1 order-md-2">
        <MButton
          type="button"
          className="btn btn-primary sistema w-100"
          data-bs-toggle="collapse"
          data-bs-target="#advance-results"
          onClick={() => selecionarPlan()}
        >
          Seleccionar plan
        </MButton>
      </div>
    </div>
  );
};
