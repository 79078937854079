import { changeStep, loadStepper } from "@redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LocationPropsTRC } from "../TRCInsurance";
import { useForm } from "react-hook-form";
import "../TRCInsurance.scss";
import {
  MButton,
  MInputCheckBox,
} from "@inversiones-ma/finfast-react-controls";
import { AssuredTRC } from "@redux/models/InsuranceModels";
import { cleanAssuredTRC, loadAssuredTRC } from "@redux/actions/InsuranceActions";
import PersonForm from "../../PartialForm/PersonForm";
import CompanyForm from "../../PartialForm/CompanyForm";
import MandatoryInfo from "./PartialForm.tsx/MandatoryInfo";
import AssuredAddress from "./PartialForm.tsx/AssuredAddress";
import { nombreApellido } from "@helpers/helpers2";
export interface Props {
  tipoSeguro: LocationPropsTRC;
}

const TRCAssuredData = (props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { run, name, isNaturalPerson } = useAppSelector(
    (store) => store.account
  );
  const { assuredTrc } = useAppSelector((store) => store.insuranceTRC);

  const [beneficiario, setBeneficiario] = useState<boolean>(
    assuredTrc.tieneBeneficiarioAdicional
  );

  let nombreApellidos;
  let valuesdefault: (AssuredTRC | undefined) = undefined;
  if (isNaturalPerson) {
    nombreApellidos = nombreApellido(name!);

    valuesdefault = {
      ...assuredTrc,
      rutAsegurado: run!,
      nombresAsegurado: nombreApellidos.nombres,
      apellidoPaternoAsegurado: nombreApellidos.primerApellido,
      apellidoMaternoAsegurado: nombreApellidos.segundoApellido,
    };
  } else {
    valuesdefault = {
      ...assuredTrc,
      rutAsegurado: run!,
      razonSocialAsegurado: name!,
    };
  }

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: valuesdefault,
  });

  const { handleSubmit, setValue } = form;
  
  useEffect(() => {
    setValue("tieneBeneficiarioAdicional", beneficiario);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data: AssuredTRC) => {
    let newData: AssuredTRC = {
      ...data,
      //tieneBeneficiarioAdicional: beneficiario,
    };

    dispatch(loadAssuredTRC(newData));
    dispatch(changeStep(2));
  };

  const handleOnClickBack = () => {
    // dispatch(cleanAssuredTRC());
    dispatch(changeStep(1));
    history.push("/home");
  };

  return (
    <>
      <div className="col-lg-8 trc-section">
        <div className="section-insurance">
          <h2 className="titles-center fs-24">
            Ingresa los datos del asegurado
          </h2>
          <p className="text-center pb-4">
            Completa el formulario con los datos de la empresa que contrata.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="subtitle-mini">
              Información del asegurado / contratante
            </h3>
            <div className="row pb-3">
              {isNaturalPerson ? (
                <PersonForm form={form} />
              ) : (
                <CompanyForm form={form} />
              )}
            </div>
            <AssuredAddress form={form} />
            <MandatoryInfo form={form} tipoSeguro={props.tipoSeguro} />

            <h3 className="subtitle-mini">Agregar asegurado adicional</h3>
            <div className="row pb-3">
              <div className="col-12">
                <div className="form-check">
                  <MInputCheckBox
                    name="tieneBeneficiarioAdicional"
                    // checked={beneficiario}
                    onChange={() => setBeneficiario(!beneficiario)}
                    form={form}
                    label="Agregar al mandante como asegurado adicional"
                  ></MInputCheckBox>
                </div>
              </div>
            </div>

            <hr />

            <div className="row pb-2">
              <div className="col-lg-3 col-md-4 order-3 order-md-1">
                <MButton
                  type="button"
                  onClick={handleOnClickBack}
                  className="btn btn-primary sistema outline w-100"
                >
                  <i className="fa fa-arrow-left"></i> Volver
                </MButton>
              </div>
              <div className="col-lg-6 col-md-4 order-2"></div>
              <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
                <MButton
                  type="submit"
                  className="btn btn-primary sistema w-100"
                >
                  Continuar <i className="fa fa-arrow-right"></i>
                </MButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TRCAssuredData;
