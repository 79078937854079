import { InjectTokenHeader, MAxios } from "../conf";

export async function GetConfiguration(key: any) {
  const endpoint = "Configuration/GetConfiguration";

  const res = await MAxios().post(endpoint, key);
  return res.data;
}

const ApiConfiguration = {
  GetConfiguration,
};

export default ApiConfiguration;
