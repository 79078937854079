import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  MInputCheckBox,
  MInputEmail,
  MInputGeneric,
  MInputNumber,
  MInputRadioButtons,
  MInputRut,
  MInputSelect,
  MTooltip,
} from "@inversiones-ma/finfast-react-controls";
import * as GlobalModels from "@redux/models/GlobalModels";
import * as helpers2 from "@helpers/helpers2";
import * as helpers from "@helpers/helpers";

interface PropsAdditionalInformation {
  form: any;
}

const AdditionalInformation = ({ form }: PropsAdditionalInformation) => {
  const { regions } = useAppSelector((store) => store.global);
  // const { assured } = useAppSelector((store) => store.insuranceRC);

  const { setValue, watch } = form;
  const {
    regionBeneficiarioAdicional,
    comunaBeneficiarioAdicional,
    tieneBeneficiarioAdicional,
    tipoBeneficiarioAdicional,
  } = watch();

  const [addAdicionalBenef, setAddAdicionalBenef] = useState<boolean>(
    tieneBeneficiarioAdicional
  );

  const [arrComunaBeneficiario, setArrComunaBeneficiario] = useState<
    GlobalModels.Commune[]
  >(helpers2.getCommunes("", regions));

  useEffect(() => {
    setValue("tieneBeneficiarioAdicional", addAdicionalBenef);
  }, []);

  useEffect(() => {
    if (comunaBeneficiarioAdicional !== "") {
      setValue("comunaBeneficiarioAdicional", comunaBeneficiarioAdicional);
    }
  }, [arrComunaBeneficiario]);

  useEffect(() => {
    if (!tieneBeneficiarioAdicional) {
      setValue("tipoBeneficiarioAdicional", "");
      setValue("rutBeneficiarioAdicional", "");
      setValue("razonSocialBeneficiarioAdicional", "");
      setValue("mailBeneficiarioAdicional", "");
      setValue("telefonoBeneficiarioAdicional", "");
      setValue("mailBeneficiarioAdicional", "");
      setValue("nombresBeneficiarioAdicional", "");
      setValue("apellidoPaternoBeneficiarioAdicional", "");
      setValue("apellidoMaternoBeneficiarioAdicional", "");
      setValue("regionBeneficiarioAdicional", "");
      setValue("comunaBeneficiarioAdicional", "");
      setValue("calleBeneficiarioAdicional", "");
      setValue("numeroBeneficiarioAdicional", "");
    }
  }, [tieneBeneficiarioAdicional]);

  useEffect(() => {
    let r = regions?.find(
      (r) => r.id.toString() == regionBeneficiarioAdicional
    );
    let comunas = helpers.getComunas(r?.name, regions);
    setArrComunaBeneficiario(comunas);
    // eslint-disable-next-line
  }, [regionBeneficiarioAdicional]);

  useEffect(() => {
    if (tipoBeneficiarioAdicional === "empresa") {
      setValue("nombresBeneficiarioAdicional", "");
      setValue("apellidoPaternoBeneficiarioAdicional", "");
      setValue("apellidoMaternoBeneficiarioAdicional", "");
    }

    if (tipoBeneficiarioAdicional === "persona") {
      setValue("razonSocialBeneficiarioAdicional", "");
    }
    // eslint-disable-next-line
  }, [tipoBeneficiarioAdicional]);

  return (
    <>
      <h3 className="subtitle-mini">
        Información de asegurado adicional
        <MTooltip
          iconClass="fas fa-question-circle fs-10"
          content={
            <h6 className="text-justify">
              <i>
                Ingresa los datos de la persona o institución que deseas que
                figure en la póliza
              </i>
            </h6>
          }
        ></MTooltip>
      </h3>
      <div className="row">
        <div className="col-12 asegurados">
          <h4 className="color-text fs-16">
            <b>¿Deseas agregar un asegurado adicional?</b> <i>(opcional)</i>
          </h4>
          <MInputCheckBox
            form={form}
            name="tieneBeneficiarioAdicional"
            checked={addAdicionalBenef}
            onChange={(e) => setAddAdicionalBenef(e)}
            label={<p>Sí, deseo agregar un asegurado adicional</p>} 
          ></MInputCheckBox>
        </div>
        {addAdicionalBenef && (
          <>
            <div className="col-12 asegurados">
              <h4 className="color-text fs-16">
                <b>Selecciona el tipo de asegurado adicional</b>
              </h4>
              <div className="row">
                <MInputRadioButtons
                  name="tipoBeneficiarioAdicional"
                  className="form-check-label"
                  labelPosition="right"
                  form={form}
                  direction="column"
                  items={[
                    { value: "empresa", label: "Empresa" },
                    { value: "persona", label: "Persona natural" },
                  ]}
                  rules={{
                    required: "Este campo es requerido",
                  }}
                />
              </div>
            </div>
            <>
              <div className="row">
                {!!tipoBeneficiarioAdicional && (
                  <div className="col-lg-6 col-12">
                    <div className="floating-label">
                      <MInputRut
                        form={form}
                        name="rutBeneficiarioAdicional"
                        label="RUT"
                        className="floating-input"
                        placeholder=" "
                        rules={{
                          required: "Este campo es requerido",
                        }}
                      />
                    </div>
                  </div>
                )}
                {tipoBeneficiarioAdicional === "empresa" && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="floating-label">
                        <MInputGeneric
                          form={form}
                          name="razonSocialBeneficiarioAdicional"
                          label="Razón Social"
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {tipoBeneficiarioAdicional === "persona" && (
                  <>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputGeneric
                          form={form}
                          name="nombresBeneficiarioAdicional"
                          label="Nombres"
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputGeneric
                          form={form}
                          name="apellidoPaternoBeneficiarioAdicional"
                          label="Apellido paterno"
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputGeneric
                          form={form}
                          name="apellidoMaternoBeneficiarioAdicional"
                          label="Apellido materno"
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          // rules={{
                          //   required: "Este campo es requerido",
                          // }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {!!tipoBeneficiarioAdicional && (
                <>
                  <div className="row pb-3">
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputEmail
                          form={form}
                          name="mailBeneficiarioAdicional"
                          label="Correo"
                          className="floating-input"
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputNumber
                          form={form}
                          name="telefonoBeneficiarioAdicional"
                          label="Teléfono"
                          className="floating-input"
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                          maxLength={12}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <h3 className="subtitle-mini">
                      Dirección de asegurado adicional
                    </h3>
                  </div>

                  <div className="row pb-3">
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputSelect
                          form={form}
                          name="regionBeneficiarioAdicional"
                          className="form-select info-form input-tag"
                          items={regions as GlobalModels.Region[]}
                          valueItem="id"
                          label="Región"
                          descriptionItem="name"
                          orderBy={{
                            by: "name",
                            desc: false,
                            isString: true,
                          }}
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputSelect
                          form={form}
                          name="comunaBeneficiarioAdicional"
                          className="form-select info-form input-tag"
                          items={arrComunaBeneficiario}
                          valueItem="id"
                          descriptionItem="name"
                          orderBy={{
                            by: "name",
                            desc: false,
                            isString: true,
                          }}
                          label="Comuna"
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputGeneric
                          form={form}
                          name="calleBeneficiarioAdicional"
                          label="Calle"
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <MInputNumber
                          form={form}
                          name="numeroBeneficiarioAdicional"
                          label="Número"
                          className="floating-input"
                          maxLength={6}
                          placeholder=" "
                          rules={{
                            required: "Este campo es requerido",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </div>
    </>
  );
};

export default AdditionalInformation;
