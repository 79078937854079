import React from "react";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import { useHistory } from "react-router-dom";

export default function EmptyCar() {
  const history = useHistory();

  return (
    <div className="row">
      <div className="col-12 text-center p-4">
        <h3 className="subtitle-mini text-center">
          Tu carro de compras está vacío
        </h3>

        <p>
          Puedes consultar tus pólizas desde {"  "}
          <span
            onClick={() => history.push("/quotes")}
            className="color-secondary fw-bold text-decoration-underline texto-alert-newCertificate"
          >
            Descargar polizas
          </span>{" "}
          <br />o iniciar una nueva compra.
        </p>
      </div>
      <div className="col-12 text-center">
        <MButton
          type="button"
          className="btn btn-primary sistema external"
          aria-expanded="false"
          onClick={() => history.push("/buy-certificate")}
        >
          Seguir comprando
        </MButton>
      </div>
    </div>
  );
}
