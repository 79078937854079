import {
  loadAssuredTRC,
  cleanAssuredTRC,
  loadWorkInformationTRC,
  loadTiposObrasTRC,
  loadTiposRiesgosObrasTRC,
  loadPeriodosMantencionTRC,
  loadInsurancePlansTRC,
  cleanAdditionalWorkInfoTRC,
} from "./../actions/InsuranceActions";
import { InsuranceTRCState } from "@redux/models/InsuranceModels";
import { createSlice } from "@reduxjs/toolkit";

const inicialState: InsuranceTRCState = {
  plans: [],
  assuredTrc: {
    //asegurado
    rutAsegurado: "",
    razonSocialAsegurado: "",
    nombresAsegurado: "",
    apellidoPaternoAsegurado: "",
    apellidoMaternoAsegurado: "",
    mailAsegurado: "",
    telefonoAsegurado: "",
    regionAsegurado: "",
    comunaAsegurado: "",
    calleAsegurado: "",
    numeroAsegurado: 0,
    //mandante
    rutMandante: "",
    razonSocialMandante: "",
    mailMandante: "",
    telefonoMandante: "",
    regionMandante: "",
    comunaMandante: "",
    calleMandante: "",
    numeroMandante: 0,

    //adicional
    // rutBeneficiarioAdicional: "",
    // apellidoPaternoBeneficiarioAdicional: "",
    // apellidoMaternoBeneficiarioAdicional: "",
    // nombresBeneficiarioAdicional: "",
    // razonSocialBeneficiarioAdicional: "",
    // regionBeneficiarioAdicional: "",
    // comunaBeneficiarioAdicional: "",
    // calleBeneficiarioAdicional: "",
    // numeroBeneficiarioAdicional: 0,
    // mailBeneficiarioAdicional: "",
    // telefonoBeneficiarioAdicional: "",

    tieneBeneficiarioAdicional: false,
  },
  dataWork: {
    nombreObra: "",
    descripcionObra: "",
    direccionObra: "",
    direccionAdicionalObra: "",
    idRegionObra: "",
    idComunaObra: "",
    obraCercanaLagunaMar: false,
    obraCercanaLagunaMarAltura: false,
    idTipoRiesgoObra: 0,
    idTipoObra: 0,
    numeroPisos: 0,
    numeroSubterraneos: 0,
    numeroTrabajadores: 0,
    vigenciaInicioObra: new Date(),
    vigenciaTerminoObra: new Date(),
    idPeriodoMantencionObra: 0,
    montoUfAseguradoObra: 0,
    montoUfResponsabilidadCivil: 0,
    aseguradoAdicional: false,
    isDireccionSinNumero: false,
  },
  tiposObra: [],
  tiposRiesgoObra: [],
  periodosMantencion: [],
};

const slice = createSlice({
  name: "insuranceTRC",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAssuredTRC, (state, action) => {
        state.assuredTrc = action.payload;
      })
      .addCase(cleanAssuredTRC, (state) => {
        state.assuredTrc = inicialState.assuredTrc;
      })
      .addCase(loadWorkInformationTRC, (state, action) => {
        state.dataWork = action.payload;
      })
      .addCase(loadTiposObrasTRC, (state, action) => {
        state.tiposObra = action.payload;
      })
      .addCase(loadTiposRiesgosObrasTRC, (state, action) => {
        state.tiposRiesgoObra = action.payload;
      })
      .addCase(loadPeriodosMantencionTRC, (state, action) => {
        state.periodosMantencion = action.payload;
      })
      .addCase(loadInsurancePlansTRC, (state, action) => {
        state.plans = action.payload;
      })
      .addCase(cleanAdditionalWorkInfoTRC, (state) => {
         state.dataWork = inicialState.dataWork;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
