/*
* @param paramrut {string}= 16.761.256-9
* @return {string} = 167512569
*/
var numeroPolizaClean = function numeroPolizaClean(paramNumeroPoliza) {
    return ('' + paramNumeroPoliza).toString().replace(/[^0-9]+/g, '').toUpperCase();
};

/*
* @param paramNumeroPoliza {number/string} = 167512569
* @return {string} = 16256-1
*/
var numeroPolizaFormat = function numeroPolizaFormat(paramNumeroPoliza) {
    // quita todo lo distinto a numeros
    var numeroPoliza = numeroPolizaClean(paramNumeroPoliza);
    var result = '';
    if(numeroPoliza.length > 1) {
      result = numeroPoliza.substr(0, numeroPoliza.length - 1) + '-' + numeroPoliza.substr(numeroPoliza.length - 1);
    } else {
      result = numeroPoliza;
    }
    return result;
};

exports.numeroPolizaClean = numeroPolizaClean;
exports.numeroPolizaFormat = numeroPolizaFormat;