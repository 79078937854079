import { TypeInsurance } from "@redux/models/TypeInsuranceModels";
import { Insurances, HeaderContent, ExternalInsurances } from "./";

export interface MainProps {
  insurances: TypeInsurance[];
  externalInsurances: TypeInsurance[];
}

export const Main = ({
  insurances,
  externalInsurances,
}: MainProps): JSX.Element => {
  return (
    <main className="bg-home">
      <div className="seguros-section home">
        <div className="container ">
          <HeaderContent />
          <Insurances insurances={insurances} />
          {
            //<ExternalInsurances externalInsurances={externalInsurances} />}
          }
        </div>
      </div>
    </main>
  );
};
