import { InjectTokenHeader, MAxios } from "../conf";

const GetPaymentMethods = async () => {
  const endpoint = "PaymentMethods/GetPaymentEnabledPlatform?platformCode=sms";

  const res = await MAxios().get(endpoint);

  return res.data;
};

const ApiPayment = {
  GetPaymentMethods,
};

export default ApiPayment;
