import { createAction } from '@reduxjs/toolkit';

import { PaymentMethods, Region, SurveyNps, ListDataConfiguration, Stepper } from '../models/GlobalModels';

export const loadRegions = createAction('GLOBAL_LOAD_REGIONS', (regions: Region[]) => {
    return {
        payload: regions
    }
});

export const setCurrentDate = createAction('GLOBAL_SET_CURRENT_DATE', (currentDate: string) => {
    return {
        payload: currentDate
    }
});

export const loadPaymentMethods = createAction('GLOBAL_LOAD_PAYMENT_METHODS', (paymentMethods: PaymentMethods[]) => {
    return {
        payload: paymentMethods
    }
});

export const loadDataSurvey = createAction('GLOBAL_LOAD_SURVEY', (dataSurvey: SurveyNps | undefined) => {
    return {
        payload: dataSurvey
    }
});

export const loadDataDisabledTrc = createAction('GLOBAL_LOAD_DATADISABLED_TRC', (dataDisabledTrc: ListDataConfiguration[] | undefined) => {
    return {
        payload: dataDisabledTrc
    }
});

export const loadStepper = createAction("GLOBAL_LOAD_STEPPER", (stepper: Stepper) => {
    return {
        payload: stepper
    }
})

export const changeStep = createAction("GLOBAL_CHANGE_STEP", (step: number) => {
    return {
        payload: step
    }
})


export const cleanState = createAction('GLOBAL_CLEAN_STATE');