import React from "react";
import { Router } from "react-router-dom";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import store from "./scripts/redux/store/store";
import Routing from "./Routing"
import { History } from "history";
import "./styles/app.scss";
import "./styles/custom.scss";
import "./styles/safe.scss";
import AutoScrollToTop from "./components/features/AutoScrollToTop";
import imgArrowScroll from "./img/flecha_scroll.svg";
import ScrollToTop from "react-scroll-up";
import ReactGA from "react-ga4";

const App = (props: { history: History }) => {
  props.history.listen((page) => {
    ReactGA.event({
      category: "App Seguros",
      label: "navegacion en sitio",
      action: `Navegación en ${page.pathname}`,
    });
  });

  return (
    <Provider store={store}>
      <Router history={props.history}>
        <AutoScrollToTop />
        <ScrollToTop
          showUnder={160}
          style={{ bottom: 88, right: 12, zIndex: 1 } as React.CSSProperties}
        >
          <img alt="Flecha hacia arriba" src={imgArrowScroll}></img>
        </ScrollToTop>
        <Routing />
      </Router>
    </Provider>
  );
};

export default App;
