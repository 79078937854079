export const arrMenu = [
  {
    id: 1,
    title: "Inicio",
    liClass: "nav-item menu-home-pr",
    iclass: "",
    url: "/home",
    order: 1,
  },
  {
    id: 2,
    title: "Comprar Certificados",
    liClass: "nav-item menu-home-pr",
    iclass: "",
    url: "/buy-certificate",
    order: 2,
  },
  {
    id: 3,
    title: "Mis Certificados",
    liClass: "nav-item menu-home-pr",
    iclass: "",
    url: "/my-certificates",
    order: 3,
  },
  {
    id: 4,
    title: "Filtrar Licitaciones",
    liClass: "nav-item menu-home-pr",
    iclass: "",

    url: "/filter",

    order: 4,
  },
  {
    id: 5,
    title: "Carrito",
    liClass: "nav-item menu-home-pr",
    iclass: "fas fa-shopping-cart",
    order: 5,
    showShoppingCartQuantity: true,
    url: "/homecart",
  },
];

export const arrStep = [
  { step: "1", title: "Carro de compras" },
  { step: "2", title: "Confirmación de pago" },
];

export const arrStepByCostCero = [
  { step: "1", title: "Carro de compras" },
  { step: "2", title: "Confirmación de pago" },
];

