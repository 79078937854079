import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import Pay from "./Pay";
import { MPopup } from "@inversiones-ma/finfast-react-controls";
import { cleanStateCart, loadCart } from "@redux/actions/SessionActions";
import ItemsRC from "./ItemsRC";
import ItemsPF from "./ItemsPF";
import ItemsTRC from "./ItemsTRC";
import ApiInsurance from "@api/insurance/insurance";
import BaseModel from "@api/BaseModel";
import RCDetailCoverage from "../../RCInsurance/Partial/RCDetailCoverage";
import {
  Insurance,
  InsurancePlan,
  InsuranceTRCModel,
  Prima,
} from "@redux/models/InsuranceModels";
import "../HomeCart.scss";
import EmptyCar from "./EmptyCar";
import { CoverageDetail } from "../../PersonalAccidents/Partials/Modals/CoverageDetails";
import TRCViewCoverage from "../../TRCInsurance/Partial/TRCViewCoverage";
import { loadWorkInformationTRC } from "@redux/actions/InsuranceActions";

const CartStep1 = () => {
  const dispatch = useAppDispatch();
  const { cart } = useAppSelector((store) => store.session);
  const { plans } = useAppSelector((store) => store.insuranceTRC);

  const [showModalCoverage, setShowModalCoverage] = useState<boolean>(false);
  const [showModalCoveragePF, setShowModalCoveragePF] =
    useState<boolean>(false);
  const [showModalCoverageTRC, setShowModalCoverageTRC] =
    useState<boolean>(false);
  const [deleteCart, setDeleteCart] = useState<boolean>(false);
  const [idPropuesta, setIdPropuesta] = useState<number>(1);
  const [primaBruta, setPrimaBruta] = useState<number>(0);
  const [primaNeta, setPrimaNeta] = useState<number>(0);
  const [idPropuestaPF, setIdPropuestaPF] = useState<number>(1);
  const [idInsurance, setIdInsurance] = useState<number>(0);

  const [insurancePlan, setInsurancePlan] = useState<InsurancePlan | any>(
    undefined
  );
  const [quoteResult, setQuoteResult] = useState<Prima | undefined>(undefined);

  useEffect(() => {
    // eslint-disable-next-line
  }, [cart]);

  const handlerLinkShowCoverageOnClick = (
    id: number | undefined,
    primaBruta: number | undefined,
    primaNeta: number | undefined
  ) => {
    setIdPropuesta(id as number);
    setPrimaBruta(primaBruta as number);
    setPrimaNeta(primaNeta as number);
    setShowModalCoverage(true);
  };

  const handlerLinkShowCoverageOnClickPF = (id: number | undefined) => {
    setIdPropuestaPF(id as number);
    setShowModalCoveragePF(true);
  };

  const handlerLinkShowCoverageOnClickTRC = (
    x: InsuranceTRCModel | undefined
  ) => {
    dispatch(loadWorkInformationTRC(x?.dataWork!));
    setInsurancePlan(
      plans.find((a) => a.idPropuesta === x?.poliza.idOpcionPropuesta)
    );
    setQuoteResult(x?.prima);
    setShowModalCoverageTRC(true);
  };

  const handlerDeleteOnCart = async (
    idInsurance: number | null,
    idTipoSeguro: number
  ) => {
    if (idInsurance === null) return;

    setIdInsurance(idInsurance);
    setDeleteCart(true);

    await ApiInsurance.DeleteInsuranceFromCart(idInsurance)
      .then((resp: BaseModel) => {
        if (resp !== null && resp.success) {
          //responsabilidad civil
          if (idTipoSeguro === 1) {
            let newInsurance = cart?.item.listInsuranceRC?.filter(
              (data) => data.poliza.id !== idInsurance
            );
            const insurance: Insurance = {
              listInsuranceRC: newInsurance,
              listInsurancePF: cart?.item.listInsurancePF,
              listInsuranceTRC: cart?.item.listInsuranceTRC,
            };

            dispatch(loadCart({ item: insurance }));
          }
          //Proteccccion civil
          if (idTipoSeguro === 2) {
            let newInsurance = cart?.item.listInsurancePF?.filter(
              (data) => data.poliza.id !== idInsurance
            );
            const insurance: Insurance = {
              listInsuranceRC: cart?.item.listInsuranceRC,
              listInsurancePF: newInsurance,
              listInsuranceTRC: cart?.item.listInsuranceTRC,
            };

            dispatch(loadCart({ item: insurance }));
          }

          if (idTipoSeguro === 3) {
            let newInsurance = cart?.item.listInsuranceTRC?.filter(
              (data) => data.poliza.id !== idInsurance
            );
            const insurance: Insurance = {
              listInsuranceRC: cart?.item.listInsuranceRC,
              listInsurancePF: cart?.item.listInsurancePF,
              listInsuranceTRC: newInsurance,
            };

            dispatch(loadCart({ item: insurance }));
          }
        }
      })
      .catch((err) => console.error("Ha ocurrido un error:" + err.message))
      .finally(() => {
        setDeleteCart(false);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-7 my-3">
          <div className="section-insurance">
            {!cart.item.listInsuranceRC.length &&
              !cart.item.listInsuranceTRC.length &&
              !cart.item.listInsurancePF.length && <EmptyCar />}

            <ItemsRC
              handlerLinkShowCoverageOnClick={handlerLinkShowCoverageOnClick}
              handlerDeleteOnCart={handlerDeleteOnCart}
              deleteCart={deleteCart}
              id={idInsurance}
            ></ItemsRC>

            <ItemsPF
              handlerLinkShowCoverageOnClick={handlerLinkShowCoverageOnClickPF}
              handlerDeleteOnCart={handlerDeleteOnCart}
              deleteCart={deleteCart}
              id={idInsurance}
            ></ItemsPF>

            <ItemsTRC
              handlerLinkShowCoverageOnClickTRC={
                handlerLinkShowCoverageOnClickTRC
              }
              handlerDeleteOnCart={handlerDeleteOnCart}
              deleteCart={deleteCart}
              id={idInsurance}
            ></ItemsTRC>
          </div>
        </div>
        <div className="col-12 col-lg-5 my-3">
          <div className="section-insurance">
            <div className="row">
              <Pay />
            </div>
          </div>
        </div>
      </div>
      {showModalCoverage && (
        <RCDetailCoverage
          showModal={showModalCoverage}
          setShowModal={setShowModalCoverage}
          idPropuesta={idPropuesta}
          setIdPropuestaSelect={setIdPropuesta}
          fromCart={true}
          primaBruta={primaBruta}
          primaNeta={primaNeta}
        />
      )}
      {showModalCoveragePF && (
        <CoverageDetail
          showModal={showModalCoveragePF}
          setShowModal={setShowModalCoveragePF}
          idPropuesta={idPropuestaPF}
          setIdPropuestaSelect={setIdPropuestaPF}
          fromCart={true}
        />
      )}
      {showModalCoverageTRC && (
        <MPopup
          size="xl"
          showClose={true}
          showModal={showModalCoverageTRC}
          setShowModal={setShowModalCoverageTRC}
        >
          <TRCViewCoverage
            planSelected={insurancePlan}
            prima={quoteResult}
            expandedLink={false}
            setShowModal={setShowModalCoverageTRC}
          />
        </MPopup>
      )}
    </>
  );
};

export default CartStep1;
