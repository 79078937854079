import {
  Assured,
  AssuredPA,
  AssuredTRC,
  Coverage,
  InsurancePlan,
  Prima,
  TipoObra,
  TipoRiesgoObra,
  PeriodoMantencionObra,
  DataWork,
} from "./../models/InsuranceModels";
import { createAction } from "@reduxjs/toolkit";

export const loadInsurancePlansRC = createAction(
  "INSURANCE_LOAD_PLANSRC",
  (plansRC: InsurancePlan[]) => {
    return {
      payload: plansRC,
    };
  }
);

export const loadInsurancePlansPA = createAction(
  "INSURANCE_LOAD_PERSONAL_ACCIDENTS_PLANS",
  (plansPA: InsurancePlan[]) => {
    return {
      payload: plansPA,
    };
  }
);

export const loadInsurancePlansTRC = createAction(
  "INSURANCE_LOAD_TRC_PLANS",
  (plansTRC: InsurancePlan[]) => {
    return {
      payload: plansTRC,
    };
  }
);

export const loadAssured = createAction(
  "INSURANCE_LOAD_ASSURED",
  (assured: Assured) => {
    return {
      payload: assured,
    };
  }
);

export const loadAssuredTRC = createAction(
  "INSURANCE_LOAD_ASSURED_TRC",
  (assuredTrc: AssuredTRC) => {
    return {
      payload: assuredTrc,
    };
  }
);

export const loadWorkInformationTRC = createAction(
   "INSURANCE_LOAD_WORKINFORMATION_TRC",
   (workInformation: DataWork) => {
     return {
       payload: workInformation,
     };
   }
);

export const loadAssuredPA = createAction(
  "INSURANCE_LOAD_ASSURED_PA",
  (assured: AssuredPA) => {
    return {
      payload: assured,
    };
  }
);

export const loadSurePrime = createAction(
  "INSURANCE_LOAD_SUREPRIME",
  (assured: Prima) => {
    return {
      payload: assured,
    };
  }
);

export const loadAllCoverages = createAction(
  "INSURANCE_LOAD_COVERAGES",
  (coverages: Coverage[]) => {
    return {
      payload: coverages,
    };
  }
);

export const loadTiposObrasTRC = createAction(
  "INSURANCE_LOAD_TIPO_OBRA_TRC",
  (tiposObra: TipoObra[]) => {
    return {
      payload: tiposObra,
    };
  }
);
export const loadTiposRiesgosObrasTRC = createAction(
  "INSURANCE_LOAD_TIPO_RIESGO_OBRA_TRC",
  (tiposRiesgoObra: TipoRiesgoObra[]) => {
    return {
      payload: tiposRiesgoObra,
    };
  }
);

export const loadPeriodosMantencionTRC = createAction(
  "INSURANCE_LOAD_PERIODOS_MANTENCION_TRC",
  (periodosMantencion: PeriodoMantencionObra[]) => {
    return {
      payload: periodosMantencion,
    };
  }
);

// export const loadAdditionalWorkInfoTRC = createAction("INSURANCE_LOAD_ADDITIONAL_WORK_INFO", (additionalInfo: AdditionalWorkInfo) => {
//     return {
//         payload: additionalInfo
//     }
// })

export const cleanInsurancePlansPA = createAction(
  "INSURANCE_CLEAN_PERSONAL_ACCIDENTS_PLANS"
);
export const cleanAssured = createAction("INSURANCE_CLEAN_ASSURED");
export const cleanAssuredTRC = createAction("INSURANCE_CLEAN_ASSURED_TRC");
// export const cleanWorkInformationTRC = createAction(
//   "INSURANCE_CLEAN_WORKINFORMATION_TRC"
// );
export const cleanAdditionalWorkInfoTRC = createAction(
  "INSURANCE_CLEAN_ADDITIONAL_INFO_TRC"
);
export const cleanAssuredPA = createAction("INSURANCE_CLEAN_ASSURED");
export const cleanSurePrime = createAction("INSURANCE_CLEAN_SUREPRIME");
