import { roundNumber } from "../../../../../scripts/helpers/helpers";

export type TypeClean = {
    primaAfecta: number,
    primaExenta: number,
    primaNeta: number,
    iva: number,
    primaBruta: number,
}

export type TypeFormat = {
    primaAfecta: string,
    primaExenta: string,
    primaNeta: string,
    iva: string,
    primaBruta: string,
}

export type TypePrima = {
    clean: TypeClean,
    format: TypeFormat
}



export const resumenCotizacionMultiProteccionFinanciera = (
    primaAfecta: number,
    primaExenta: number
) => {
    const primaNeta: number = primaAfecta + primaExenta;
    const iva: number = primaAfecta * 0.19;
    const primaBruta: number = primaNeta + iva;

    const clean: TypeClean = {
        primaAfecta: primaAfecta,
        primaExenta: primaExenta,
        primaNeta,
        iva,
        primaBruta,
    };

    const cleanRound: TypeClean = {
        primaAfecta: roundNumber(primaAfecta, 2),
        primaExenta: roundNumber(primaExenta, 2),
        primaNeta: roundNumber(primaAfecta + primaExenta, 2),
        iva: roundNumber(primaAfecta * 0.19, 2),
        primaBruta: roundNumber(primaNeta + iva, 2),
    };

    const format: TypeFormat = {
        primaAfecta: parseFloat(cleanRound.primaAfecta.toString()).toFixed(2).replace(".", ","),
        primaExenta: parseFloat(cleanRound.primaExenta.toString()).toFixed(2).replace(".", ","),
        primaNeta: parseFloat(cleanRound.primaNeta.toString()).toFixed(2).replace(".", ","),
        iva: parseFloat(cleanRound.iva.toString()).toFixed(2).replace(".", ","),
        primaBruta: parseFloat(cleanRound.primaBruta.toString()).toFixed(2).replace(".", ","),
    };


    return {
        clean,
        format: format,
    };
};
