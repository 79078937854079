import { Region } from "@redux/models/GlobalModels";
import { useEffect, useState } from "react";
import {
  MButton,
  MInputEmail,
  MInputGeneric,
  MInputNumber,
  MInputRadioButtons,
  MInputRut,
  MInputSelect,
} from "@inversiones-ma/finfast-react-controls";
import { Commune } from "@redux/models/GlobalModels";
import * as helpers from "@helpers/helpers";
import * as helpers2 from "@helpers/helpers2";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { changeStep } from "@redux/actions/GlobalActions";
import { loadAssuredPA } from "@redux/actions/InsuranceActions";
import { AssuredPA } from "@redux/models/InsuranceModels";
import PersonForm from "../../PartialForm/PersonForm";
import CompanyForm from "../../PartialForm/CompanyForm";
import AddressForm from "../../PartialForm/AddressForm";
import AdditionalInformation from "../../PartialForm/AdditionalInformation";
import { isEmpty } from "lodash";

type StepTwoProps = {
  handleChangeStep: Function;
  regions: Region[] | undefined;
  setAddAdicionalBenef: React.Dispatch<React.SetStateAction<boolean>>;
  addAdicionalBenef: boolean;
  employees: number;
  idPlan: number;
  medicalExpenses: boolean;
};

export const StepTwo = ({
  handleChangeStep,
  employees,
  idPlan,
  medicalExpenses,
}: StepTwoProps) => {
  const dispatch = useAppDispatch();
  const { run, name, email, isNaturalPerson } = useAppSelector(
    (store) => store.account
  );
  const { active } = useAppSelector((store) => store.global.stepper);
  const { assured } = useAppSelector((store) => store.insurancePA);

  let nombreApellidos;
  let valuesdefault = {};
  if (isNaturalPerson) {
    nombreApellidos = helpers2.nombreApellido(name!);

    valuesdefault = {
      ...assured,
      rutAsegurado: run,
      nombresAsegurado: nombreApellidos.nombres,
      apellidoPaternoAsegurado: nombreApellidos.primerApellido,
      apellidoMaternoAsegurado: nombreApellidos.segundoApellido,
      //tieneBeneficiarioAdicional: assured.tieneBeneficiarioAdicional?.toString() === "true" ? true : false
    };
  } else {
    valuesdefault = {
      ...assured,
      rutAsegurado: run,
      razonSocialAsegurado: name,
      //tieneBeneficiarioAdicional: assured.tieneBeneficiarioAdicional?.toString() === "true" ? true : false
    };
  }

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: valuesdefault,
  });

  const { handleSubmit } = form;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const onSubmit = (data: AssuredPA) => {
    const newAssured: AssuredPA = {
      ...data,
      cantidadTrabajadores: employees,
      idPlanPropuesta: idPlan,
      rgm: medicalExpenses,
      mailAsegurado: isEmpty(data.mailAsegurado) ? email! : data?.mailAsegurado,
    };
    
    dispatch(loadAssuredPA(newAssured));
    if (active === 2) {
      dispatch(changeStep(3));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="section-insurance">
        <h2 className="titles-center fs-24">Ingresa los datos del asegurado</h2>
        <p className="text-center pb-4">
          Completa el formulario con los datos de la empresa que contrata.
        </p>
        <div className="row pb-3">
          {isNaturalPerson ? (
            <PersonForm form={form} />
          ) : (
            <CompanyForm form={form} />
          )}
        </div>

        <AddressForm form={form} />
        <div className="dashed-separator mb-4"></div>

        <AdditionalInformation form={form} />

        <hr />
        {/* Botones del formulario */}
        <div className="row pb-2">
          <div className="col-lg-3 col-md-4 order-3 order-md-1">
            <MButton
              className="btn btn-primary sistema outline w-100"
              type="button"
              onClick={() => handleChangeStep(1)}
            >
              <i className="mx-2 fa fa-arrow-left"></i>
              Volver
            </MButton>
          </div>
          <div className="col-lg-6 col-md-4 order-2"></div>
          <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
            <MButton className="btn btn-primary sistema  w-100" type="submit">
              Continuar
              <i className="mx-2 fa fa-arrow-right"></i>
            </MButton>
          </div>
        </div>
      </div>
    </form>
  );
};
