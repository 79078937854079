import {
  loadInsurancePlansRC,
  loadAssured,
  cleanAssured,
} from "./../actions/InsuranceActions";
import { InsuranceRCState } from "@redux/models/InsuranceModels";
import { createSlice } from "@reduxjs/toolkit";

const inicialState: InsuranceRCState = {
  plans: [],
  assured: {
    calleAsegurado: "",
    calleBeneficiarioAdicional: "",
    telefonoAsegurado: "",
    telefonoBeneficiarioAdicional: "0",
    comunaAsegurado: "",
    comunaBeneficiarioAdicional: "",
    mailAsegurado: "",
    mailBeneficiarioAdicional: "",
    numeroAsegurado: 0,
    numeroBeneficiarioAdicional: 0,
    razonSocialAsegurado: "",
    nombresAsegurado: "",
    apellidoPaternoAsegurado: "",
    apellidoMaternoAsegurado: "",
    razonSocialBeneficiarioAdicional: "",
    regionAsegurado: "",
    regionBeneficiarioAdicional: "",
    rutAsegurado: "",
    rutBeneficiarioAdicional: "",
    tieneBeneficiarioAdicional: false,
    nombresBeneficiarioAdicional:"",
    apellidoPaternoBeneficiarioAdicional: "",
    apellidoMaternoBeneficiarioAdicional: "",
    tipoBeneficiarioAdicional: ""
  },
};

const slice = createSlice({
  name: "insuranceRC",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadInsurancePlansRC, (state, action) => {
        state.plans = action.payload;
      })
      .addCase(loadAssured, (state, action) => {
        state.assured = action.payload;
      })
      .addCase(cleanAssured, (state) => {
        state.assured = inicialState.assured;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
