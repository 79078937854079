import React, { useState } from "react";
import {
  MButton,
  MCards,
  MInputGeneric,
  MInputRut,
  MTitle,
  ValidationFeedback,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import ValidationResult from "./partial/ValidationResult";
import "./DownloadPolicy.scss";
import ApiPolicy from "@api/policy/policy";
import { numeroPolizaFormat } from "../../../../scripts/js/formatters";

function DownloadPolicy() {
  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  function updateNumeroPoliza() {
    let numeroPoliza = numeroPolizaFormat(item as any);
    numeroPoliza = numeroPoliza === "-" ? "" : numeroPoliza;
    setValue("item", numeroPoliza, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  const { handleSubmit, watch, setValue } = form;

  const [validationResult, setValidationResult] = useState<boolean>(false);
  const [poliza, setPoliza] = useState<dataPolicy>();
  const [dataPoliza, setDataPoliza] = useState<policy>();

  const [inRequest, setInRequest] = useState<boolean>(false);
  const [inRequestDownload, setInRequestDownload] = useState<boolean>(false);

  const [polizaSuccess, setPolizaSuccess] = useState<boolean>(false);

  const { item } = watch();

  async function onSubmit(data: policy) {
    setInRequest(true);
    await ApiPolicy.SearchPolicy(data)
      .then((res) => {
        if (res.success) {
          setPoliza(res.data);
          setValidationResult(true);
          //set values to download
          setDataPoliza(data);
          setPolizaSuccess(false);
        } else {
          setValidationResult(false);
          setPolizaSuccess(true);
        }
      })
      .finally(() => {
        setInRequest(false);
      });
  }

  async function DownloadPolicy() {
    setInRequestDownload(true);
    await ApiPolicy.DownloadPolicy(dataPoliza as policy)
      .then((response) => {
        return new Blob([response]);
      })
      .then((blob) => {
        if (blob.type === "text/plain") {
          return;
        }
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Poliza.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        setInRequestDownload(false);
      });
  }

  function resetValidation() {
    setValidationResult(false);
    setValue("item", "");
    setValue("Rut", "");
  }

  return (
    <div className="mx-5">
      <div className="px-3 py-2 pt-5">
        <MCards
          className="py-5 px-1 "
          color="successHome"
          onClick={() => {}}
          size="default"
        >
          <MTitle hr position="center">
            Descargar PóLIZA
          </MTitle>
          <p className="text-center">
            Acá podrás descargar tus pólizas comprada con nosotros{" "}
          </p>
          <div className="text-center pt-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <span className="subtitle-mini mb-0 ">
                Ingresa los datos de tu póliza
              </span>
              <div className="m-5">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <MInputRut
                      form={form}
                      name={"Rut"}
                      label={"RUT"}
                      rules={{
                        required: "Formato de Rut inválido",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <MInputGeneric
                      form={form}
                      name={"item"}
                      label={"Número de póliza"}
                      onChange={() => updateNumeroPoliza}
                      rules={{
                        required:
                          "El formato de número de póliza , es incorrecto. Ejemplo: 9007027-1",
                      }}
                    />
                    {polizaSuccess && (
                      <ValidationFeedback className="errorPolicy">
                        {
                          "El número de póliza no existe , verifica si el número es correcto y vuelve a intentarlo. "
                        }
                      </ValidationFeedback>
                    )}
                  </div>
                  <div className="col-12 col-md-4">
                    <MButton
                      type="submit"
                      className="btn btn-primary sistema w-100 mt-3"
                      loading={inRequest}
                    >
                      Buscar póliza
                    </MButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="text-center val">
            {validationResult && (
              <span className="subtitle-mini mb-0 text-center">
                Resultados de validación
              </span>
            )}
          </div>
        </MCards>
        {validationResult && (
          <ValidationResult
            dataPolicy={poliza as dataPolicy}
            resetValidation={resetValidation}
            inRequestDownload={inRequestDownload}
            DownloadPolicy={DownloadPolicy}
          />
        )}
      </div>
    </div>
  );
}

export default DownloadPolicy;

interface policy {
  Rut: string;
  item: string;
}

interface Asegurados {
  nombre: string;
  principal: boolean;
  rut: string;
}

interface Poliza {
  moneda: string;
  nombreArchivo: string;
  prima: number;
  propuesta: string;
  vigencia: Date;
  estadoPoliza: boolean;
}

export interface dataPolicy {
  asegurados: Asegurados[];
  poliza: Poliza;
  vigencia: string;
}
