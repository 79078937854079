import React, { useState } from "react";
import { useAppSelector } from "@redux/hooks";
import iconTbk from "@img/transbank.png";
import iconRenta from "@img/logo-renta-nacional.png";
import { convertToPeso } from "@helpers/helpers";
import {
  MButton,
  MInputCheckBox,
  MMInputRadio,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import ApiTbk from "@api/tbk/Tbk";
import {
  Insurance,
  ResultCreateTransaction,
} from "@redux/models/InsuranceModels";
import GenericBaseModel from "@api/GenericBaseModel";
import { useHistory } from "react-router-dom";

const Pay = () => {
  const { cart } = useAppSelector((store) => store.session);
  const [toPay, setToPay] = useState<boolean>(false);

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = form;
  const history = useHistory();

  const primaAfectaTotal =
    cart?.item.listInsuranceRC?.reduce(
      (x, a) => x + a.prima?.primaAfectaCLP!,
      0
    ) +
      cart?.item.listInsurancePF?.reduce(
        (x, a) => x + a.prima?.primaAfectaCLP!,
        0
      ) +
      cart?.item.listInsuranceTRC?.reduce(
        (x, a) => x + a.prima?.primaAfectaCLP!,
        0
      ) ?? 0;

  const primaExentaTotal =
    cart?.item.listInsuranceRC?.reduce(
      (x, a) => x + a.prima?.primaExentaCLP!,
      0
    ) +
      cart?.item.listInsurancePF?.reduce(
        (x, a) => x + a.prima?.primaExentaCLP!,
        0
      ) +
      cart?.item.listInsuranceTRC?.reduce(
        (x, a) => x + a.prima?.primaExentaCLP!,
        0
      ) ?? 0;

  const primaNetaTotal =
    cart?.item.listInsuranceRC?.reduce(
      (x, a) => x + a.prima?.primaNetaCLP!,
      0
    ) +
      cart?.item.listInsurancePF?.reduce(
        (x, a) => x + a.prima?.primaNetaCLP!,
        0
      ) +
      cart?.item.listInsuranceTRC?.reduce(
        (x, a) => x + a.prima?.primaNetaCLP!,
        0
      ) ?? 0;

  const ivaTotal =
    cart?.item.listInsuranceRC?.reduce((x, a) => x + a.prima?.ivaCLP!, 0) +
      cart?.item.listInsurancePF?.reduce((x, a) => x + a.prima?.ivaCLP!, 0) +
      cart?.item.listInsuranceTRC?.reduce((x, a) => x + a.prima?.ivaCLP!, 0) ??
    0;

  const primaBrutaTotal =
    cart?.item.listInsuranceRC?.reduce(
      (x, a) => x + a.prima?.primaBrutaCLP!,
      0
    ) +
      cart?.item.listInsurancePF?.reduce(
        (x, a) => x + a.prima?.primaBrutaCLP!,
        0
      ) +
      cart?.item.listInsuranceTRC?.reduce(
        (x, a) => x + a.prima?.primaBrutaCLP!,
        0
      ) ?? 0;

  const totalPay = primaBrutaTotal;

  const onSubmit = async (data: any) => {
    setToPay(true);

    await ApiTbk.PayTbk(cart?.item as Insurance)
      .then((resp: GenericBaseModel<ResultCreateTransaction>) => {
        if (resp !== null && resp.success) {
          const form = document.createElement("form");
          form.method = "post";
          form.action = resp.data.paymentMetadata.url;

          const hiddenField = document.createElement("input");

          hiddenField.type = "hidden";
          hiddenField.id = "token_ws";
          hiddenField.name = "token_ws";
          hiddenField.value = resp.data.paymentMetadata.token;

          form.appendChild(hiddenField);

          document.body.appendChild(form);

          form.submit();
        }
      })
      .catch((err) => console.error("Ha ocurrido un error:" + err.message))
      .finally(() => setToPay(false));
  };

  function handleredirect(url: string) {
    history.push(url);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12">
          <h4 className="subtitle-mini text-center mb-4">
            Resumen de tu pedido
          </h4>

          <div className="section-insurance lborder-info p-2 operation-detail-card">
            <div className="row mx-auto gx-0">
              <div className="col-6">Prima Afecta Total</div>
              <div className="col-6 text-end">
                {convertToPeso(primaAfectaTotal)}
              </div>
              <div className="col-6">Prima Exenta Total</div>
              <div className="col-6 text-end">
                {convertToPeso(primaExentaTotal ?? 0)}
              </div>
              {primaNetaTotal !== 0 && (
                <>
                  <hr className="my-2" />

                  <div className="col-6 fw-bold">Prima Neta Total</div>
                  <div className="col-6 text-end">
                    {convertToPeso(primaNetaTotal)}
                  </div>
                </>
              )}
              <hr className="my-2" />
              <div className="col-6 fw-bold">IVA (19%)</div>
              <div className="col-6 text-end">{convertToPeso(ivaTotal)}</div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="section-insurance lborder-success p-2 ">
            <div className="row mx-auto gx-0">
              <div className="col-6 fs-18 fw-bold">Total a pagar</div>
              <div className="col-6 text-end fs-18 fw-bold color-secondary">
                {convertToPeso(totalPay)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="section-insurance">
            <div className="row">
              <div className="col-6 my-auto">
                <p className="youarein">Estás contratando con:</p>
              </div>
              <div className="col-6 text-end">
                <img
                  src={iconRenta}
                  alt="Logo Renta Nacional"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <h4 className="subtitle-mini text-center mb-4">Medios de pago</h4>
          <div className="row">
            <div className="col-6 my-auto">
              <div className="form-check">
                <MInputCheckBox
                  className="form-check-input"
                  form={form}
                  name="checkToPay"
                  rules={{
                    required: "Este campo es requerido",
                  }}
                  label="Transbank"
                  disabled={
                    !cart.item.listInsuranceRC.length &&
                    !cart.item.listInsuranceTRC.length &&
                    !cart.item.listInsurancePF.length
                  }
                />
              </div>
            </div>
            <div className="col-6 text-end">
              <img src={iconTbk} alt="Transbank" className="img-fluid" />
            </div>
          </div>

          <div
            className="alert alert-info d-flex align-items-start"
            role="alert"
          >
            <div className="icon">
              <i className="fas fa-info-circle"></i>
            </div>
            <div>
              <h5>Importante</h5>
              <p>
                Una vez realizado el pago, la generación de tu póliza podría
                demorarse hasta un <strong>máximo de 48 horas.</strong>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="row pb-4">
            <div className="col-12 col-lg-8 mx-auto">
              <MButton
                className="w-100"
                type="submit"
                loading={toPay}
                disabled={
                  !cart.item.listInsuranceRC.length &&
                  !cart.item.listInsuranceTRC.length &&
                  !cart.item.listInsurancePF.length
                }
              >
                Pagar
              </MButton>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <MButton
                onClick={() => handleredirect("/home")}
                className="w-100"
                outline
                type="button"
              >
                Seguir comprando
              </MButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Pay;
