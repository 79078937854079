import { InjectTokenHeader, MAxios } from "../conf";

const GetRegionProvinciaComuna = async () => {
  const endpoint = "RegionComuna/ListRegionProvincia";

  return await MAxios()
    .get(endpoint)
    .then((res) => res.data);
};

const GetRegionComuna = async () => {
  const endpoint = "RegionComuna/ListRegion";

  return await MAxios()
    .get(endpoint)
    .then((res) => res.data);
};

const ApiRegionProvinciaComuna = {
  GetRegionProvinciaComuna,
  GetRegionComuna,
};

export default ApiRegionProvinciaComuna;
