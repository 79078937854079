import { useAppDispatch, useAppSelector } from "@redux/hooks";
import React, { SetStateAction, useEffect } from "react";
import {
  MButton,
  MInputEmail,
  MInputNumber,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import { Assured } from "@redux/models/InsuranceModels";
import { loadAssured } from "@redux/actions/InsuranceActions";
import PersonForm from "../../PartialForm/PersonForm";
import CompanyForm from "../../PartialForm/CompanyForm";
import AdditionalInformation from "../../PartialForm/AdditionalInformation";
import AddressForm from "../../PartialForm/AddressForm";
import { nombreApellido } from "@helpers/helpers2";
import { isEmpty } from "lodash";

interface Props {
  setStep: any;
  addAdicionalBenef: boolean;
  typeBenefAdicional: string;
  setAddAdicionalBenef: React.Dispatch<SetStateAction<boolean>>;
  setTypeBenefAdicional: React.Dispatch<SetStateAction<string>>;
}

const RCDataAssured = (props: Props) => {
  const dispatch = useAppDispatch();
  const { run, name, email, isNaturalPerson } = useAppSelector(
    (store) => store.account
  );

  const { assured } = useAppSelector((store) => store.insuranceRC);

  const backStep = () => {
    props.setStep(1);
  };

  let nombreApellidos;
  let valuesdefault = {};
  if (isNaturalPerson) {
    nombreApellidos = nombreApellido(name!);

    valuesdefault = {
      ...assured,
      rutAsegurado: run,
      nombresAsegurado: nombreApellidos.nombres,
      apellidoPaternoAsegurado: nombreApellidos.primerApellido,
      apellidoMaternoAsegurado: nombreApellidos.segundoApellido,
    };
  } else {
    valuesdefault = {
      ...assured,
      rutAsegurado: run,
      razonSocialAsegurado: name,
    };
  }

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: valuesdefault,
  });

  const { handleSubmit } = form;

  const onSubmit = (data: Assured) => {
    const dataForm: Assured = {
      ...data,
      mailAsegurado: isEmpty(data.mailAsegurado) ? email! : data?.mailAsegurado,
    };
    dispatch(loadAssured(dataForm));
    props.setStep(3);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="section-insurance">
        <h2 className="titles-center fs-24">Ingresa los datos del asegurado</h2>
        <p className="text-center pb-4">
          Completa el formulario con los datos de la empresa que contrata.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="subtitle-mini">
            Información del asegurado / contratante
          </h3>
          <div className="row pb-3">
            {isNaturalPerson ? (
              <PersonForm form={form} />
            ) : (
              <CompanyForm form={form} />
            )}
          </div>
          <AddressForm form={form} />
          <div className="dashed-separator mb-4"></div>

          <AdditionalInformation form={form} />
          <hr />
          <div className="row pb-2">
            <div className="col-lg-3 col-md-4 order-3 order-md-1">
              <MButton
                type="button"
                onClick={() => backStep()}
                className="btn btn-primary sistema outline w-100"
              >
                <i className="fa fa-arrow-left"></i> Volver
              </MButton>
            </div>
            <div className="col-lg-6 col-md-4 order-2"></div>
            <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
              <MButton type="submit" className="btn btn-primary sistema w-100">
                Continuar <i className="fa fa-arrow-right"></i>
              </MButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RCDataAssured;
