import { rutValidate } from "rutfunctions";
import { getFileExtension, toDate } from "./helpers";

// react-hook-form object validators

//MinLenght

export const minLengthGeneral = (value) => ({
  value: value,
  message:
    value < 2
      ? "Debes ingresar al menos un caracter"
      : "Debes ingresar al menos " + value + " caracteres",
});

export const minLengthTelefono = {
  value: 9,
  message: "Debes ingresar un teléfono válido",
};
//MaxLenght
export const maxLengthGeneral = (value) => ({
  value: value,
  message: "Debes ingresar a lo más " + value + " caracteres",
});

export const maxLengthGlosa = {
  value: 500,
  message: "No puedes ingresar más de 500 caracteres",
};
export const maxLengthNumeroDireccion = {
  value: 6,
  message: "Debes ingresar un número de dirección válido",
};
//max
export const maxMontoCertificado = {
  value: 999999999,
  message: "El monto del certificado es demasiado alto",
};
//Regex
export const patternAlphaSpace = {
  value: /^[A-Za-záÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternAlphaNumSpace = {
  value: /^[A-Za-z0-9áÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternText = {
  value: /^[A-Za-z0-9-#°.,:;áÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternNum = {
  value: /^[0-9]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternAddress = {
  value: /^[A-Za-z0-9-#°.,áÁéÉíÍóÓúÚñÑ\s]+$/,
  message: "Estás intentando ingresar caracteres no permitidos",
};
export const patternRut = {
  value: /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/i,
  message: "Formato de Rut inválido",
};
export const patternNumeroSeparadorMilesComas = {
  value: /^\d{3}(.\d{3})*(,\d+)?$/,
  message: "Formato número inválido",
};
export const patternEmail = {
  value:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Formato de correo inválido",
};
//Values
export const validateTrim = (value) =>
  value.length === 0 ||
  /^\S/.test(value) ||
  "No puedes comenzar con espacios vacíos";
export const validateRut = (value) => rutValidate(value) || "El rut no existe";
export const validateSelect = (value) =>
  (value !== -1 && value !== "-1") || "Debes seleccionar una alternativa";
export const validateHiddenArray = (value) =>
  (value !== "" && value !== "[]") ||
  "Debes seleccionar al menos una alternativa";
export const validateDateForward = (value) =>
  new Date(`${value.year}-${value.month}-${value.day}`) > new Date() ||
  "Rango de fecha no permitido";
export const validateDateBack = (value) =>
  new Date(`${value.year}-${value.month}-${value.day}`) < new Date() ||
  "Rango de fecha no permitido";

export const validateFileType = (
  value,
  fileType,
  message = "Tipo de archivo no permitido"
) => {
  const fileList = [...value];

  const Types = fileType.split(",");
  let validate = true;
  fileList.forEach((file) => {
    validate &= Types.includes(file.type);
  });
  return validate || message;
};

export const validateFileExtension = (
  value,
  extension,
  message = "Tipo de archivo no permitido"
) => {
  const fileList = [...value];

  const extensions = extension.split(",");
  let validate = true;
  fileList.forEach((file) => {
    validate &= extensions.includes(getFileExtension(file));
  });
  return validate || message;
};

export const validateFileSize = (value, fileSize) => {
  const fileList = [...value];
  let validate = true;
  fileList.forEach((file) => {
    validate &= fileSize >= file.size;
  });
  return validate || "Peso de archivo supera el máximo permitido";
};

export const validateDateEquealOrForward = (value) => {
  const inDate = new Date(value);
  const nowDate = new Date();
  return (
    inDate >= nowDate ||
    toDate(inDate) === toDate(nowDate) ||
    "Rango de fecha no permitido"
  );
};

export const validateDateEqualOrBack = (value) => {
  const inDate = new Date(value);
  const nowDate = new Date();
  return (
    inDate <= nowDate ||
    toDate(inDate) === toDate(nowDate) ||
    "Rango de fecha no permitido"
  );
};

export const validateDateOverEighteen = (value) => {
  const inDate = new Date(value);
  const nowDate = new Date();
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth();
  const day = nowDate.getDate();
  const maxDate = new Date(year - 18, month, day);
  return (
    inDate <= maxDate ||
    toDate(inDate) === toDate(maxDate) ||
    "Debe ser mayor de edad"
  );
};

export const validateMinMax = ({minimo, maximo, unidad = ""}) => {
  let respuesta = {};
  if (!!minimo) {
    let min = (v) =>
    !!!v ||parseFloat(v) >= minimo || `Debe ser mayor o igual a ${minimo} ${unidad}`.trim();
    respuesta = { ...respuesta, min };
  }

  if (!!maximo) {
    let max = (v) =>
    !!!v || parseFloat(v) <= maximo || `Debe ser menor o igual a ${maximo} ${unidad}`.trim();
    respuesta = { ...respuesta, max };
  }
  return respuesta;
};

export const validateMinMaxBetween = ({required, min, max, unit = ""}) => {
  return {
    required: { value: required, message: "Este campo es requerido" },    
    validate: {
      minimo: value => {
        if (!!!min || !!!value) return true;
        const message = `Debe ser mayor o igual a ${min} ${unit}`
        return (parseFloat(value)>= (min) || message);
      },
      maximo: value => {
        if (!!!max || !!!value) return true;
        const message = `Debe ser menor o igual a ${max} ${unit}`
        return (
          parseFloat(value) <= (max) || message);
      },
    },
  };

  // let respuesta = {};
  // if (!!minimo) {
  //   let min = (v) =>
  //     parseFloat(v) >= minimo || `Debe ser mayor o igual a ${minimo} ${unidad}`.trim();
  //   respuesta = { ...respuesta, min };
  // }

  // if (!!maximo) {
  //   let max = (v) =>
  //     parseFloat(v) <= maximo || `Debe ser menor o igual a ${maximo} ${unidad}`.trim();
  //   respuesta = { ...respuesta, max };
  // }
  // return respuesta;
};

// Async validates

// Validaciones conjuntas
export const vRut = (
  requireText = "Este campo es requerido",
  required = true
) => ({
  required: required ? requireText : false,
  pattern: patternRut,
  validate: validateRut,
});

export const vNombres = (
  requiredText = null,
  maxLength = 70,
  minLength = 3
) => ({
  required: requiredText ?? false,
  pattern: patternAlphaSpace,
  maxLength: maxLengthGeneral(maxLength),
  minLength: minLengthGeneral(minLength),
  validate: validateTrim,
});

export const vEmail = (requiredText = null, maxLength = 100) => ({
  required: requiredText ?? false,
  pattern: patternEmail,
  maxLength: maxLengthGeneral(maxLength),
});

export const vNewPasswordRepeat = (compareString, requiredText = null) => ({
  required: requiredText ?? false,
  validate: (value) =>
    value === compareString ||
    value?.length === 0 ||
    "Contraseñas deben coincidir",
});

export const vDireccion = (
  requiredText = null,
  maxLength = 80,
  minLength = 4
) => ({
  required: requiredText ?? false,
  pattern: patternAddress,
  maxLength: maxLengthGeneral(maxLength),
  minLength: minLengthGeneral(minLength),
  validate: validateTrim,
});

export const vTelefono = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  pattern: patternNum,
  minLength: minLengthTelefono,
});

export const vNumeroDireccion = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  pattern: patternNum,
  maxLength: maxLengthNumeroDireccion,
});

export const vGlosa = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  maxLength: maxLengthGlosa,
});

export const vDateEqualOrForward = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  validate: validateDateEquealOrForward,
});

export const vDateEqualOrBack = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  validate: validateDateEqualOrBack,
});

export const vDateFechaNacimiento = (required = true) => ({
  required: required ? "Este campo es requerido" : false,
  validate: validateDateOverEighteen,
});

export const vStrcDistanciaAgua = ({
  required = false,
  unit = { multiplier: 1, description: "" },
  min = 300,
  max = null,
  maxLength = 5,
}) => {
  return {
    required: { value: required, message: "Este campo es requerido" },
    maxLength: {
      value: maxLength,
      message: `Máximo ${maxLength} caracteres`,
    },
    validate: {
      minimo: value => {
        if (!!!min || !!!value) return true;
        const message = `Debe ser mayor o igual a ${min} Mts`
        return (parseFloat(value)>= (min / unit.multiplier) || message);
      },
      maximo: value => {
        if (!!!max || !!!value) return true;
        const message = `Debe ser menor o igual a ${max} Mts`
        return (
          parseFloat(value) <= (max / unit.multiplier) || message);
      },
    },
  };
};
