import React, { useEffect } from "react";
import { finfast } from "../../../../scripts/config/environment";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import * as GlobalActions from "@redux/actions/GlobalActions";
import * as SessionActions from "@redux/actions/SessionActions";
import * as AccountActions from "@redux/actions/AccountActions";
import MLoading from "@controls/MLoading";
import ApiMembresiaAccount from "@api/membresia/account";

const Logout = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((store) => store.account);

  useEffect(() => {
    ApiMembresiaAccount.KillToken(token!);
    sessionStorage.clear();
    localStorage.clear();
    dispatch(GlobalActions.cleanState());
    dispatch(SessionActions.cleanState());    
    dispatch(AccountActions.cleanState());

    window.location.href = finfast() + "/logout";
    // eslint-disable-next-line
  }, []);

  return (
    <div className=" d-flex justify-content-center mt-5">
      <MLoading />
    </div>
  );
};

export default Logout;
