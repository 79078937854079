import { TypeInsurance } from "@redux/models/TypeInsuranceModels";
import { CardInsurance } from "./";

export interface InsurancesProps {
  insurances: TypeInsurance[];
}

export const Insurances = ({ insurances }: InsurancesProps) => {
  return (
    <div className="row pt-3">
      {insurances.map((i: TypeInsurance) => (
        <div className="col-12 col-md-6 col-lg-4">
          <CardInsurance
            insurance={i}
            tipoSeguroTRC={{
              descripcion: i.descripcion,
              id: i.idOpcionPropuesta,
            }}
          />
        </div>
      ))}
    </div>
  );
};
