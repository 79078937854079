import filerc from "../../../../../../../img/fichas-ayuda-seguro/ficha_seguro_rc.png";
import ficha_seguro_proteccion_financiera from "../../../../../../../img/fichas-ayuda-seguro/ficha_seguro_proteccion_financiera.png";
import ficha_seguro_todo_riesgo_rc from "../../../../../../../img/fichas-ayuda-seguro/ficha_seguro_todo_riesgo_rc.png";
import ficha_seguro_tc from "../../../../../../../img/fichas-ayuda-seguro/ficha_seguro_tc.png";
import ficha_seguro_rc_construccion from "../../../../../../../img/fichas-ayuda-seguro/ficha_seguro_rc_construccion.png";

import { MPopup } from "@inversiones-ma/finfast-react-controls";

interface Images {
  [key: number]: string;
}

const insuranceImages: Images = {
  1: filerc,
  7: ficha_seguro_proteccion_financiera,
  35: ficha_seguro_todo_riesgo_rc,
  38: ficha_seguro_tc,
  40: ficha_seguro_rc_construccion
};

type TModalFileHelpInsuranceProps = {
  modalOpen: boolean;
  idOpcionPropuesta: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalFileHelpInsurance = ({
  modalOpen,
  setModalOpen,
  idOpcionPropuesta,
}: TModalFileHelpInsuranceProps) => {
  const selectedImage =
    insuranceImages[idOpcionPropuesta] || "default-image-path";
    
  return (
    <MPopup showModal={modalOpen} setShowModal={setModalOpen} size="xl">
      <img src={selectedImage} className="w-100" />
    </MPopup>
  );
};
