import React from "react";
import "./navbar-container.css";
import Client from "./Client";
import Logout from "./Logout";
import Contact from "./Contact";
import { useAppSelector } from "../../../../scripts/redux/hooks";
import { capitalizeTheFirstLetterOfEachWord } from "@helpers/helpers";

const MenuClient = () => {
  const { name } = useAppSelector((store) => store.account);
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-grisf7 p-0 d-none d-lg-block">
      <div className="container menu-transversal">
        <div className="menu-login-name">
          <p className="p-0 m-0">
            Iniciaste sesión como:{" "}
            <span className="fw-bold">
              {capitalizeTheFirstLetterOfEachWord(name)}
            </span>
          </p>
        </div>

        <div
          className="navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav navbar-right">
            <li className="nav-item dropdown menu-home-pr">
              <Contact />
            </li>
            <li className="nav-item dropdown menu-home-pr">
              <Client />
            </li>
            <li className="nav-item menu-home-pr">
              <Logout />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MenuClient;
