import { useAppSelector } from "@redux/hooks";
import React from "react";
//import iconTriangulo from "../../../../img/icon-triangulo-nav-home.svg";
import { capitalizeTheFirstLetterOfEachWord } from "@helpers/helpers";

interface PropsContact {
  isMobile?: boolean;
}
const Contact = (props: PropsContact = { isMobile: false }) => {
  const { executive } = useAppSelector((store) => store.account);

  if (props.isMobile) {
    return (
      <div className="lborder-primary px-3 mt-3">
        <p className="fw-bold">Contacto Ejecutivo</p>
        <p>
          <i className="fas fa-headset me-2"></i>
          {capitalizeTheFirstLetterOfEachWord(executive?.name)}
          <br />
          <i className="fa fa-phone me-2"></i>
          <a href={"tel:" + "executive?.phone"} className="text-link">
            {executive?.phone || "-"}
          </a>
          <br />
          <i className="fa fa-envelope me-2"></i>
          <a href={"mailto:" + executive?.email} className="text-link">
            {executive?.email}
          </a>
        </p>
      </div>
    );
  }

  return (
    <>
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Contacto <i className="fas fa-headset ms-2"></i>
      </a>
      <ul
        className="dropdown-menu navegacion-home-desplegable"
        aria-labelledby="navbarDropdownMenuLink"
      >
        {/* <img src={iconTriangulo} alt="triangulo" className="triangulo-nav" /> */}
        <li>
          <a className="dropdown-item" href="#.">
            <i className="fas fa-headset me-2"></i>
            {capitalizeTheFirstLetterOfEachWord(executive?.name)}
          </a>
        </li>
        <li>
          <a className="dropdown-item" href={"tel:" + executive?.phone}>
            <i className="fa fa-phone me-2"></i>
            <span className="text-link">{executive?.phone || "-"}</span>
          </a>
        </li>
        <li>
          <a className="dropdown-item" href={"mailto:" + executive?.email}>
            <i className="fa fa-envelope me-2"></i>
            <span className="text-link">{executive?.email}</span>
          </a>
        </li>
      </ul>
    </>
  );
};

export default Contact;
