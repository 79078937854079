import { useAppSelector } from "@redux/hooks";
import iconInsuranceRc from "@img/rc.png";
import { convertToPeso } from "@helpers/helpers";

interface Props {
  handlerLinkShowCoverageOnClick: (
    id: number | undefined,
    primaBruta: number | undefined,
    primaNeta: number | undefined
  ) => void;
  handlerDeleteOnCart: (id: number | null, idTipoInsurance: number) => void;
  deleteCart: boolean;
  id: number;
}
const ItemsRC = (props: Props) => {
  const { cart } = useAppSelector((store) => store.session);

  return (
    <>
      {cart?.item.listInsuranceRC.length > 0 &&
        cart?.item.listInsuranceRC?.map((x, index) => {
          return (
            <div
              key={index}
              className="section-insurance insurance-resume-cart mb-4"
            >
              <div className="row">
                <div className="col-12 col-lg-2 my-lg-auto text-center text-lg-start mb-3">
                  <img
                    src={iconInsuranceRc}
                    className="icon-insurance"
                    alt="Icono Seguro"
                  />
                </div>

                <div className="col-12 col-lg-7 text-center text-lg-start">
                  <h4>{x.poliza.descripcionTipoSeguro}</h4>
                  <p className="details-plan m-0">
                    <span></span>
                    {/* <span>{x.poliza.descripcionOpcionPropuesta}</span> */}
                  </p>
                  <p className="price m-0">
                    UF {x.poliza?.montoPrimaOpcionPropuesta} anual + IVA{" "}
                    <span>({convertToPeso(x.prima?.primaBrutaCLP)})</span>
                  </p>
                  <span
                    className="text-link fs-12"
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      props.handlerLinkShowCoverageOnClick(
                        x.poliza.idOpcionPropuesta,
                        x.poliza.primaBruta,
                        x.poliza.primaAfecta
                      )
                    }
                  >
                    Ver detalle de coberturas
                  </span>
                </div>

                {/* acciones escritorio */}
                <div className="col-3 my-auto d-none d-lg-block">
                  <div className="row">
                    <div className="col-6 ">
                      {/* <a className="text-link">
                      <i className="fa fa-edit fs-24"></i>
                    </a> */}
                    </div>

                    <div className="col-6">
                      <a
                        onClick={() =>
                          props.handlerDeleteOnCart(
                            x.poliza.id,
                            x.poliza.idTipoSeguro
                          )
                        }
                        className="text-link"
                      >
                        {props.deleteCart && props.id === x.poliza.id ? (
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                        ) : (
                          <i className="fa fa-trash fs-24"></i>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row bottom-insurance">
                <div className="col-6 fs-14">
                  <span className="fw-bold">Prima Afecta</span>:{" "}
                  {convertToPeso(x.prima?.primaAfectaCLP)}
                </div>
                <div className="col-6 fs-14 text-end">
                  <span className="fw-bold">Prima Neta</span>:{" "}
                  {convertToPeso(x.prima?.primaNetaCLP)}
                </div>
              </div>

              {/* Actions Mobile */}
              <div className="action-buttons-mobile d-block d-lg-none">
                <div className="row">
                  {/* <div className="col-6">
                  <button className="btn btn-primary outline w-100">
                    Editar <i className="fa fa-edit fs-14"></i>
                  </button>
                </div> */}
                  <div className="col-12">
                    <button
                      className="btn btn-primary outline w-100"
                      onClick={() =>
                        props.handlerDeleteOnCart(
                          x.poliza.id,
                          x.poliza.idTipoSeguro
                        )
                      }
                    >
                      Eliminar{" "}
                      {props.deleteCart && props.id === x.poliza.id ? (
                        <i className="fa fa-spinner fa-pulse fa-fw "></i>
                      ) : (
                        <i className="fa fa-trash fs-14"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ItemsRC;
