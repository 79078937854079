import { MButton, MPopup } from "@inversiones-ma/finfast-react-controls";
import "./Terms.scss";

type TermsProps = {
  showModal: boolean;
  setShowModal: Function;
  showClose: boolean;
};

export const Terms = ({ setShowModal, showClose, showModal }: TermsProps) => {
  return (
    <MPopup
      showModal={showModal}
      setShowModal={setShowModal}
      size={"lg"}
      showClose={showClose}
    >
      <div className="row mx-auto justify-content-center terms">
        <div className="col-12">
          <h4 className="titles-center my-3">Términos y condiciones</h4>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="col-12 mt-4">
                <div className="accordion new-accordion acordion-site-admin">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-heading1">
                      <button
                        className="accordion-button collapsed fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapse1"
                        aria-expanded="false"
                        aria-controls="flush-collapse1"
                      >
                        I. Cobertura
                      </button>
                    </h2>
                    <div
                      id="flush-collapse1"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-heading1"
                      data-bs-parent="#acordion-uaf"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-12 text-dark p-4">
                            <p>
                              <b>
                                Modelo de póliza aprobado según Reg.
                                POL320130695 de la CMF.
                              </b>{" "}
                              Esta entidad asegura mediante la tasa, monto,
                              vigencia, primas e impuestos detallados a
                              continuación y con arreglo a las condiciones
                              generales estipuladas en la póliza y sus anexos
                              aplicables al presente caso y aceptada por ambas
                              partes y a las particulares que se especifican,
                              asegura dentro de los límites de la República de
                              Chile.
                            </p>
                            <p>
                              <b>PÓLIZAS INDIVIDUALES.</b> Queda prohibida la
                              suscripción de pólizas individuales aún, cuando
                              sean por montos inferiores al monto máximo por
                              persona.
                            </p>
                            <p>
                              <b>LÍMITE MÁXIMO DE INDEMNIZACIÓN.</b> Se
                              establece un límite máximo de indemnización
                              equivalente a un 30% del cúmulo total asegurado
                              Plan I, con tope máximo de UF 50.000.- por evento
                              y agregado póliza para todas las coberturas
                              contratadas en su conjunto.
                            </p>
                            <p>
                              <b>CONDICIÓN DE ASEGURABILIDAD.</b> Al momento del
                              siniestro, Contratante deberá demostrar relación
                              contractual con el accidentado.
                            </p>
                            <p>
                              <b>LIMITES DE EDAD.</b> Se pueden asegurar persona
                              con un mínimo de 18 y hasta un máximo de 70 años,
                              para ingreso al seguro, y con una permanencia
                              máxima hasta 75 años.
                            </p>
                            <ul>
                              <li>
                                Personas menores de 18 años no puede ser
                                aseguradas bajo Plan I: Muerte Accidental
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="accordion new-accordion acordion-site-admin">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-heading2">
                    <button
                      className="accordion-button collapsed fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse2"
                      aria-expanded="false"
                      aria-controls="flush-collapse2"
                    >
                      II. Extensiones de Cobertura
                    </button>
                  </h2>
                  <div
                    id="flush-collapse2"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading2"
                    data-bs-parent="#acordion-uaf"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-12 text-dark p-4">
                          <p>
                            Las siguientes extensiones de cobertura se podrán
                            otorgar en atención a la actividad desarrollada por
                            los asegurados, e incorporadas como condición
                            particular.
                          </p>
                          <p>
                            <b>MOVIMIENTOS SÍSMICOS</b>. Sismo sin límite de
                            grados incluye terremoto. Fenómeno de sacudida
                            brusca y pasajera de la corteza terrestre, producida
                            por la liberación de energía acumulada en forma de
                            ondas sísmicas. Las cuales se producen por rupturas
                            de fallas, fricción, procesos volcánicos o incluso
                            producidas por el hombre por medio de detonaciones.
                            La escala de Mercalli se utiliza para evaluar la
                            intensidad de los terremotos según los efectos y
                            daños causados a distintas estructuras.
                          </p>
                          <p>
                            <b>TSUNAMI</b>. Evento que involucra un grupo de
                            olas de gran energía y de tamaño variable, que se
                            producen cuando algún fenómeno desplaza
                            verticalmente una gran masa de agua.
                          </p>
                          <p>
                            <b>MOTINES O TUMULTOS</b>. Las consecuencias
                            derivadas de motines o tumultos, sin perjuicio de lo
                            estipulado respecto de la intervención del asegurado
                            en dichos motines o tumultos, que queda excluida
                            absolutamente del seguro.
                          </p>
                          <p>
                            <b>TRABAJOS EN ALTURA</b>. Bajo el nombre de
                            trabajos en altura se designan a los trabajos que
                            son ejecutados en alturas superiores a establecido
                            para tales efectos por las respectivas Mutualidades,
                            ya sea en andamios, edificios, escaleras,
                            estructuras, máquinas, plataformas, vehículos, etc.,
                            así como a los trabajos en aberturas de tierra,
                            excavaciones, pozos, trabajos verticales, etc. Como
                            criterio general, todos los trabajos en altura sólo
                            podrán efectuarse, en principio, con la ayuda de
                            equipos o dispositivos de protección colectiva,
                            tales como barandilla, plataformas o redes de
                            seguridad. En caso de siniestro, rige lo siguiente
                            para la cobertura de trabajos en altura:
                          </p>
                          <ol>
                            <li>
                              {" "}
                              En caso de accidente, se debe informar a la
                              compañía inmediatamente
                            </li>
                            <li>
                              {" "}
                              En caso de muerte esta debe ser constatada y
                              certificada por las autoridades competentes. Se
                              entiende por autoridad competente la policial,
                              marítima, médica o judicial, en su caso, de la
                              jurisdicción respectiva en que el suceso se haya
                              producido.
                            </li>
                            <li>
                              {" "}
                              Además, la compañía estará facultada para
                              solicitar la autopsia del asegurado y si algún
                              heredero se negare a permitir dicha autopsia o la
                              retardase en forma tal que ella sea inútil para el
                              fin perseguido, la compañía quedará liberada de su
                              obligación respecto a esta cláusula adicional.
                            </li>
                            <li>
                              {" "}
                              La compañía se reserva el derecho de solicitar
                              mayores antecedentes.
                            </li>
                          </ol>

                          <p>
                            <b>TRABAJOS EN ZONAS SUBTERRÁNEAS</b>. Se otorga
                            cobertura a los trabajos subterráneos que se
                            efectúen en terrenos compuestos de capas filtrantes,
                            húmedas, disgregantes y generalmente inconsistentes,
                            en túneles, esclusas y cámaras subterráneas, y la
                            aplicación de explotación de las minas se regirán
                            por las disposiciones de reglamento correspondiente
                            del código del trabajo y lo establecido por las
                            respectivas Mutualidades como trabajo subterráneo.
                            En caso de siniestro, rige lo siguiente para la
                            cobertura de trabajos en zonas subterráneas:
                          </p>
                          <ol>
                            <li>
                              En caso de accidente, se debe informar a la
                              compañía inmediatamente.
                            </li>
                            <li>
                              En caso de muerte esta debe ser constatada y
                              certificada por las autoridades competentes. Se
                              entiende por autoridad competente la policial,
                              marítima, médica o judicial, en su caso, de la
                              jurisdicción respectiva en que el suceso se haya
                              producido.
                            </li>
                            <li>
                              Además, la compañía estará facultada para
                              solicitar la autopsia del asegurado y si algún
                              heredero se negare a permitir dicha autopsia o la
                              retardase en forma tal que ella sea inútil para el
                              fin perseguido, la compañía quedará liberada de su
                              obligación respecto a esta cláusula adicional.
                            </li>
                            <li>
                              La compañía se reserva el derecho de solicitar
                              mayores antecedentes.
                            </li>
                          </ol>
                          <p>
                            <b>CONGELAMIENTO E INSOLACIÓN</b>. Se otorga
                            cobertura por congelamiento e insolación por
                            exposición a factores climáticos adversos como
                            consecuencia directa de un accidente.
                          </p>
                          <p>
                            <b>MAL DE ALTURA</b>, El mal de altura se debe a una
                            combinación de reducción de la presión atmosférica y
                            niveles más bajos de oxígeno a grandes alturas.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="accordion new-accordion acordion-site-admin">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-heading3">
                    <button
                      className="accordion-button collapsed fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse3"
                      aria-expanded="false"
                      aria-controls="flush-collapse3"
                    >
                      III. Deducibles
                    </button>
                  </h2>
                  <div
                    id="flush-collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading3"
                    data-bs-parent="#acordion-uaf"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-12 text-dark p-4">
                          <p>
                            <b>Reembolso de Gastos Médicos</b>. (Solo si ha sido
                            contratada e incorporada en la sección Coberturas)
                            Opera en exceso de SOAP y de cualquier sistema de
                            salud del asegurado (Isapre, Fonasa), de no existir
                            ninguno de los anteriores, en caso de siniestro
                            cubierto por la póliza, se indemnizará solo hasta el
                            50% de los gastos incurridos atribuibles a este
                            plan.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="accordion new-accordion acordion-site-admin">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-heading4">
                    <button
                      className="accordion-button collapsed fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse4"
                      aria-expanded="false"
                      aria-controls="flush-collapse4"
                    >
                      IV. Exclusiones
                    </button>
                  </h2>
                  <div
                    id="flush-collapse4"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading4"
                    data-bs-parent="#acordion-uaf"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-12 text-dark p-4">
                          <p>
                            <b>Actividades excluidas</b>
                          </p>
                          <ol>
                            <li>
                              Actividades Mineras Subterráneas (extracción)
                            </li>
                            <li>Actividades Militares.</li>
                            <li>Actividades Bomberos.</li>
                            <li>Actividades de Policía.</li>
                            <li>
                              Actividades de Guardias con porte de Armas.
                              (Vigilantes Privados)
                            </li>
                            <li>Actividades de Transporte de Valores.</li>
                            <li>
                              Actividades con Manejo o Uso de Explosivos, Fuegos
                              Artificiales y similares.
                            </li>
                            <li>
                              Actividades con Manejo o Uso de elementos
                              Químicos, Tóxicos, Corrosivos, Radioactivos y
                              similares.
                            </li>
                            <li>Actividades con Manejo o Uso de Asbestos.</li>
                            <li>
                              Alumnos de Colegios, Liceos o Escuelas de
                              Educación Parvularia, Primaria o Secundaria ya sea
                              Público o Privado.
                            </li>
                            <li>
                              Actividades Circenses tales como trapecistas,
                              acróbatas o personal de circo.
                            </li>
                            <li>
                              Actividades de Buceo profesional o artesanal.
                            </li>
                            <li>Rescatistas de Cualquier tipo.</li>
                            <li>
                              Deportistas Profesionales o que reciban
                              remuneración alguna por ello.
                            </li>
                          </ol>
                          <p>
                            <b>Exclusiones Contractuales</b>
                            <br />
                            Rigen exclusiones de contrato de reaseguro
                            automático
                          </p>
                          <ol>
                            <li>
                              Cobertura automática para pasajeros de aerolíneas
                              o seguros vendidos como parte de tickets aéreos.
                            </li>
                            <li>Personal y Tripulación de aerolíneas.</li>
                            <li>
                              Contaminación radiactiva, sea directa o indirecta.
                            </li>
                            <li>
                              Falta intencional (entendida como duelo, suicidio,
                              intento de suicidio y otros similares).
                            </li>
                            <li>
                              Guerra o Guerra civil. (pero los riesgos de guerra
                              podrían ser aceptados cuando el asegurado fuera de
                              su país de origen siempre que no tomen parte
                              activa en las hostilidades y siempre que se
                              considere una cancelación de cobertura de “riesgos
                              de guerra” con 14 días de antelación)
                            </li>
                            <li>
                              Respecto a terrorismo, huelga, motín y conmoción
                              civil, se cubren riesgos pasivos, pero se excluye
                              completamente ataques terroristas por sustancias
                              nucleares, químicas y/o biológicas. Se
                              considerarán riesgos pasivos cuando el asegurado
                              muere o sufre daños porque están accidentalmente
                              en un lugar cuando ocurre un acto de terrorismo,
                              huelga, motín o conmoción civil
                            </li>
                            <li>
                              Todo tipo de enfermedad y dolencia y cuidado
                              médico, con la excepción de coberturas de
                              discapacidad temporal para doctores.
                            </li>
                            <li>
                              Compensación para empleados / trabajadores y
                              seguro de responsabilidad de empleados, que no
                              opera en exceso de las coberturas mutuales.
                            </li>
                            <li>
                              Riesgos de aviación (excepto como pasajeros que
                              hayan pagado el pasaje o una aeronave
                              completamente licenciada en la cual el asegurado
                              está viajando calidad distinta a la de miembro de
                              la tripulación).
                            </li>
                            <li>
                              Participación en todo tipo de concursos de
                              velocidad distintos a aquellos a pie.
                            </li>
                            <li>Cualquier personal que trabaje:</li>
                            <ol type="a">
                              <li>
                                Off shore; excepto personal en plantas de
                                pisciculturas en agua.
                              </li>
                              <li>Como tripulante de una nave</li>
                              <li>Como tripulante de una aeronave.</li>
                            </ol>
                            <li>
                              No se pagarán beneficios si la muerte, daño y/o
                              enfermedad del asegurado es causada por y/o
                              resulta de guerra, hostilidades (sea que la guerra
                              sea declarada o no), guerra civil, rebelión,
                              revolución o acciones de cualquier otra persona
                              que actúe en nombre de o en relación a cualquier
                              grupo u organización que intente derrocar al
                              gobierno por fuerza, o intente influenciar al
                              mismo a través de terrorismo, secuestro o intento
                              de secuestro, ataque, asalto o cualquier otro
                              medio violento.
                            </li>
                            <li>Todo tipo de Responsabilidad Civil.</li>
                            <li>
                              Seguro que cubra fuerzas armadas, ejércitos y
                              grupos de defensa, en lo que respecta a: Efectos
                              de participación en guerra, declarada o no
                              declarada, invasión, acción de un enemigo
                              extranjero, hostilidades u operaciones bélicas, ya
                              sea con o sin declaración de guerra, así como
                              tampoco ningún ejercicio o práctica de guerra.
                              Comisión de actos que puedan ser calificados como
                              delito, así como la participación activa en
                              rebelión, revolución, sublevación, asonadas,
                              motín, conmoción civil, subversión y terrorismo.
                            </li>
                            <li>
                              Policías, guardias de prisión, bomberos, con la
                              excepción de guardias que no pueden portar armas
                              de fuego.
                            </li>
                            <li>Seguro vendido por agentes de viaje</li>
                            <li>
                              Inhabilidad Temporal. Pagos de salario siguiendo
                              inhabilidad en exceso de 365 días.
                            </li>
                            <li>Invasión de poderes extranjeros</li>
                            <li>
                              Muerte o Desmembramiento accidental resultante del
                              consumo de alcohol o drogas.
                            </li>
                            <li>
                              Empleados de minas subterráneas, excepto aquellos
                              que efectúen labores subterráneas, pero no
                              relacionados directamente con actividades de
                              extracción de mineral.
                            </li>
                            <li>
                              Vuelos charter (comerciales y no comerciales)
                              excepto cuando el transporte es realizado por
                              líneas aéreas comerciales regulares.
                            </li>
                            <li>
                              Trabajos peligrosos y la práctica de riesgos
                              deportivos, tales como:
                            </li>
                            <ol type="a">
                              <li>Carreras en auto</li>
                              <li>
                                Carreras de motores y carreras de lancha motoras
                              </li>
                              <li>Boxeo</li>
                              <li>Competencias de surf y windsurf</li>
                              <li>Navegación a vela de larga distancia</li>
                              <li>Botes de navegación de grandes cruceros</li>
                              <li>Andinismo, rappel y espeleología</li>
                              <li>
                                Escalada libre (montaña vertical o muralla
                                artificial)
                              </li>
                              <li>
                                Buzos profesionales, excepto personal en plantas
                                de pisciculturas en agua y hasta una profundidad
                                máxima de 40 metros.
                              </li>
                              <li>
                                Buzos amateur, sumergidos en profundidades que
                                excedan 40 metros.
                              </li>
                              <li>Bobsleigh</li>
                              <li>Salto en bungee</li>
                              <li>Caza</li>
                              <li>Carreras de caballo y salto ecuestre</li>
                              <li>Hípica</li>
                              <li>Corridas de toro y Rejoneadores</li>
                              <li>Amansamiento de caballos; rodeos</li>
                              <li>Ski (sólo saltos)</li>
                              <li>Paracaidismo</li>
                              <li>Parapentes (para-gliding y hang-gliding)</li>
                              <li>Planeadores</li>
                            </ol>
                            <li>
                              Riesgos de Aviación, tal como uso, transporte o
                              pilotaje de:
                            </li>
                            <ol type="a">
                              <li>Aviones ultra livianos</li>
                              <li>Aerostáticos</li>
                              <li>Acrobacias aéreas</li>
                              <li>Vuelo temerario</li>
                              <li>Vuelo libre</li>
                              <li>Deporte amateur de vuelo en planeador</li>
                            </ol>
                            <li>Dobles de películas</li>
                            <li>Manufactura de productos con asbestos.</li>
                          </ol>
                          <p>
                            <b>Exclusión de COVID-19</b>
                            <br />A pesar de cualquier disposición en contrario
                            dentro de este seguro o cualquier endoso, este
                            seguro no cubrirá a ninguna responsabilidad o
                            pérdida, que surja o esté relacionada con, o de
                            alguna manera que implique, ya sea directa o
                            indirectamente:
                          </p>
                          <ol type="a">
                            <li>
                              cualquier enfermedad por coronavirus (COVID-19)
                            </li>
                            <li>
                              cualquier coronavirus 2 del síndrome respiratorio
                              agudo severo (SARS-CoV-2)
                            </li>
                            <li>
                              cualquier mutante, derivado o variación de
                              COVID-19 o SARS-CoV-2
                            </li>
                            <li>
                              cualquier temor o amenaza, ya sea real o
                              percibida, de (a), (b) o (c) anteriores;
                            </li>
                            <li>
                              cualquier acción tomada para controlar, prevenir,
                              suprimir, mitigar o de cualquier manera
                              relacionada con cualquiera de (a), (b) o (c)
                              anteriores.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="accordion new-accordion acordion-site-admin">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-heading5">
                    <button
                      className="accordion-button collapsed fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse5"
                      aria-expanded="false"
                      aria-controls="flush-collapse5"
                    >
                      V. Anexos
                    </button>
                  </h2>
                  <div
                    id="flush-collapse5"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading5"
                    data-bs-parent="#acordion-uaf"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-12 text-dark p-4">
                          <p>
                            <b>REQUISITOS DE ASEGURABILIDAD</b>. El Asegurado
                            deberá tomar todas las medidas de seguridad que la
                            ley contempla para prevenir daños a terceros. Los
                            siniestros deberán ser reportados al asegurador
                            según se indica en las pólizas respectivas y esté
                            los administrará de acuerdo a las normas de la CMF
                            vigentes a la fecha del siniestro.
                          </p>
                          <p>
                            <b>CÓDIGO DE AUTORREGULACIÓN Y BUENAS PRÁCTICAS</b>.
                            <br />
                            <b>
                              RENTA NACIONAL COMPAÑÍA DE SEGUROS GENERALES S.A.
                            </b>
                            , se encuentra adherida voluntariamente al código de
                            autorregulación y al compendio de buenas prácticas
                            de las compañías de seguros, cuyo propósito es
                            propender al desarrollo del mercado de seguros, en
                            consonancia con los principios de libre competencia
                            y buena fe que debe existir entre las empresas y
                            entre éstas y sus clientes. Copia del compendio de
                            buenas prácticas corporativas de las compañías de
                            seguros se encuentra a disposición de los
                            interesados en cualquiera de las oficinas de esta
                            Compañía o en www.aach.cl. Asimismo, ha aceptado la
                            intervención del Defensor del Asegurado cuando los
                            clientes le presenten reclamos en relación a los
                            contratos celebrados con ella. Los clientes pueden
                            presentar sus reclamos ante el Defensor del
                            Asegurado utilizando los formularios disponibles en
                            las oficinas de RENTA NACIONAL COMPAÑÍA DE SEGUROS
                            GENERALES S.A. o a través de la página web
                            www.ddachile.cl.
                          </p>
                          <p>
                            <b>
                              INFORMACION SOBRE ATENCION DE CLIENTES Y
                              PRESENTACIÓN DE CONSULTAS Y RECLAMOS
                            </b>
                            . En virtud de la Circular No 2131 de 28 de
                            Noviembre de 2013, las compañías de seguros,
                            corredores de seguros y liquidadores de siniestros,
                            deberán recibir, registrar y responder todas las
                            presentaciones, consultas o reclamos que se les
                            presenten directamente por el contratante,
                            asegurado, beneficiarios o legítimos interesados,
                            surgidas con motivo de su relación con ellos. Las
                            presentaciones pueden ser efectuadas en la casa
                            matriz y en todas las agencias, oficinas o
                            sucursales de las entidades en que se atienda
                            público, presencialmente, por correo postal o medios
                            electrónicos, sin formalidades, en el horario normal
                            de atención.Recibida una presentación, consulta o
                            reclamo, ésta deberá ser respondida en el plazo más
                            breve posible, el que no podrá exceder de 20 días
                            hábiles contados desde su recepción. El interesado,
                            en caso de disconformidad respecto de lo informado,
                            o bien cuando exista demora injustificada de la
                            respuesta, podrá recurrir a la Comisión para el
                            Mercado Financiero, Área de Protección al
                            Inversionista y Asegurado, cuyas oficinas se
                            encuentran ubicadas en Av. Libertador Bernardo O
                            ́Higgins 1449, piso 1° o a través del sitio web
                            www.cmfchile.cl.
                          </p>
                          <p>
                            <b>
                              CIRCULAR No 2106 COMISIÓN PARA EL MERCADO
                              FINANCIERO PROCEDIMIENTO DE LIQUIDACION DE
                              SINIESTROS
                            </b>
                          </p>
                          <ol>
                            <li>
                              {" "}
                              OBJETIVO DE LA LIQUIDACION, La liquidación tiene
                              por fin establecer la ocurrencia del siniestro,
                              determinar si el siniestro está cubierto en la
                              póliza contratada en una compañía de seguros, y
                              cuantificar el monto de la pérdida y de la
                              indemnización a pagar. El procedimiento de
                              liquidación está sometido a los principios de
                              celebridad y economía procedimental, de
                              objetividad y carácter técnico y de transparencia
                              y acceso.
                            </li>
                            <li>
                              FORMA DE EFECTUAR LA LIQUIDACION, La liquidación
                              puede efectuarla directamente la Compañía o
                              encomendarla a un Liquidador de Seguros. La
                              decisión debe comunicarse al Asegurado dentro del
                              plazo de tres días hábiles contados desde la fecha
                              de la denuncia del siniestro.
                            </li>
                            <li>
                              DERECHO DE OPOSICION A LA LIQUIDACION DIRECTA, En
                              caso de liquidación directa por la Compañía, el
                              Asegurado o Beneficiario puede oponerse a ella,
                              solicitándole por escrito que designe un
                              Liquidador de Seguros, dentro del plazo de cinco
                              días contados desde la comunicación de la
                              Compañía. La Compañía deberá designar al
                              Liquidador en el plazo de dos días hábiles
                              contados desde dicha oposición.
                            </li>
                            <li>
                              {" "}
                              INFORMACIÓN AL ASEGURADO DE GESTIONES A REALIZAR Y
                              PETICION DE ANTECEDENTES, El Liquidador o la
                              Compañía, deberá informar al Asegurado, por
                              escrito, en forma suficiente y oportuna, al correo
                              electrónico (informado en la denuncia del
                              siniestro) o por carta certificada (al domicilio
                              señalado en la denuncia de siniestro), de las
                              gestiones que le corresponde realizar, solicitando
                              de una sola vez, cuando las circunstancias lo
                              permitan, todos los antecedentes que requiere para
                              liquidar el siniestro.
                            </li>
                            <li>
                              {" "}
                              PRE-INFORME DE LIQUIDACION, En aquellos siniestros
                              en que surgieren problemas y diferencias de
                              criterios sobre sus causas, evaluación del riesgo
                              o extensión de la cobertura, podrá el Liquidador,
                              actuando de oficio o a petición del Asegurado,
                              emitir un Pre-Informe de Liquidación sobre la
                              cobertura del siniestro y el monto de los daños
                              producidos, el que deberá ponerse en conocimiento
                              de los interesados. El Asegurado o la Compañía
                              podrán hacer observaciones por escrito al
                              Pre-Informe dentro del plazo de cinco días hábiles
                              desde su conocimiento.
                            </li>
                            <li>
                              {" "}
                              PLAZO DE LIQUIDACION, Dentro del más breve plazo,
                              no pudiendo exceder de 45 días corridos desde
                              fecha denuncio, a excepción de:
                            </li>
                            <ol type="a">
                              <li>
                                Siniestros que correspondan a seguros
                                individuales sobre riesgos del Primer Grupo cuya
                                prima anual sea superior a 100 UF : 90 días
                                corridos desde fecha denuncio.
                              </li>
                              <li>
                                Siniestros Marítimos que afecten a los Cascos o
                                en caso de Avería Gruesa: 180 días corridos
                                desde fecha denuncio.
                              </li>
                            </ol>
                            <li>
                              PRORROGA DEL PLAZO DE LIQUIDACION, Los plazos
                              antes señalados podrán, excepcionalmente siempre
                              que las circunstancias lo ameriten, prorrogarse,
                              sucesivamente por iguales periodos, informando los
                              motivos que la fundamenten e indicando las
                              gestiones concretas y específicas que se
                              realizarán, lo que deberá comunicarse al Asegurado
                              y a la Superintendencia, pudiendo esta última
                              dejar sin efecto la ampliación, en casos
                              calificados, y fijar un plazo para entrega del
                              Informe de Liquidación. No podrá ser motivo de
                              prórroga la solicitud de nuevos antecedentes cuyo
                              requerimiento pudo preverse con anterioridad,
                              salvo que se indiquen las razones que justifiquen
                              la falta de requerimiento, ni podrán prorrogarse
                              los siniestros en que no haya existido gestión
                              alguna del liquidador, registrado o directo.
                            </li>
                            <li>
                              INFORME FINAL DE LIQUIDACION, El informe final de
                              liquidación deberá remitirse al Asegurado y
                              simultáneamente al Asegurador, cuando corresponda,
                              y deberá contener necesariamente la transcripción
                              íntegra de los artículos 25 al 28 del Reglamento
                              de Auxiliares del Comercio de Seguros (D.S. de
                              Hacienda No 1.055, de 2012, Diario Oficial de 29
                              de diciembre de 2012).
                            </li>
                            <li>
                              {" "}
                              IMPUGNACION INFORME DE LIQUIDACION, Recibido el
                              Informe de Liquidación, la Compañía y el Asegurado
                              dispondrán de un plazo de diez días hábiles para
                              impugnarla. En caso de liquidación directa por la
                              Compañía, este derecho sólo lo tendrá el
                              Asegurado. Impugnado el Informe, el Liquidador o
                              la compañía dispondrá de un plazo de 6 días
                              hábiles para responder la impugnación.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center py-3">
        <div className="col-12 col-md-3">
          <MButton
            type="button"
            className="btn btn-primary sistema w-100"
            data-bs-toggle="collapse"
            onClick={() => setShowModal(false)}
            data-bs-target="#advance-results"
          >
            Aceptar
          </MButton>
        </div>
      </div>
    </MPopup>
  );
};
