import { useEffect, useState } from "react";
import { changeStep, loadStepper } from "@redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  MButton,
  MInputCheckBox,
  MInputGeneric,
  MInputNumber,
  MInputSelect,
  MInputTextArea,
} from "@inversiones-ma/finfast-react-controls";
import { useForm } from "react-hook-form";
import { LocationPropsTRC } from "../TRCInsurance";
import * as GlobalModels from "@redux/models/GlobalModels";
import * as helpers from "@helpers/helpers";
import * as helpers2 from "@helpers/helpers2";
import * as InsuranceModels from "@redux/models/InsuranceModels";
import "../TRCInsurance.scss";
import { loadWorkInformationTRC } from "@redux/actions/InsuranceActions";

export interface Props {
  tipoSeguro: LocationPropsTRC;
}

const TRCWorkInformation = (props: Props) => {
  const dispatch = useAppDispatch();

  const { dataWork } = useAppSelector((store) => store.insuranceTRC);

  const { regions } = useAppSelector((store) => store.global);
  const [arrComuna, setArrComuna] = useState<GlobalModels.Commune[]>(
    helpers2.getCommunes("", regions)
  );

  const form = useForm<InsuranceModels.DataWork>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const { watch, setValue, handleSubmit, clearErrors } = form;
  const { idRegionObra, isDireccionSinNumero } = watch();

  useEffect(() => {
    setValue("obraCercanaLagunaMar", dataWork.obraCercanaLagunaMar);
    setValue("nombreObra", dataWork.nombreObra);
    setValue("descripcionObra", dataWork.descripcionObra);
    setValue("idRegionObra", dataWork.idRegionObra);
    setValue("idComunaObra", dataWork.idComunaObra);
    setValue("direccionObra", dataWork.direccionObra);
    setValue("direccionNumero", dataWork.direccionNumero);
    setValue("direccionAdicionalObra", dataWork.direccionAdicionalObra);
    setValue("isDireccionSinNumero", dataWork.isDireccionSinNumero);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataWork.idComunaObra !== "") {
      setValue("idComunaObra", dataWork.idComunaObra);
    }
  }, [arrComuna]);

  useEffect(() => {
    if (isDireccionSinNumero) {
      clearErrors("direccionNumero");
      setValue("direccionNumero", undefined);
    }
  }, [isDireccionSinNumero]);

  useEffect(() => {
    let r = regions?.find((r) => r.id.toString() == idRegionObra);
    let comunas = helpers.getComunas(r?.name, regions);
    setArrComuna(comunas);
    // eslint-disable-next-line
  }, [idRegionObra]);

  const onSubmit = (data: InsuranceModels.DataWork) => {
    dispatch(loadWorkInformationTRC(data));
    dispatch(changeStep(3));
  };

  const handleReturn = () => {
    dispatch(changeStep(1));
  };

  return (
    <>
      <div className="col-lg-8">
        <div className="section-insurance">
          <h2 className="titles-center fs-24">
            Ingresa los datos del asegurado
          </h2>
          <p className="text-center pb-4">
            Completa el formulario con los datos de la empresa que contrata.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="subtitle-mini">
              Declaración de cercanía a cuerpos de agua
            </h3>
            <div className="row pb-3">
              <div className="col-lg-12">
                <div
                  className="alert alert-info d-flex align-items-start"
                  role="alert"
                >
                  <div className="icon">
                    <i
                      className="fas fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div>
                    <h5>
                      El seguro no cubre obras que se encuentren cercanas a
                      lagunas, lagos, ríos o mar.
                    </h5>
                    <p>
                      Se considera obra cercana aquellas que se encuentran a
                      menos de 300 mt de distancia o a menos de 20 mt de altura.
                    </p>
                  </div>
                </div>
                <div className="form-check">
                  <MInputCheckBox
                    form={form}
                    name="obraCercanaLagunaMar"
                    label="Confirmo que la obra no se encuentra cercana a cuerpos de agua."
                    className="form-check-input"
                    rules={{
                      required:
                        "Debes confirmar que la obra no se encuentra cercana a cuerpos de agua",
                    }}
                  />
                </div>
                <hr className="dashed" />
              </div>
            </div>
            <h3 className="subtitle-mini">Identificación de la obra</h3>
            <div className="row pb-3">
              <div className="col-lg-6">
                <fieldset>
                  <div className="floating-label">
                    <MInputGeneric
                      form={form}
                      name="nombreObra"
                      label="Nombre de obra"
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      rules={{
                        required: "Este campo es requerido",
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-12">
                <div className="floating-label">
                  <MInputTextArea
                    form={form}
                    name="descripcionObra"
                    className="floating-input floating-textarea default"
                    placeholder=" "
                    maxLength={1000}
                    label="Descripción de la obra"
                    rules={{
                      required: "Este campo es requerido",
                    }}
                  />
                </div>
              </div>
            </div>

            <h3 className="subtitle-mini">Ubicación de la obra</h3>
            <div className="row pb-3">
              <div className="col-lg-6">
                <div className="floating-label">
                  <MInputSelect
                    form={form}
                    name="idRegionObra"
                    className="form-select info-form input-tag"
                    items={regions as GlobalModels.Region[]}
                    valueItem="id"
                    label="Región"
                    descriptionItem="name"
                    orderBy={{
                      by: "name",
                      desc: false,
                      isString: true,
                    }}
                    rules={{
                      required: "Este campo es requerido",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <MInputSelect
                    form={form}
                    name="idComunaObra"
                    className="form-select info-form input-tag"
                    items={arrComuna}
                    valueItem="id"
                    descriptionItem="name"
                    orderBy={{
                      by: "name",
                      desc: false,
                      isString: true,
                    }}
                    label="Comuna"
                    rules={{
                      required: "Este campo es requerido",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <fieldset>
                  <div className="floating-label">
                    <MInputGeneric
                      form={form}
                      name="direccionObra"
                      label="Calle"
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      rules={{
                        validate: (value: string) => {
                          if (value.trim().length > 0) {
                            return true;
                          }

                          return "La dirección de la obra es requerida";
                        },
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-6">
                <fieldset>
                  <div className="floating-label">
                    <MInputNumber
                      form={form}
                      name="direccionNumero"
                      label="Número"
                      maxLength={6}
                      className="floating-input"
                      placeholder=" "
                      disabled={!!isDireccionSinNumero}
                      rules={{
                        required: {
                          value: !isDireccionSinNumero,
                          message: "Este campo es requerido",
                        },
                      }}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col-lg-6">
                <fieldset>
                  <div className="floating-label">
                    <MInputGeneric
                      form={form}
                      name="direccionAdicionalObra"
                      label="Complemento de dirección (Opcional)"
                      className="floating-input"
                      type="text"
                      placeholder=" "
                    />
                  </div>
                </fieldset>
              </div>
              <div className="col">
                <div className="form-check">
                  <MInputCheckBox
                    form={form}
                    name="isDireccionSinNumero"
                    label=" Si la dirección de la obra no tiene número, marca esta opción."
                    className="form-check-input"
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="row pb-2">
              <div className="col-lg-3 col-md-4 order-3 order-md-1">
                <MButton
                  className="btn btn-primary sistema outline w-100"
                  onClick={() => handleReturn()}
                  type="button"
                >
                  <i className="fa fa-arrow-left" />
                  Volver
                </MButton>
              </div>
              <div className="col-lg-6 col-md-4 order-2"></div>
              <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
                <MButton
                  className="btn btn-primary sistema w-100"
                  type="submit"
                >
                  Continuar
                  <i className="fa fa-arrow-right" />
                </MButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default TRCWorkInformation;
