import { MGrid, MGridColumn } from "@inversiones-ma/finfast-react-controls";
import { Coverage } from "@redux/models/InsuranceModels";

type TRCDesktopDetailCoverageProps = {
  detailCoverages: Coverage[];
};

export const RCDesktopDetailCoverage = ({
  detailCoverages,
}: TRCDesktopDetailCoverageProps) => {
  return (
    <div className="d-none d-sm-block">
      <MGrid
        className="table table-coberturas-seguro"
        dataSource={detailCoverages}
        key="code"
      >
        <MGridColumn
          dataField="descripcionCobertura"
          label=" "
          className="bg-grey fs-14 color-text"
          cellRender={(_k: any, value: any, row: any) => {
            return <> {value} </>;
          }}
        ></MGridColumn>
        <MGridColumn
          dataField="montoAsegurado"
          label={<h4 className=" fs-16 color-secondary fw-bold">Asegurado</h4>}
          className="fs-16 color-secondary fw-bold"
          cellRender={(_k: any, value: any, row: any) => {
            return <> {value} </>;
          }}
        ></MGridColumn>
        <MGridColumn
          dataField="primaNeta"
          label={<h4 className=" fs-16 color-gris57 fw-bold ">Prima neta</h4>}
          className="fs-16 color-gris57 fw-bold"
          headerClassName="ancho-prima-neta"
          cellRender={(_k: any, value: any, row: any) => {
            return <> {value} </>;
          }}
        ></MGridColumn>
      </MGrid>
    </div>
  );
};
