import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { convertToUf } from "@helpers/helpers";
import { MButton } from "@inversiones-ma/finfast-react-controls";
import { changeStep } from "@redux/actions/GlobalActions";
import { useAppDispatch } from "@redux/hooks";
import { InsurancePlan } from "@redux/models/InsuranceModels";

import RCDetailCoverage from "./RCDetailCoverage";

interface Props {
  plans: InsurancePlan[];
  setStep: any;
  idPropuesta: number;
  setIdPropuesta: any;
}
const RCSelectPlan = (props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [modalDetailCoverage, setModalDetailCoverage] =
    useState<boolean>(false);

  const [idPropuestaToSee, setIdPropuestaToSee] = useState<number>(0);
  const nextStep = () => {
    props.setStep(2);
  };
  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = form;

  const selectPlan = (idPropuesta: number) => {
    props.setIdPropuesta(idPropuesta);
  };
  const seeDetailCoverage = (idPropuesta: number) => {
    setIdPropuestaToSee(idPropuesta);
    setModalDetailCoverage(true);
  };
  const handleOnClickBack = () => {
    dispatch(changeStep(1));
    history.push("/home");
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="section-insurance">
        <h2 className="titles-center fs-24">
          Selecciona la opción que más te convenga
        </h2>
        <form onSubmit={handleSubmit(nextStep)}>
          <div className="row">
            {props.plans?.map((item: InsurancePlan) => (
              <div className="col-12 col-md-4 my-2" key={item.idPropuesta}>
                <div className="rounded-card-bg  p-0 planes-rc-new text-center p-4">
                  <h4 className="fs-14 color-secondary">Plan {item.idPlan}</h4>
                  <h3 className="fs-14 color-primary">
                    <span className="fs-24 color-primary ">
                      <strong>UF {convertToUf(item.montoPrima)}</strong>{" "}
                    </span>
                    anual + IVA
                  </h3>
                  <div className="dashed-separator my-2"></div>
                  <h5 className="fs-14 color-text ">
                    Cobertura <br />
                    Responsabilidad Civil General
                  </h5>
                  <p className="fs-18 color-secondary">
                    UF {convertToUf(item.montoAseguradoGeneral)}
                  </p>
                  <div className="dashed-separator my-2"></div>
                  <a
                    onClick={() => seeDetailCoverage(item.idPropuesta)}
                    className="text-link"
                    style={{ cursor: "pointer" }}
                  >
                    Ver detalle de coberturas
                  </a>
                </div>
                {props.idPropuesta === item.idPropuesta ? (
                  <MButton className="btn btn-primary w-100 boton-seleccion-rc">
                    Seleccionado{" "}
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                  </MButton>
                ) : (
                  <MButton
                    className="btn btn-primary outline w-100 boton-seleccion-rc"
                    onClick={() => selectPlan(item.idPropuesta)}
                  >
                    Seleccionar
                  </MButton>
                )}
              </div>
            ))}
          </div>

          <div className="row pt-5 pb-2">
            <div className="col-lg-3 col-md-4 order-3 order-md-1">
              <MButton
                type="button"
                onClick={handleOnClickBack}
                className="btn btn-primary sistema outline w-100"
              >
                <i className="fa fa-arrow-left"></i> Volver
              </MButton>
            </div>
            <div className="col-lg-6 col-md-4 order-2"></div>
            <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 order-1 order-md-3">
              <MButton type="submit" className="btn btn-primary sistema w-100">
                Continuar <i className="fa fa-arrow-right"></i>
              </MButton>
            </div>
          </div>
        </form>
      </div>
      <RCDetailCoverage
        showModal={modalDetailCoverage}
        setShowModal={setModalDetailCoverage}
        idPropuesta={idPropuestaToSee}
        setIdPropuestaSelect={props.setIdPropuesta}
        fromCart={false}
      />
    </>
  );
};

export default RCSelectPlan;
