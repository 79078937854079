import React from "react";
import MenuClient from "./partial/MenuClient";
import MenuPartialSelector from "./partial/MenuPartialSelector";

const MasterPrivateNavbar = () => {
  return (
    <>
      <header className="fixed-top-main">
        <MenuClient />
        <MenuPartialSelector />
      </header>
    </>
  );
};

export default MasterPrivateNavbar;
