import React, { useEffect, useState } from "react";
import { useAppSelector } from "@redux/hooks";
import LoadingScreen from "./components/pages/Public/LoadingScreen";
import { Route, Switch, Redirect } from "react-router-dom";
import MasterPrivate from "./components/layouts/MasterPrivate";
import MasterPublic from "./components/layouts/MasterPublic";
import { Home } from "./components/pages/Private/Home/Home";
import HomeCart from "./components/pages/Private/HomeCart/HomeCart";
import RCInsurance from "./components/pages/Private/RCInsurance/RCInsurance";
import { Steeper } from "@controls/Stepper/Stepper";
import TRCInsurance from "./components/pages/Private/TRCInsurance/TRCInsurance";
import { PFInsurance } from "./components/pages/Private/PersonalAccidents/PFInsurance";
import DownloadPolicy from "./components/pages/Private/DownloadPolicy/DownloadPolicy";
import { ShowLogin } from "./scripts/config/environment";
import Login from "./components/pages/Private/Login/Login";
import Logout from "./components/pages/Private/Logout/Logout";

const Routing = () => {
  const { token } = useAppSelector((store) => store.account);
  const showLogin = false; //ShowLogin();

  //En caso de tener token acceder a las rutas del sistema
  if (token) {
    return (
      <MasterPrivate>
        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/responsabilidad-civil" exact component={RCInsurance} />
          <Route
            path="/todo-riesgo-construccion"
            exact
            component={TRCInsurance}
          />
          <Route
            path={"/proteccion-financiera"}
            exact
            component={PFInsurance}
          />
          <Route path={"/stepper"} render={() => <Steeper />} />
          <Route path="/homecart" exact component={HomeCart} />
          <Route path="/homecart/:children" exact component={HomeCart} />
          <Route path={"/descarga-poliza"} exact component={DownloadPolicy} />
          <Route path={"/logout"} exact component={Logout} />
          <Route render={() => <Redirect to="/home" />} />
        </Switch>
      </MasterPrivate>
    );
  } else {
    //Rutas públicas
    return (
      <MasterPublic>
        <Switch>
          <Route
            path="/"
            exact
            component={!showLogin ? LoadingScreen : Login}
          />
          <Route
            path="/login"
            exact
            component={!showLogin ? LoadingScreen : Login}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </MasterPublic>
    );
  }
};

export default Routing;
