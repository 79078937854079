import { createSlice, current } from "@reduxjs/toolkit";

import { loadProducts, cleanState } from "../actions/ProductActions";

import { ProductState } from "../models/ProductsModels";

const inicialState: ProductState = {
  listProducts: [],
};

const slice = createSlice({
  name: "products",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanState, (state) => {
        state.listProducts = inicialState.listProducts;
      })
      .addCase(loadProducts, (state, action) => {
        state.listProducts = action?.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
