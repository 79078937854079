import { createAction } from '@reduxjs/toolkit'
import { TypeInsurance } from '@redux/models/TypeInsuranceModels'

export const setInsurances = createAction('setAllInsurances', (insurances: TypeInsurance[]) => {
    return {
        payload: insurances
    }
})

export const setExternalInsurances = createAction('setExternalInsurances', (insurances: TypeInsurance[]) => {
    return {
        payload: insurances
    }
})