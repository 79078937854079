import {
  loadInsurancePlansPA,
  loadAssuredPA,
  loadSurePrime,
  cleanSurePrime,
  cleanInsurancePlansPA
} from "./../actions/InsuranceActions";
import { InsurancePAState } from "@redux/models/InsuranceModels";
import { createSlice } from "@reduxjs/toolkit";

const inicialState: InsurancePAState = {
  plans: [],
  assured: {
    //asegurado
    rutAsegurado: "",
    razonSocialAsegurado: "",
    nombresAsegurado: "",
    apellidoPaternoAsegurado: "",
    apellidoMaternoAsegurado: "",
    mailAsegurado: "",
    telefonoAsegurado: "",
    regionAsegurado: "",
    comunaAsegurado: "",
    calleAsegurado: "",
    numeroAsegurado: "",
    //adicional
    rutBeneficiarioAdicional: "",
    razonSocialBeneficiarioAdicional: "",
    nombresBeneficiarioAdicional: "",
    apellidoPaternoBeneficiarioAdicional: "",
    apellidoMaternoBeneficiarioAdicional: "",
    mailBeneficiarioAdicional: "",
    telefonoBeneficiarioAdicional: "",
    regionBeneficiarioAdicional: "",
    comunaBeneficiarioAdicional: "",
    calleBeneficiarioAdicional: "",
    numeroBeneficiarioAdicional: "",

    tieneBeneficiarioAdicional: false,
    tipoBeneficiarioAdicional: "",
    idPlanPropuesta: 1,
    cantidadTrabajadores: 1,
    rgm: false,
  },
  prima: undefined,
};

const slice = createSlice({
  name: "insurancePA",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadInsurancePlansPA, (state, action) => {
        state.plans = action.payload;
      })
      .addCase(loadAssuredPA, (state, action) => {
        state.assured = action.payload;
      })
      .addCase(loadSurePrime, (state, action) => {
        state.prima = action.payload;
      })
      .addCase(cleanSurePrime, (state) => {
        state.prima = inicialState.prima;
      })
      .addCase(cleanInsurancePlansPA, (state) => {
        state.assured = inicialState.assured;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
