import * as GlobalModels from "@redux/models/GlobalModels";
import { sonRegionesIguales } from "./helpers";
import dayjs from "dayjs";

export const getCommunes = (
  regionName: string,
  regions: GlobalModels.Region[] | undefined
): GlobalModels.Commune[] => {
  if (!regionName) return [];
  if (!regions || regions.length === 0) return [];
  const communes =
    regions.find((r) => sonRegionesIguales(regionName, r.name))?.communes ?? [];
  if (communes.length === 0) return [];
  return communes;
};

export const patternEmail = {
  value:
    /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Formato de correo inválido",
};

export const maxLengthGeneral = (value: number | undefined) => ({
  value: value,
  message: "Debes ingresar a lo más " + value + " caracteres",
});

export const vEmail = (requiredText: string | undefined, maxLength = 100) => ({
  required: requiredText ?? false,
  pattern: patternEmail,
  maxLength: maxLengthGeneral(maxLength),
});

export const comparableString = (value: string): string => {
  // eslint-disable-next-line
  let pattern: RegExp = /[!#%^*()_+=\[\]{};':"\\|<>\/?]+/;
  return value.toString().replace(pattern, "");
};

export const removeTimeFromDate = (date: Date): Date => {
  if (date === undefined || date === null) {
    return new Date(new Date().toDateString());
  } else if (typeof date === "string") {
    return new Date(new Date(date).toDateString());
  } else {
    return new Date(date.toDateString());
  }
};

export const getDate = (loginDate: Date, loginDateClient: Date): Date => {
  const loginDateStr: string = loginDate.toString().substring(0, 23);
  const loginDateClientStr: string = loginDateClient
    .toString()
    .substring(0, 23);
  const loginDateJs = dayjs(loginDateStr);
  const loginDateClientJs = dayjs(loginDateClientStr);
  const currentDateClientJs = dayjs(new Date());
  const diffMinutes: number = currentDateClientJs.diff(
    loginDateClientJs,
    "minute"
  );
  const currentDateJs = loginDateJs.add(diffMinutes, "minutes");
  // console.log({
  //   loginDateJs: loginDateJs.toDate()
  //   , loginDateClientJs: loginDateClientJs.toDate()
  //   , currentDateClientJs: currentDateClientJs.toDate()
  //   , diffMinutes
  //   , currentDateJs: currentDateJs.toDate()
  //   , getDate: currentDateJs.toDate()
  //   , getDateWithoutTime: removeTimeFromDate(currentDateJs.toDate())
  // });
  return currentDateJs.toDate();
};

export const validateStartDateAndEndDate = (startDate: Date, endDate: Date) => {
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return true;
  
  startDate = new Date(startDate.setHours(0, 0, 0, 0));
  endDate = new Date(endDate.setHours(0, 0, 0, 0));
  
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  if (differenceInDays < 1) {
    return "confirme la fecha de termino";
  }

  return true;
};

export const getDateWithoutTime = (
  loginDate: Date,
  loginDateClient: Date
): Date => {
  const date = getDate(loginDate, loginDateClient);
  return removeTimeFromDate(date);
};

export const nombreApellido = (nombre: string) => {
  //nombre = "maria de los angeles soto perez";
  let palabras: string[] = nombre.trim().split(" ");

  /* palabras de apellidos (y nombres) compuetos */
  let palabrasEspeciales: string[] = [
    "da",
    "de",
    "del",
    "la",
    "las",
    "los",
    "mac",
    "mc",
    "van",
    "von",
    "y",
    "i",
    "san",
    "santa",
  ];

  let prev = "";
  let nombresLista: string[] = [];

  palabras.forEach((item) => {
    if (item != "") {
      let actual = item.toLowerCase();
      if (palabrasEspeciales.includes(actual)) {
        prev = prev + actual.toUpperCase() + " ";
      } else {
        nombresLista.push(prev + item.toUpperCase());
        prev = "";
      }
    }
  });

  let nombres = "";
  let primerApellido = "";
  let segundoApellido = "";
  switch (nombresLista.length) {
    case 0:
      nombres = "";
      break;
    case 1:
      nombres = nombresLista[0];
      break;
    case 2:
      primerApellido = nombresLista[0];
      nombres = nombresLista[1];
      break;
    case 3:
      primerApellido = nombresLista[1];
      segundoApellido = nombresLista[2];
      nombres = nombresLista[0];
      break;
    case 4:
      primerApellido = nombresLista[2];
      segundoApellido = nombresLista[3];
      nombres = nombresLista[0] + " " + nombresLista[1];
      break;
    default:
      primerApellido = nombresLista[2];
      segundoApellido = nombresLista[3];
      nombresLista.splice(0);
      nombresLista.splice(0);
      nombres = nombresLista.join();
      break;
  }

  let nombreApellido = {
    nombres: nombres,
    primerApellido: primerApellido,
    segundoApellido: segundoApellido,
  };

  return nombreApellido;
};
