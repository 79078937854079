import React, { useEffect, useState } from "react";
import { useAppSelector } from "@redux/hooks";
import {
  MInputEmail,
  MInputGeneric,
  MInputNumber,
  MInputRut,
  MInputSelect,
} from "@inversiones-ma/finfast-react-controls";
import * as GlobalModels from "@redux/models/GlobalModels";
import * as helpers from "@helpers/helpers";
import * as helpers2 from "@helpers/helpers2";
import { LocationPropsTRC } from "../../TRCInsurance";
import { patternRut, validateRut } from "@helpers/validationsRFH";

interface PropsMandatoryInfo {
  form: any;
  tipoSeguro: LocationPropsTRC;
}

const MandatoryInfo = ({ form, tipoSeguro }: PropsMandatoryInfo) => {
  const { assuredTrc } = useAppSelector((store) => store.insuranceTRC);
  const { regions, dataConfiguration } = useAppSelector(
    (store) => store.global
  );
 
  const [arrComunaMandante, setArrComunaMandante] = useState<
    GlobalModels.Commune[]
  >(helpers2.getCommunes("", regions));

  const { watch, setValue } = form;
  const { regionMandante } = watch();

  const arrRutMOP = (
    dataConfiguration?.find(
      (item) => item.llave == "insuranceTRCRutMandanteMOP"
    )?.valorAlfanumerico ?? ""
  ).split(",");
  const validMOP = tipoSeguro?.id !== 40;

  useEffect(() => {
    let r = regions?.find((r) => r.id.toString() == regionMandante);
    let comunas = helpers.getComunas(r?.name, regions);
    setArrComunaMandante(comunas);
    // eslint-disable-next-line
  }, [regionMandante]);

  useEffect(() => {
    if (assuredTrc.comunaMandante !== "") {
      setValue("comunaMandante", assuredTrc.comunaMandante);
    }
  }, [arrComunaMandante]);

  return (
    <>
      <h3 className="subtitle-mini">Información del mandante</h3>
      <div className="row">
        {validMOP && (
          <div className="col-md-12">
            <div
              className="alert alert-warning d-flex align-items-start"
              role="alert"
            >
              <div className="icon">
                <i className="fas fa-exclamation-circle" aria-hidden="true"></i>
              </div>
              <div>
                <h5>Importante</h5>
                <p>Este seguro no aplica para empresas asociadas al MOP.</p>
              </div>
            </div>
          </div>
        )}

        <div className="col-lg-6">
          <fieldset>
            <div className="floating-label">
              <MInputRut
                form={form}
                name="rutMandante"
                label="RUT Mandante"
                className="floating-input"
                placeholder=" "
                rules={{
                  required: "El campo no puede quedar vacío",
                  pattern: patternRut,
                  // validate: validateRut,
                  validate: {
                    sii: validateRut,
                    si: (value) => {
                      return (
                        !validMOP ||
                        !arrRutMOP.find((x) => x === value) ||
                        "No es posible vender este seguro para el Ministerio de Obras Públicas."
                      );
                    },
                  },
                }}
                // rules={{
                //   required: "Este campo es requerido",
                //   validate: () =>
                //     validMOP ||
                //     (menssageMOP ??
                //       "Para emitir esta garantía contáctese con tu ejecutiva"),
                // }}
              />
            </div>
          </fieldset>
        </div>
        <div className="col-lg-12">
          <fieldset>
            <div className="floating-label">
              <MInputGeneric
                form={form}
                name="razonSocialMandante"
                label="Razón Social del Mandante"
                className="floating-input"
                type="text"
                placeholder=" "
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </fieldset>
        </div>
        <div className="col-lg-6">
          <fieldset>
            <div className="floating-label">
              <MInputEmail
                form={form}
                name="mailMandante"
                label="Correo"
                className="floating-input"
                placeholder=" "
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </fieldset>
        </div>
        <div className="col-lg-6">
          <fieldset>
            <div className="floating-label">
              <MInputNumber
                form={form}
                name="telefonoMandante"
                label="Teléfono"
                className="floating-input"
                placeholder=" "
                rules={{
                  required: "Este campo es requerido",
                }}
                maxLength={12}
              />
            </div>
          </fieldset>
        </div>
      </div>
      <h3 className="subtitle-mini">Dirección del mandante</h3>
      <div className="row pb-3">
        <div className="col-lg-6">
          <div className="floating-label">
            <MInputSelect
              form={form}
              name="regionMandante"
              className="form-select info-form input-tag"
              items={regions as GlobalModels.Region[]}
              valueItem="id"
              label="Región"
              descriptionItem="name"
              orderBy={{
                by: "name",
                desc: false,
                isString: true,
              }}
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="floating-label">
            <MInputSelect
              form={form}
              name="comunaMandante"
              className="form-select info-form input-tag"
              items={arrComunaMandante}
              valueItem="id"
              descriptionItem="name"
              orderBy={{
                by: "name",
                desc: false,
                isString: true,
              }}
              label="Comuna"
              rules={{
                required: "Este campo es requerido",
              }}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <fieldset>
            <div className="floating-label">
              <MInputGeneric
                form={form}
                name="calleMandante"
                label="Calle"
                className="floating-input"
                type="text"
                placeholder=" "
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </fieldset>
        </div>
        <div className="col-lg-6">
          <fieldset>
            <div className="floating-label">
              <MInputNumber
                form={form}
                name="numeroMandante"
                label="Número"
                className="floating-input"
                maxLength={6}
                placeholder=" "
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default MandatoryInfo;
