import { createSlice } from "@reduxjs/toolkit";

import {
  cleanState,
  loadPaymentMethods,
  loadRegions,
  setCurrentDate,
  loadDataSurvey,
  loadDataDisabledTrc,
  loadStepper,
  changeStep,
} from "../actions/GlobalActions";
import { GlobalState } from "../models/GlobalModels";

const inicialState: GlobalState = {
  loaded: false,
  regions: undefined,
  currentDate: undefined,
  paymentMethods: undefined,
  surveyNps: undefined,
  dataConfiguration: undefined,
  stepper: {
    active: 1,
    steps: [
      {
        id: 1,
        name: "Cotizar",
      },
      {
        id: 2,
        name: "Información del asegurado",
      },
      {
        id: 3,
        name: "Ingresa información adicional",
      },
    ],
  },
};

const slice = createSlice({
  name: "global",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRegions, (state, action) => {
        state.regions = action.payload;
      })
      .addCase(setCurrentDate, (state, action) => {
        state.currentDate = action.payload;
      })
      .addCase(cleanState, (state) => {
        state.loaded = inicialState.loaded;
        state.regions = inicialState.regions;
        state.currentDate = inicialState.currentDate;
      })
      .addCase(loadPaymentMethods, (state, action) => {
        state.paymentMethods = action.payload;
      })
      .addCase(loadDataSurvey, (state, action) => {
        state.surveyNps = action.payload;
      })
      .addCase(loadDataDisabledTrc, (state, action) => {
        state.dataConfiguration = action.payload;
      })
      .addCase(loadStepper, (state, action) => {
        state.stepper = action.payload;
      })
      .addCase(changeStep, (state, action) => {
        state.stepper.active = action.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
