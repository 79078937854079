import { TypeQuote } from "../Functions/QuoterPersonalAccidents";
import { toMilesNumeros } from "../../../../../scripts/helpers/helpers";

type InsuranceQuoteProps = {
  quote: TypeQuote | undefined;
};

export const InsuranceQuote = ({ quote }: InsuranceQuoteProps) => {
  const handleRenderMobile = (): JSX.Element => {
    return (
      <>
        {quote?.cobertura?.map((c) => (
          <div className="detalle-coberturas my-3" key={c.idPlanSeguro}>
            <div className="row bg-light p-2">
              <div className="col-6">
                <h4 className="fs-16 color-primary fw-bold p-0 m-0">
                  Cobertura
                </h4>
              </div>
              <div className="col-6">
                <p className="fs-14 color-text p-0 m-0">
                  {c.descripcionCobertura}
                </p>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-6">
                <h4 className=" fs-16 color-secondary fw-bold p-0 m-0">
                  Asegurado
                </h4>
              </div>
              <div className="col-6">
                <p className="fs-14 color-text p-0 m-0">
                  UF {toMilesNumeros(c.montoAsegurado)}
                </p>
              </div>
            </div>
            <hr className="m-0" />
            <div className="row p-2">
              <div className="col-6">
                <h4 className="fs-16 text-muted fw-bold p-0 m-0">Prima Neta</h4>
              </div>
              <div className="col-6">
                <p className="fs-14 text-muted p-0 m-0">
                  UF {c.primaFormateada}
                </p>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const handleRenderDesktop = (): JSX.Element => {
    return (
      <>
        {quote?.cobertura?.map((c) => (
          <tr key={c.idPlanSeguro}>
            <td className="bg-grey fs-14 color-text">
              {c.descripcionCobertura}
            </td>
            <td>
              <p className="fs-14 color-text">
                UF {toMilesNumeros(c.montoAsegurado)}
              </p>
            </td>
            <td>
              <p className="fs-14 text-muted">UF {c.primaFormateada}</p>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      {quote && (
        <div className="row">
          <div className="col-12 mb-3 mx-2">
            <h3 className="subtitle-mini">Resultado de cotización</h3>
          </div>
          <div className="col-12">
            <div className="row cont-cobertura-cotizador card-product-seguros bg-white py-2 px-3 mx-1">
              <div className="col-12">
                <p className="m-0 fs-14 color-secondary">Prima neta</p>
                <h3 className="ff-kanit fs-24 color-primary text-uppercase">
                  UF {quote?.prima.format.primaNeta} ANUAL + IVA
                </h3>
                <div className="dashed-separator mb-2"></div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="d-block d-sm-none">
                      {handleRenderMobile()}
                    </div>
                    <div className="d-none d-sm-block">
                      <table className="table table-coberturas-seguro">
                        <thead>
                          <tr>
                            <th scope="col">
                              <h4 className=" fs-16 color-primary fw-bold">
                                Cobertura
                              </h4>
                            </th>
                            <th scope="col">
                              <h4 className=" fs-16 color-secondary fw-bold">
                                Asegurado
                              </h4>
                            </th>
                            <th scope="col">
                              <h4 className=" fs-16 text-muted">Prima neta</h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>{handleRenderDesktop()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
