import { MAxios, InjectTokenHeader } from "../conf";

export const getProductsMenu = async () => {
    const endpoint = "membresia/EnabledProducts/ValidateProducts";

    const res = await MAxios().post(endpoint);

    return res.data;
}

const GetOneUseToken = async (token: string) => {
    let formData = new FormData();
    formData.append("token", token);
    const endpoint = "membresia/AccountProducto/GetOneUseToken";

    const tokenHeader = token ? InjectTokenHeader(token) : {};

    const res = await MAxios().post(endpoint, formData, tokenHeader);
    return res.data;
};

const ApiProductsMenu = {
    getProductsMenu,
    GetOneUseToken
};

export default ApiProductsMenu;