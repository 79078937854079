import { createSlice } from '@reduxjs/toolkit';

import {
  cleanStateCart,
  loadActivities,
  loadActivitiesRC,
  loadCart,
} from '../actions/SessionActions';
import { SessionState } from '../models/SessionModels';

const inicialState: SessionState = {
  cart: {
    item: { listInsuranceRC: [], listInsuranceTRC: [], listInsurancePF: [] },
  },
  activities: [],
  activitiesRC: [],
  uf: undefined,
  usd: undefined,
};

const slice = createSlice({
  name: "sessions",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cleanStateCart, (state) => {
        state.cart = inicialState.cart;
      })
      .addCase(loadCart, (state, action) => {
        state.cart = action?.payload;
      })
      .addCase(loadActivities, (state, action) => {
        state.activities = action?.payload;
      })
      .addCase(loadActivitiesRC, (state, action) => {
        state.activitiesRC = action?.payload;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
