import { createSlice } from '@reduxjs/toolkit'
import { TypeInsurance } from '@redux/models/TypeInsuranceModels'
import { setInsurances, setExternalInsurances } from '@redux/actions/TypeInsuranceActions'

export interface TypeInsuranceState {
    insurances: TypeInsurance[],
    externalInsurances: TypeInsurance[]
}

const initialState: TypeInsuranceState = {
    insurances: [],
    externalInsurances: []
}

export const typesInsuranceSlice = createSlice({
    name: 'typeInsurances',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setInsurances, (state, action) => {
                state.insurances = action.payload
            })
            .addCase(setExternalInsurances, (state, action) => {
                state.externalInsurances = action.payload
            })
            .addDefaultCase((_state) => { })
    }
})

export const { } = typesInsuranceSlice.actions 
