import {
  // AdditionalWorkInfo,
  DataWork,
  InsuranceTRCModel,
  InsuredInformation,
  MandatoryInformation,
  PolizaCommon,
  // WorkInformation,
} from "@redux/models/InsuranceModels";
import { Activity } from "@redux/models/SessionModels";

import {
  Assured,
  Insurance,
  InsuranceRCAddToCart,
  InsurancePlan,
  InsurancePolicyActivities,
  InsurancePolicyEntity,
  Prima,
  InsuranceRCModel,
  InsurancePFModel,
  AssuredPA,
  InsurancePFAddToCart,
  AssuredTRC,
  InsuranceTRCAddToCart,
} from "@redux/models/InsuranceModels";
import _ from "lodash";
import { useAppSelector } from "@redux/hooks";

export const handleSendRcAddToCart = (
  assured: Assured,
  checkActivity: Activity[],
  plans: InsurancePlan[],
  idPropuesta: number,
  fechaInicio: Date,
  fechaTermino?: Date
): InsuranceRCAddToCart | undefined => {
  try {
    if (!!!assured || !!!checkActivity || !!!plans) {
      return undefined;
    }
 
    debugger;
    const insurancePolicyEntity: InsurancePolicyEntity[] = [];

    //primero los datos del asegurado(usuario logueado)
    //siempre con IdTipoRelacionPoliza: 1
    //segundo los datos del contratante
    //siempre con IdTipoRelacionPoliza: 2
    const dataAsegurado: InsurancePolicyEntity = {
      comuna: assured.comunaAsegurado,
      fono:
        assured.telefonoAsegurado !== ""
          ? parseInt(assured.telefonoAsegurado)
          : 0,
      idTipoRelacionPoliza: 1,
      mail: assured.mailAsegurado,
      rut: assured.rutAsegurado,
      apellidoMaterno: assured.apellidoMaternoAsegurado,
      apellidoPaterno: assured.apellidoPaternoAsegurado,
      calle: assured.calleAsegurado,
      isNaturalPerson:
        assured.tipoBeneficiarioAdicional === "persona" ? true : false,
      nombre: assured.nombresAsegurado,
      numero: assured.numeroAsegurado.toString(),
      razonSocial: assured.razonSocialAsegurado,
      //direccion: "",
      region: assured.regionAsegurado,
    };

    const dataContratante: InsurancePolicyEntity = {
      comuna: assured.comunaAsegurado,
      fono:
        assured.telefonoAsegurado !== ""
          ? parseInt(assured.telefonoAsegurado)
          : 0,
      idTipoRelacionPoliza: 2,
      mail: assured.mailAsegurado,
      rut: assured.rutAsegurado,
      apellidoMaterno: assured.apellidoMaternoAsegurado,
      apellidoPaterno: assured.apellidoPaternoAsegurado,
      calle: assured.calleAsegurado,
      isNaturalPerson:
        assured.tipoBeneficiarioAdicional === "persona" ? true : false,
      nombre: assured.nombresAsegurado,
      numero: assured.numeroAsegurado.toString(),
      razonSocial: assured.razonSocialAsegurado,
      //direccion: "",
      region: assured.regionAsegurado,
    };
    insurancePolicyEntity.push(dataAsegurado, dataContratante);

    //ASEGURADO ADICIONAL
    if (assured.tieneBeneficiarioAdicional) {
      const dataMandante: InsurancePolicyEntity = {
        comuna: assured.comunaBeneficiarioAdicional,
        fono: parseInt(assured.telefonoBeneficiarioAdicional),
        idTipoRelacionPoliza: 3,
        mail: assured.mailBeneficiarioAdicional,
        rut: assured.rutBeneficiarioAdicional,
        apellidoMaterno: assured.apellidoMaternoBeneficiarioAdicional,
        apellidoPaterno: assured.apellidoPaternoBeneficiarioAdicional,
        calle: assured.calleBeneficiarioAdicional,
        isNaturalPerson:
          assured.tipoBeneficiarioAdicional === "persona" ? true : false,
        nombre: assured.nombresBeneficiarioAdicional,
        numero: assured.numeroBeneficiarioAdicional.toString(),
        razonSocial: assured.razonSocialBeneficiarioAdicional,
        //direccion: "",
        region: assured.regionBeneficiarioAdicional,
      };
      insurancePolicyEntity.push(dataMandante);
    }

    const insurancePolicyActivities: InsurancePolicyActivities[] = [];
    checkActivity.forEach((a) => {
      if (a.checked) {
        const actividad: InsurancePolicyActivities = {
          codigo: a.code,
        };
        insurancePolicyActivities.push(actividad);
      }
    });

    let newPoliza: PolizaCommon = {
      id: 0,
      anio: new Date().getFullYear(),
      idOpcionPropuesta: idPropuesta,
      descripcionOpcionPropuesta: "",
      idEstadoPoliza: null,
      idTipoSeguro: 1,
      comision: 0,
      descripcionTipoSeguro: "",
      vigenciaInicio: fechaInicio,
      vigenciaTermino: fechaTermino,
      cantidadEmpleados: 0,
      rgm: false,
    };

    const insurancePersistentRC: InsuranceRCAddToCart = {
      poliza: newPoliza,
      insurancePolicyEntity: insurancePolicyEntity,
      insurancePolicyActivities: insurancePolicyActivities,
      prima: undefined,
    };

    return insurancePersistentRC;
  } catch (error) {
    return undefined;
  }
};

export const handleSendPFAddToCart = (
  assured: AssuredPA,
  checkActivity: Activity[],
  plans: InsurancePlan[],
  prima: Prima | undefined,
  fechaInicio: Date
): InsurancePFAddToCart | undefined => {
  try {
    if (!!!assured || !!!checkActivity || !!!plans || !!!prima) {
      return undefined;
    }
    const insurancePolicyEntity: InsurancePolicyEntity[] = [];

    //primero los datos del asegurado(usuario logueado)
    //siempre con IdTipoRelacionPoliza: 1
    //segundo los datos del contratante
    //siempre con IdTipoRelacionPoliza: 2
    const dataAsegurado: InsurancePolicyEntity = {
      comuna: assured.comunaAsegurado,
      fono:
        assured.telefonoAsegurado !== ""
          ? parseInt(assured.telefonoAsegurado)
          : 0,
      idTipoRelacionPoliza: 1,
      mail: assured.mailAsegurado,
      rut: assured.rutAsegurado,
      apellidoMaterno: assured.apellidoMaternoAsegurado,
      apellidoPaterno: assured.apellidoPaternoAsegurado,
      calle: assured.calleAsegurado,
      isNaturalPerson:
        assured.tipoBeneficiarioAdicional === "persona" ? true : false,
      nombre: assured.nombresAsegurado,
      numero: assured.numeroAsegurado,
      razonSocial: assured.razonSocialAsegurado,
      //direccion: "",
      region: assured.regionAsegurado,
    };
    const dataContratante: InsurancePolicyEntity = {
      comuna: assured.comunaAsegurado,
      fono:
        assured.telefonoAsegurado !== ""
          ? parseInt(assured.telefonoAsegurado)
          : 0,
      idTipoRelacionPoliza: 2,
      mail: assured.mailAsegurado,
      rut: assured.rutAsegurado,
      apellidoMaterno: assured.apellidoMaternoAsegurado,
      apellidoPaterno: assured.apellidoPaternoAsegurado,
      calle: assured.calleAsegurado,
      isNaturalPerson:
        assured.tipoBeneficiarioAdicional === "persona" ? true : false,
      nombre: assured.nombresAsegurado,
      numero: assured.numeroAsegurado,
      razonSocial: assured.razonSocialAsegurado,
      region: assured.regionAsegurado,
    };
    insurancePolicyEntity.push(dataAsegurado, dataContratante);

    let benef = assured.tieneBeneficiarioAdicional.toString() === "true";
    if (benef) {
      const dataMandante: InsurancePolicyEntity = {
        comuna: assured.comunaBeneficiarioAdicional,
        fono: assured.telefonoBeneficiarioAdicional
          ? parseInt(assured.telefonoBeneficiarioAdicional)
          : 0,
        idTipoRelacionPoliza: 3,
        mail: assured.mailBeneficiarioAdicional,
        rut: assured.rutBeneficiarioAdicional,
        apellidoMaterno: assured.apellidoMaternoBeneficiarioAdicional,
        apellidoPaterno: assured.apellidoPaternoBeneficiarioAdicional,
        calle: assured.calleBeneficiarioAdicional,
        isNaturalPerson: false,
        nombre: assured.nombresBeneficiarioAdicional,
        numero: assured.numeroBeneficiarioAdicional,
        razonSocial: assured.razonSocialBeneficiarioAdicional,
        region: assured.regionBeneficiarioAdicional,
      };
      insurancePolicyEntity.push(dataMandante);
    }

    const insurancePolicyActivities: InsurancePolicyActivities[] = [];
    checkActivity.forEach((a) => {
      if (a.checked) {
        const actividad: InsurancePolicyActivities = {
          codigo: a.code,
        };
        insurancePolicyActivities.push(actividad);
      }
    });

    let polizaInfo: PolizaCommon = {
      id: 0,
      anio: new Date().getFullYear(),
      idOpcionPropuesta: assured.idPlanPropuesta,
      descripcionOpcionPropuesta: "",
      idEstadoPoliza: null,
      idTipoSeguro: 2,
      comision: 0,
      descripcionTipoSeguro: "",
      vigenciaInicio: fechaInicio,
      cantidadEmpleados: assured.cantidadTrabajadores,
      rgm: assured.rgm,
    };

    const insurancePersistentPF: InsurancePFAddToCart = {
      poliza: polizaInfo,
      insurancePolicyEntity: insurancePolicyEntity,
      insurancePolicyActivities: insurancePolicyActivities,
      prima: prima,
    };

    return insurancePersistentPF;
  } catch (error) {
    return undefined;
  }
};

export const handleSendTRCAddToCart = (
  assured: AssuredTRC,
  workInformation: DataWork,
  // additionalWorkInfo: DataWork,
  idOpcionPropuesta: number
): InsuranceTRCAddToCart | undefined => {
  try {
    if (!!!idOpcionPropuesta) return;

    const datawork: DataWork = {
      nombreObra: workInformation.nombreObra,
      descripcionObra: workInformation.descripcionObra,
      direccionObra: workInformation.direccionObra,
      direccionNumero: workInformation.direccionNumero,
      direccionAdicionalObra: workInformation.direccionAdicionalObra,
      idRegionObra: workInformation.idRegionObra,
      idComunaObra: workInformation.idComunaObra,
      obraCercanaLagunaMar: workInformation.obraCercanaLagunaMar!,
      obraCercanaLagunaMarAltura: workInformation.obraCercanaLagunaMar!,

      idTipoRiesgoObra: workInformation.idTipoRiesgoObra,
      idTipoObra: workInformation.idTipoObra,
      numeroPisos:
        workInformation.numeroPisos === null ||
        workInformation.numeroPisos.toString() === ""
          ? 0
          : workInformation.numeroPisos,
      numeroSubterraneos:
        workInformation.numeroSubterraneos === null ||
        workInformation.numeroSubterraneos.toString() === ""
          ? 0
          : workInformation.numeroSubterraneos,

      numeroTrabajadores: workInformation.numeroTrabajadores,
      vigenciaInicioObra: workInformation.vigenciaInicioObra,
      vigenciaTerminoObra: workInformation.vigenciaTerminoObra,
      idPeriodoMantencionObra: workInformation.idPeriodoMantencionObra,
      montoUfAseguradoObra: workInformation.montoUfAseguradoObra,
      montoUfResponsabilidadCivil: 0,

      aseguradoAdicional: assured.tieneBeneficiarioAdicional,
      isDireccionSinNumero:workInformation.isDireccionSinNumero
    };

    const informationAsegurado: InsuredInformation = {
      rutAsegurado: assured.rutAsegurado,
      razonSocialAsegurado: assured.razonSocialAsegurado,
      nombres: assured.nombresAsegurado,
      apellidoPaterno: assured.apellidoPaternoAsegurado,
      apellidoMaterno: assured.apellidoMaternoAsegurado,
      fono:
        assured.telefonoAsegurado !== ""
          ? parseInt(assured.telefonoAsegurado)
          : 0,
      mail: assured.mailAsegurado,
      regionAsegurado: parseInt(assured.regionAsegurado),
      comunaAsegurado: parseInt(assured.comunaAsegurado),
      calleAsegurado: assured.calleAsegurado,
      numeroAsegurado: assured.numeroAsegurado,
    };

    const informationMandante: MandatoryInformation = {
      rutMandante: assured.rutMandante,
      razonSocialMandante: assured.razonSocialMandante,
      correoMandante: assured.mailMandante,
      telefonoMandante: parseInt(assured.telefonoMandante),
      regionMandante: parseInt(assured.regionMandante),
      comunaMandante: parseInt(assured.comunaMandante),
      calleMandante: assured.calleMandante,
      numeroMandante: assured.numeroMandante,
      aseguradoAdicionalMandante: assured.tieneBeneficiarioAdicional,
    };

    let polizaInfo: PolizaCommon = {
      id: 0,
      anio: new Date().getFullYear(),
      idOpcionPropuesta: idOpcionPropuesta,
      descripcionOpcionPropuesta: "",
      idEstadoPoliza: null,
      idTipoSeguro: 3,
      comision: 0,
      descripcionTipoSeguro: "",
      vigenciaInicio: workInformation.vigenciaInicioObra,
      cantidadEmpleados: 0,
      rgm: false,
    };

    const insuranceTRCAddToCart: InsuranceTRCAddToCart = {
      poliza: polizaInfo,
      dataWork: datawork,
      insuredInformation: informationAsegurado,
      mandatoryInformation: informationMandante,
      prima: undefined,
    };

    return insuranceTRCAddToCart;
  } catch (error) {
    return undefined;
  }
};

///
///newInsuranceRc: nuevo seguro agregado al carro
///insuranceRCPersistent: item actividades, informacion adicional, prima del seguro que se agregara al carro
///cartInsurance: items del carro de compras
///
export const handleResponseRcAddToCart = (
  newInsuranceRc: InsuranceRCModel,
  cartInsurance: Insurance
): Insurance | null => {
  if (!!!newInsuranceRc) {
    return null;
  }

  try {
    let list: InsuranceRCModel = {
      poliza: newInsuranceRc?.poliza,
      insurancePolicyActivities: newInsuranceRc?.insurancePolicyActivities,
      insurancePolicyEntity: newInsuranceRc?.insurancePolicyEntity,
      prima: newInsuranceRc?.prima,
    };

    let listInsuranceRC: InsuranceRCModel[] | undefined | null =
      cartInsurance?.listInsuranceRC ? [...cartInsurance?.listInsuranceRC] : [];

    if (listInsuranceRC && listInsuranceRC.length > 0) {
      let indexInsuranceRc = _.findIndex(
        cartInsurance?.listInsuranceRC,
        (x) => x.poliza.id === newInsuranceRc?.poliza.id
      );

      if (indexInsuranceRc !== -1) {
        listInsuranceRC[indexInsuranceRc] = list;
      } else {
        listInsuranceRC.push(list);
      }
    } else {
      listInsuranceRC.push(list);
    }

    const insurance: Insurance = {
      listInsuranceRC: listInsuranceRC,
      listInsurancePF: cartInsurance?.listInsurancePF,
      listInsuranceTRC: cartInsurance?.listInsuranceTRC,
    };

    return insurance;
  } catch (error) {
    return null;
  }
};

export const handleResponsePfAddToCart = (
  newInsurancePf: InsurancePFModel,
  insurancePFAddToCart: InsurancePFAddToCart | undefined,
  cartInsurance: Insurance
): Insurance | null => {
  if (!!!newInsurancePf) {
    return null;
  }

  try {
    let list: InsurancePFModel = {
      poliza: newInsurancePf?.poliza,
      insurancePolicyActivities: newInsurancePf?.insurancePolicyActivities,
      insurancePolicyEntity: newInsurancePf?.insurancePolicyEntity,
      prima: newInsurancePf?.prima,
    };

    let listinsurancePF: InsurancePFModel[] | undefined | null =
      cartInsurance?.listInsurancePF ? [...cartInsurance?.listInsurancePF] : [];

    if (listinsurancePF && listinsurancePF.length > 0) {
      let indexInsuranceRc = _.findIndex(
        cartInsurance?.listInsuranceRC,
        (x) => x.poliza.id === newInsurancePf?.poliza.id
      );

      if (indexInsuranceRc !== -1) {
        listinsurancePF[indexInsuranceRc] = list;
      } else {
        listinsurancePF.push(list);
      }
    } else {
      listinsurancePF.push(list);
    }

    const insurance: Insurance = {
      listInsuranceRC: cartInsurance?.listInsuranceRC,
      listInsurancePF: listinsurancePF,
      listInsuranceTRC: cartInsurance?.listInsuranceTRC,
    };

    return insurance;
  } catch (error) {
    return null;
  }
};

export const handleResponseTRCAddToCart = (
  newInsuranceTrc: InsuranceTRCModel,
  cartInsurance: Insurance
): Insurance | null => {
  if (!!!newInsuranceTrc) {
    return null;
  }

  try {
    let list: InsuranceTRCModel = {
      poliza: newInsuranceTrc.poliza,
      mandatoryInformation: newInsuranceTrc.mandatoryInformation,
      insuredInformation: newInsuranceTrc.insuredInformation,
      dataWork: newInsuranceTrc.dataWork,
      prima: newInsuranceTrc?.prima,
    };

    let listInsuranceTRC: InsuranceTRCModel[] | undefined | null =
      cartInsurance?.listInsuranceTRC
        ? [...cartInsurance?.listInsuranceTRC]
        : [];

    if (listInsuranceTRC && listInsuranceTRC.length > 0) {
      let indexInsuranceRc = _.findIndex(
        cartInsurance?.listInsuranceRC,
        (x) => x.poliza.id === newInsuranceTrc?.poliza.id
      );

      if (indexInsuranceRc !== -1) {
        listInsuranceTRC[indexInsuranceRc] = list;
      } else {
        listInsuranceTRC.push(list);
      }
    } else {
      listInsuranceTRC.push(list);
    }

    const insurance: Insurance = {
      listInsuranceRC: cartInsurance?.listInsuranceRC,
      listInsurancePF: cartInsurance?.listInsurancePF,
      listInsuranceTRC: listInsuranceTRC,
    };

    return insurance;
  } catch (error) {
    return null;
  }
};
