import { MAxios, InjectTokenHeader, MAxiosFormData } from "../conf";

export const autoLogin = async (token: string) => {
  let formData = new FormData();
  formData.append("token", token);

  const endpoint = "membresia/AutoAccount/AutoLogin";

  const res = await MAxios().post(endpoint, formData);

  return res.data;
};

export const KillToken = async (token: string) => {
  const endpoint = "membresia/AutoAccount/KillToken";

  let formData = new FormData();
  formData.append("token", token);

  const res = await MAxiosFormData().post(endpoint, formData);

  return res.data;
};

const RefreshToken = async (token: string) => {
  const endpoint = "membresia/Account/RefreshToken";

  let formData = new FormData();
  formData.append("token", token);
  const res = await MAxiosFormData().post(endpoint, formData);

  return res.data;
};

const ApiMembresiaAccount = {
  autoLogin,
  KillToken,
  RefreshToken
};

export default ApiMembresiaAccount;
