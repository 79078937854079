
import MLoading from "@controls/MLoading";

export const RenderLoading = () => {
    return (
        <div className="anim-loading">
            <div className="lottie-animacion">
                <MLoading />
            </div>
        </div>
    )
}