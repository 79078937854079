import React, { useEffect } from "react";
import { MButton } from '@inversiones-ma/finfast-react-controls';
import { useAppDispatch } from "@redux/hooks";
import { useHistory } from "react-router-dom";
import { StepState } from "@redux/models/StepModels";
import { loadStepData } from "@redux/actions/StepActions";

const PaymentError = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  useEffect(() => {
    
  }, []);

  const handlerRetryPay = () => {
    const steps2: StepState = {
      step: 1,
      subStep: undefined
    };
    dispatch(loadStepData(steps2));

    history.push("/homecart");
  }

  return (
    <>
      <div className="col-12 col-lg-12 my-4">
        <div className="rounded-card-bg p-3 p-md-5 text-center">
          <div className="msg-validation-matriz error">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <h4 className="titles-center">NO SE PUDO PROCESAR TU PAGO</h4>
            <p className="py-2">
              Por favor, vuelve a intentarlo.
              <br />
              Si el problema persiste, comunícate con nosotros
            </p>
            <div className="row justify-content-center">
              <div className="col-12 col-md-4  order-1 order-md-0 my-auto py-2">
                <MButton className="w-100" onClick={handlerRetryPay}>
                  Reintentar
                </MButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentError;