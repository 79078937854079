import { MButton, MTabs } from "@inversiones-ma/finfast-react-controls";
import GenericBaseModel from "@api/GenericBaseModel";
import ApiInsurance from "@api/insurance/insurance";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Coverage, InsurancePlan, Prima } from "@redux/models/InsuranceModels";
import { useEffect, useState } from "react";

interface PropsCoverageResult {
  planSelected: InsurancePlan;
  prima: Prima | undefined;
  expandedLink: boolean;
  setShowModal?: any;
}

const getCoverageElement = (data: Coverage) => {
  return (
    <>
      <h4 className="fs-14 color-secondary mt-2 mb-0">
        <strong>{data.descripcionCobertura}</strong>
      </h4>
      {!!data.deducible && <p className="deducible fs-10">{data.deducible}</p>}
      <div className="bg-grisf7">
        <div className="row">
          <div className="col-6 col-md-6 text-center p-1">
            <h5 className="fs-14 color-secondary  m-0 p-0">
              <b>Asegurado</b>
            </h5>
            <p className="fs-14 color-text m-0 p-0">
              <strong>{data.montoAsegurado}</strong>
            </p>
          </div>
          <div className="col-6 col-md-6 text-center p-1">
            <h5 className="fs-14 color-text  m-0 p-0">Prima</h5>
            <p className="fs-14 color-text  m-0 p-0">UF {data.primaNeta}</p>
          </div>
        </div>
      </div>
    </>
  );
};
const getCoverageElementList = (coverageList: Coverage[]) => {
  return coverageList.map((cov) => getCoverageElement(cov));
};

const TRCViewCoverage = (props: PropsCoverageResult) => {
  if (!props.prima) {
    // loading
    return <div></div>;
  }

  const { dataWork } = useAppSelector((store) => store.insuranceTRC);

  const [coverageList, setCoverageList] = useState<Coverage[]>([]);
  const [coverageListRC, setCoverageListRC] = useState<Coverage[]>([]);
  const [coverageListTRC, setCoverageListTRC] = useState<Coverage[]>([]);
  const [ariaExpanded, setAriaExpanded] = useState<boolean>(false);

  const items = [
    {
      label: "Coberturas TRC",
      content: (
        <div className="scroll-coberturas">
          {getCoverageElementList(coverageListTRC)}
        </div>
      ),
    },
    {
      label: "Coberturas RC",
      content: (
        <div className="scroll-coberturas">
          {getCoverageElementList(coverageListRC)}
        </div>
      ),
    },
  ];

  const getCoverageList = async () => {
    await ApiInsurance.callSeguroTRCInsuranceCoverageGetInfoCoverageAll(
      new Date().getFullYear().toString(),
      props.planSelected.idTipoMoneda.toString(),
      props.planSelected.idSeguro.toString(),
      props.planSelected.idPropuesta.toString(),
      dataWork.montoUfAseguradoObra.toString(),
      props.prima!.primaAfecta.toString(),
      props.prima!.primaExenta.toString(),
      dataWork.idPeriodoMantencionObra.toString(),
      dataWork.numeroTrabajadores.toString()
    ).then((resp: GenericBaseModel<Coverage>) => {
      if (resp.success) {
        setCoverageList(resp.dataList);
      }
    });
  };

  useEffect(() => {
    getCoverageList();
    setAriaExpanded(props.expandedLink);
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    let _coverageRC = coverageList.filter((item) =>
      item.descripcionCobertura.startsWith("Responsabilidad Civil ")
    );
    let _coverageTRC = coverageList.filter(
      (item) => !item.descripcionCobertura.startsWith("Responsabilidad Civil ")
    );

    setCoverageListRC(_coverageRC);
    setCoverageListTRC(_coverageTRC);
  }, [coverageList]);

  return (
    <>
      {props.expandedLink ? (
        <>
          <a
            className={`${ariaExpanded ? "text-link" : "text-link collapsed"}`}
            data-bs-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded={ariaExpanded ? "true" : "false"}
            aria-controls="collapseExample"
          >
            Ver todas las coberturas
            <i className="fa fa-angle-right" aria-hidden="true" />
          </a>
          <div className="row my-2 collapse" id="collapseExample">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="content_1"
                  role="tabpanel"
                  aria-labelledby="tab_1"
                >
                  {props.planSelected.idPropuesta == 35 ? ( //TRC+RC
                    <MTabs
                      items={items}
                      defaultIndexTabSelected={0}
                      blockTabNavOn={undefined}
                    ></MTabs>
                  ) : (
                    <div className="scroll-coberturas">
                      {getCoverageElementList(coverageListTRC)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="col-12">
          <h4 className="titles-center my-3">detalle de coberturas</h4>
          <h3 className="text-center pt-3 fs-5 fw-bold color-secondary">
            {props.planSelected.descripcionOpcionPropuesta}
          </h3>
          <p className="text-center fs-6 color-secondary">
            Plan {props.planSelected.idPropuesta}
          </p>
          {props.planSelected.idPropuesta == 35 ? ( //TRC+RC
            <MTabs
              items={items}
              defaultIndexTabSelected={0}
              blockTabNavOn={undefined}
            ></MTabs>
          ) : (
            getCoverageElementList(coverageListTRC)
          )}
          <div className="row justify-content-center mt-4">
            <div className="col-12 col-md-3">
              <MButton
                type="button"
                className="w-100"
                onClick={() => props.setShowModal(false)}
              >
                Cerrar
              </MButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TRCViewCoverage;
