import { MButton } from "@inversiones-ma/finfast-react-controls";
import { TypeInsurance } from "@redux/models/TypeInsuranceModels";
import { LocationPropsTRC } from "components/pages/Private/TRCInsurance/TRCInsurance";
import { useHistory } from "react-router-dom";

type TButtonsCardInsuranceProps = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIdOpcionPropuesta: React.Dispatch<React.SetStateAction<number>>;
  insurance: TypeInsurance;
  tipoSeguroTRC?: LocationPropsTRC;
  blockIfConstruction: boolean;
};

export const ButtonsCardInsurance = ({
  setModalOpen,
  setIdOpcionPropuesta,
  insurance,
  tipoSeguroTRC,
  blockIfConstruction,
}: TButtonsCardInsuranceProps) => {
  const handleOpenFichaSeguro = (idOpcionPropuesta: number): void => {
    setModalOpen(true);
    setIdOpcionPropuesta(idOpcionPropuesta);
  };

  const history = useHistory();

  const redirect = () => {
    if (insurance.vinculoPlataformaInterna === "/todo-riesgo-construccion")
      history.push(insurance.vinculoPlataformaInterna, tipoSeguroTRC);
    else history.push(insurance.vinculoPlataformaInterna);
  };

  return (
    <>
      {" "}
      <div className="col-12 col-md-10 text-center mb-3">
        <p className="color-text mb-0">¿Dudas sobre este seguro?</p>
        <a
          className="text-link mt-0 mb-5 cursor-pointer"
          onClick={() => handleOpenFichaSeguro(insurance.idOpcionPropuesta)}
        >
          Accede a más información aquí
        </a>
      </div>
      <div className="col-12 col-md-7">
        <MButton
          type="button"
          className="btn btn-primary sistema w-100 p-2"
          onClick={redirect}
          disabled={
            !insurance.estado === true || blockIfConstruction ? true : false
          }
        >
          Cotizar ahora
        </MButton>
      </div>
    </>
  );
};
