import { cleanState, loadLoginData } from "../actions/AccountActions";
import { createSlice } from "@reduxjs/toolkit";
import { AccountState } from "../models/AccountModels";

const inicialState: AccountState = {
  token: undefined,
  run: undefined,
  name: undefined,
  names: undefined,
  lastName: undefined,
  motherLastName: undefined,
  email: undefined,
  idBillingType: undefined,
  executive: undefined,
  idClassification: undefined,
  idSalesLevel: undefined,
  idActivity: undefined,
  enabledEmit: undefined,
  emailPreference: undefined,
  showPreference: undefined,
  isAnonymous: undefined,
  isNaturalPerson: false,
  validatePlazo: undefined,
  blockedByDebt: undefined,
  loginDate: undefined,
  loginDateClient: undefined,
};

const slice = createSlice({
  name: "account",
  initialState: inicialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadLoginData, (state, action) => {
        state.token = action.payload.token;
        state.run = action.payload.run;
        state.name = action.payload.name;
        state.names = action.payload.names;
        state.lastName = action.payload.lastName;
        state.motherLastName = action.payload.motherLastName;
        state.email = action.payload.email;
        state.idBillingType = action.payload.idBillingType;
        state.executive = action.payload.executive;
        state.idClassification = action.payload.idClassification;
        state.idSalesLevel = action.payload.idSalesLevel;
        state.idActivity = action.payload.idActivity;
        state.enabledEmit = action.payload.enabledEmit;
        state.emailPreference = action.payload.emailPreference;
        state.showPreference = action.payload.showPreference;
        state.isAnonymous = action.payload.isAnonymous;
        state.isNaturalPerson= action.payload.isNaturalPerson;
        state.validatePlazo = action.payload.validatePlazo;
        state.blockedByDebt = action.payload.blockedByDebt;
        state.loginDate = action.payload.loginDate;
        state.loginDateClient = action.payload.loginDateClient;
      })
      .addCase(cleanState, (state) => {
        state.token = inicialState.token;
        state.run = inicialState.run;
        state.name = inicialState.name;
        state.email = inicialState.email;
        state.idBillingType = inicialState.idBillingType;
        state.executive = inicialState.executive;
        state.idClassification = inicialState.idClassification;
        state.idSalesLevel = inicialState.idSalesLevel;
        state.idActivity = inicialState.idActivity;
        state.enabledEmit = inicialState.enabledEmit;
        state.emailPreference = inicialState.emailPreference;
        state.showPreference = inicialState.showPreference;
        state.isAnonymous = inicialState.isAnonymous;
        state.validatePlazo = inicialState.validatePlazo;
        state.blockedByDebt = inicialState.blockedByDebt;
        state.loginDate = inicialState.loginDate;
        state.loginDateClient = inicialState.loginDateClient;
      })
      .addDefaultCase((_state) => {});
  },
});

export default slice.reducer;
